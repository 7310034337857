import z from "zod";
import { ProjectBlockerStatus, ProjectBlockerSlug, ProjectBlockerAssetType } from "../enums";

export const ProjectBlockerSlugSchema = z.nativeEnum(ProjectBlockerSlug);

export const ProjectBlockerStatusSchema = z.nativeEnum(ProjectBlockerStatus);

export const ProjectBlockerAssetTypeSchema = z.nativeEnum(ProjectBlockerAssetType);

export const ProjectBlockerAssetSchema = z.object({
  id: z.number(),
  createdAt: z.string().datetime(),
  assetType: ProjectBlockerAssetTypeSchema,
  signedUrl: z.string(),
  filename: z.string(),
  canDelete: z.boolean(),
  user: z.object({
    id: z.number(),
    fullName: z.string(),
    avatar: z.object({
      thumb: z.string(),
      preview: z.string(),
      large: z.string(),
      retina: z.string(),
    }),
    avatarUrls: z.object({
      thumb: z.string(),
      preview: z.string(),
      large: z.string(),
      retina: z.string(),
    }),
  }),
});

export const MessageUserSchema = z.object({
  avatar: z.object({
    large: z.string(),
    preview: z.string(),
    retina: z.string(),
    thumb: z.string(),
  }),
  email: z.string(),
  firstName: z.string(),
  fullName: z.string(),
  id: z.number(),
  lastName: z.string(),
  phone: z.string(),
});

export const BlockerTicketSchema = z.object({
  archived: z.boolean(),
  assignedAt: z.string().nullable(),
  assignedToCustomerCare: z.boolean(),
  author: MessageUserSchema,
  body: z.string(),
  canArchive: z.boolean(),
  canAssign: z.boolean(),
  canClose: z.boolean(),
  canDelete: z.boolean(),
  canEdit: z.boolean(),
  canReopen: z.boolean(),
  canReply: z.boolean(),
  id: z.number(),
  kind: z.string(),
  parentId: z.number().nullable(),
  resolved: z.boolean(),
  resolvedAt: z.string().nullable(),
  respondedAt: z.string().nullable(),
  starred: z.boolean(),
  subjectLine: z.string().nullable(),
  taggedRoles: z.array(z.number()),
  taggedUsers: z.array(z.number()),
  updatedAt: z.string().nullable(),
  userTagged: z.boolean().nullable(),
  viewed: z.boolean(),
  zendeskStatus: z.string().nullable(),
  zendeskTicketId: z.number(),
});

export const BaseProjectBlockerSchema = z.object({
  id: z.number(),
  title: z.string(),
  zendeskTicketId: z.number(),
  slug: ProjectBlockerSlugSchema,
  uploadNeeded: z.boolean(),
  status: ProjectBlockerStatusSchema,
  createdAt: z.string().datetime(),
  updatedAt: z.string().datetime(),
});

export const IndexProjectBlockerSchema = BaseProjectBlockerSchema.extend({
  messageCount: z.number(),
});

export const ProjectBlockerSchema = BaseProjectBlockerSchema.extend({
  messages: z.array(BlockerTicketSchema),
  description: z.string().optional(),
  additionalInfoTitle: z.string().optional(),
  additionalInfoUrl: z.string().optional(),
  assets: z.array(ProjectBlockerAssetSchema).optional(),
});

export const ProjectBlockersSchema = z.array(IndexProjectBlockerSchema);

export const GetProjectBlockerResponseSchema = z.object({
  status: z.number(),
  body: ProjectBlockerSchema,
});

export const GetProjectBlockersResponseSchema = z.object({
  status: z.number(),
  body: z.object({
    blockers: ProjectBlockersSchema,
    completedBlockers: z.number(),
    allBlockersComplete: z.boolean(),
  }),
});
