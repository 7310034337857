/* eslint-disable */
import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
};

export type ActiveLoan = {
  __typename?: "ActiveLoan";
  amount: Scalars["Float"];
  applicants?: Maybe<Array<Applicant>>;
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  dealerFee: Scalars["Float"];
  /** version 4(random) UUID */
  id: Scalars["ID"];
  loanDocsSentAt?: Maybe<Scalars["DateTime"]>;
  loanProductId: Scalars["String"];
  proposalId: Scalars["String"];
  /** the different loan providers for the activeLoan */
  provider: LoanProvider;
  providerId: Scalars["String"];
  providerMetadata?: Maybe<Scalars["JSON"]>;
  providerStatusEvents?: Maybe<Array<Scalars["JSON"]>>;
  rate: Scalars["Float"];
  status?: Maybe<ActiveLoanStatus>;
  termMonths: Scalars["Float"];
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
};

export enum ActiveLoanStatus {
  Applied = "applied",
  Approved = "approved",
  ApprovedWithStipulations = "approved_with_stipulations",
  Cancelled = "cancelled",
  Denied = "denied",
  Incomplete = "incomplete",
  LoanDocsSent = "loan_docs_sent",
}

export type Adder = {
  __typename?: "Adder";
  adderRoofingTypes?: Maybe<Scalars["JSON"]>;
  adderWorkTypes?: Maybe<Scalars["JSON"]>;
  /** The category of the adder */
  category?: Maybe<Scalars["JSON"]>;
  createdAt: Scalars["DateTime"];
  doesMountingTypeRequireInstaller: Scalars["Boolean"];
  id: Scalars["ID"];
  isBaseCost: Scalars["Boolean"];
  isCustom: Scalars["Boolean"];
  isDesign: Scalars["Boolean"];
  isEquipment: Scalars["Boolean"];
  isGroundMount: Scalars["Boolean"];
  isInterconnect: Scalars["Boolean"];
  isInternal: Scalars["Boolean"];
  isLabor: Scalars["Boolean"];
  isMPU: Scalars["Boolean"];
  isPermit: Scalars["Boolean"];
  isRacking: Scalars["Boolean"];
  isSiteSurvey: Scalars["Boolean"];
  isTurnKey: Scalars["Boolean"];
  isVariable: Scalars["Boolean"];
  lineType?: Maybe<Scalars["Float"]>;
  needsModuleCount: Scalars["Boolean"];
  powurOsCategoryId: Scalars["Float"];
  powurOsId: Scalars["Float"];
  priceList?: Maybe<Scalars["JSON"]>;
  proposalType?: Maybe<Scalars["Float"]>;
  regionalAdderPricing: Array<RegionalAdderPricing>;
  relatedAdder?: Maybe<Scalars["JSON"]>;
  relatedPowurOsAdderId?: Maybe<Scalars["Float"]>;
  /** The title of the adder */
  title: Scalars["String"];
  /** The unit for cost calculations of the adder  */
  unit?: Maybe<Scalars["Float"]>;
  updatedAt: Scalars["DateTime"];
  watts?: Maybe<Scalars["Float"]>;
};

export type AdderRegionAdder = {
  __typename?: "AdderRegionAdder";
  adder: Scalars["JSON"];
  adderId: Scalars["Float"];
  adderRegionId: Scalars["Float"];
  id: Scalars["Float"];
  proposalType: Scalars["Float"];
};

export type AdderSyncResult = {
  __typename?: "AdderSyncResult";
  adders: Array<Adder>;
  createdAdders: Scalars["Float"];
  createdPricing: Scalars["Float"];
  createdProposalAdders: Scalars["Float"];
  deletedPricing: Scalars["Float"];
  deletedProposalAdders: Scalars["Float"];
  end: Scalars["DateTime"];
  pricing: Array<RegionalAdderPricing>;
  regions: Scalars["Float"];
  start: Scalars["DateTime"];
  uniqueAddersFound: Scalars["Float"];
  uniqueRegionalPricingFound: Scalars["Float"];
  updatedAdders: Scalars["Float"];
  updatedPricing: Scalars["Float"];
};

export type Address = {
  __typename?: "Address";
  city: Scalars["String"];
  state: StateCode;
  street: Scalars["String"];
  street2?: Maybe<Scalars["String"]>;
  zip: Scalars["String"];
};

export type AddressInput = {
  city: Scalars["String"];
  state: StateCode;
  street: Scalars["String"];
  street2?: InputMaybe<Scalars["String"]>;
  zip: Scalars["String"];
};

export type ApiKey = {
  __typename?: "ApiKey";
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  deactivatedAt?: Maybe<Scalars["DateTime"]>;
  /** version 4(random) UUID */
  id: Scalars["ID"];
  serviceIntegrationId: Scalars["String"];
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
};

export type Applicability = {
  __typename?: "Applicability";
  /** The name of variable that this applicability applies to. Examples include systemOwnership, azimuth, and tilt. */
  applicabilityKey: Scalars["String"];
  /** For applicabilities with a fixed set of choices, the list of available choices. */
  choices?: Maybe<Array<Choice>>;
  /** A user-presentable description of what this applicability represents. */
  description: Scalars["String"];
  /** A user-presentable name for this applicability. */
  displayName: Scalars["String"];
  /** For numeric applicabilities, the maximum allowed value. */
  maxValue?: Maybe<Scalars["String"]>;
  /** For numeric applicabilities, the minimum allowed value. */
  minValue?: Maybe<Scalars["String"]>;
  /** For formulaic applicabilities, the relationship that must be true for this applicability relative to its quantityKey. For example, estimatedFirstYearkWh might have a quantityKey of 110percentAnnualConsumption and an operator of <. This indicates that the estimated first year production of the solar power system must be less than 110% of the customer’s annual consumption. */
  operator?: Maybe<Scalars["String"]>;
  /** For formulaic applicabilities, a pointer to a PropertyKey that holds the formula defining this applicability. */
  quantityKey?: Maybe<PropertyKey>;
  /** If there is a single value that is allowed for this applicability, it will show up here. For example, if systemOwnership requires host, it will be here. */
  requiredValue?: Maybe<Scalars["String"]>;
};

export type Applicant = {
  __typename?: "Applicant";
  activeLoanId?: Maybe<Scalars["String"]>;
  annualIncome: Scalars["Float"];
  consentToShareInfo?: Maybe<Scalars["Boolean"]>;
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  email: Scalars["String"];
  employerName?: Maybe<Scalars["String"]>;
  employmentMonths?: Maybe<Scalars["Float"]>;
  employmentYears?: Maybe<Scalars["Float"]>;
  firstName: Scalars["String"];
  /** version 4(random) UUID */
  id: Scalars["ID"];
  isCreditRun?: Maybe<Scalars["Boolean"]>;
  isPrimary: Scalars["Boolean"];
  jobTitle?: Maybe<Scalars["String"]>;
  lastName: Scalars["String"];
  mailingCity?: Maybe<Scalars["String"]>;
  mailingStateName?: Maybe<Scalars["String"]>;
  mailingStreet?: Maybe<Scalars["String"]>;
  mailingZipCode?: Maybe<Scalars["String"]>;
  middleName?: Maybe<Scalars["String"]>;
  otherPhone?: Maybe<Scalars["String"]>;
  phone: Scalars["String"];
  residenceCity?: Maybe<Scalars["String"]>;
  residenceStateName?: Maybe<Scalars["String"]>;
  residenceStreet?: Maybe<Scalars["String"]>;
  residenceZipCode?: Maybe<Scalars["String"]>;
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
  userId?: Maybe<Scalars["String"]>;
};

export type ApplicantBodyResponse = {
  __typename?: "ApplicantBodyResponse";
  annualIncome: Scalars["Float"];
  consentToShareInfo: Scalars["Boolean"];
  email: Scalars["String"];
  employerName: Scalars["String"];
  employmentMonths: Scalars["Float"];
  employmentYears: Scalars["Float"];
  firstName: Scalars["String"];
  id: Scalars["String"];
  isCreditRun: Scalars["Boolean"];
  isPrimary: Scalars["Boolean"];
  jobTitle: Scalars["String"];
  lastName: Scalars["String"];
  mailingCity: Scalars["String"];
  mailingStateName: Scalars["String"];
  mailingStreet: Scalars["String"];
  mailingZipCode: Scalars["Float"];
  middleInitial: Scalars["String"];
  otherPhone: Scalars["Float"];
  phone: Scalars["Float"];
  residenceCity: Scalars["String"];
  residenceStateName: Scalars["String"];
  residenceStreet: Scalars["String"];
  residenceZipCode: Scalars["Float"];
};

export type ApplicantInput = {
  address: AddressInput;
  annualIncome: CurrencyInput;
  citizenshipStatus: Citizenship_Status;
  dob: Scalars["String"];
  electronicConsent: Scalars["Boolean"];
  email: Scalars["String"];
  employer?: InputMaybe<Scalars["String"]>;
  employmentDuration?: InputMaybe<Scalars["Float"]>;
  employmentStatus: Employment_Status;
  firstName: Scalars["String"];
  homeOccupancy: Home_Occupancy;
  homeOwnership: Home_Ownership;
  lastName: Scalars["String"];
  occupation: Scalars["String"];
  primaryPhoneNumber: PhoneInput;
  secondaryPhoneNumber?: InputMaybe<PhoneInput>;
  spokenLanguage: Spoken_Language;
  ssn: Scalars["String"];
};

export type ApplicantMessage = {
  __typename?: "ApplicantMessage";
  actualLoanAmount: Scalars["String"];
  borrowingLimit: Scalars["String"];
  expiration: Scalars["String"];
};

export type Attachment = {
  __typename?: "Attachment";
  extension: Scalars["String"];
  groupId: Scalars["String"];
  id: Scalars["String"];
  label: Scalars["String"];
  name: Scalars["String"];
  status: Scalars["String"];
  type: Scalars["String"];
};

export type BasicAdderAssociation = {
  __typename?: "BasicAdderAssociation";
  adderId: Scalars["Float"];
  equipmentId: Scalars["Float"];
  qty: Scalars["Float"];
};

export type Breakdown = {
  __typename?: "Breakdown";
  city_tax_collectable: Scalars["Float"];
  city_tax_rate: Scalars["Float"];
  city_taxable_amount: Scalars["Float"];
  combined_tax_rate: Scalars["Float"];
  country_tax_collectable: Scalars["Float"];
  country_tax_rate: Scalars["Float"];
  country_taxable_amount: Scalars["Float"];
  county_tax_collectable: Scalars["Float"];
  county_tax_rate: Scalars["Float"];
  county_taxable_amount: Scalars["Float"];
  gst: Scalars["Float"];
  gst_tax_rate: Scalars["Float"];
  gst_taxable_amount: Scalars["Float"];
  line_items: Array<BreakdownLineItems>;
  pst: Scalars["Float"];
  pst_tax_rate: Scalars["Float"];
  pst_taxable_amount: Scalars["Float"];
  qst: Scalars["Float"];
  qst_tax_rate: Scalars["Float"];
  qst_taxable_amount: Scalars["Float"];
  shipping: BreakdownShipping;
  special_district_tax_collectable: Scalars["Float"];
  special_district_taxable_amount: Scalars["Float"];
  special_tax_rate: Scalars["Float"];
  state_tax_collectable: Scalars["Float"];
  state_tax_rate: Scalars["Float"];
  state_taxable_amount: Scalars["Float"];
  tax_collectable: Scalars["Float"];
  taxable_amount: Scalars["Float"];
};

export type BreakdownLineItems = {
  __typename?: "BreakdownLineItems";
  city_amount: Scalars["Float"];
  city_tax_rate: Scalars["Float"];
  city_taxable_amount: Scalars["Float"];
  combined_tax_rate: Scalars["Float"];
  country_tax_collectable: Scalars["Float"];
  country_tax_rate: Scalars["Float"];
  country_taxable_amount: Scalars["Float"];
  county_amount: Scalars["Float"];
  county_tax_rate: Scalars["Float"];
  county_taxable_amount: Scalars["Float"];
  gst: Scalars["Float"];
  gst_tax_rate: Scalars["Float"];
  gst_taxable_amount: Scalars["Float"];
  id: Scalars["String"];
  pst: Scalars["Float"];
  pst_tax_rate: Scalars["Float"];
  pst_taxable_amount: Scalars["Float"];
  qst: Scalars["Float"];
  qst_tax_rate: Scalars["Float"];
  qst_taxable_amount: Scalars["Float"];
  special_district_amount: Scalars["Float"];
  special_district_taxable_amount: Scalars["Float"];
  special_tax_rate: Scalars["Float"];
  state_amount: Scalars["Float"];
  state_sales_tax_rate: Scalars["Float"];
  state_taxable_amount: Scalars["Float"];
  tax_collectable: Scalars["Float"];
  taxable_amount: Scalars["Float"];
};

export type BreakdownShipping = {
  __typename?: "BreakdownShipping";
  city_amount: Scalars["Float"];
  city_tax_rate: Scalars["Float"];
  city_taxable_amount: Scalars["Float"];
  combined_tax_rate: Scalars["Float"];
  county_amount: Scalars["Float"];
  county_tax_rate: Scalars["Float"];
  county_taxable_amount: Scalars["Float"];
  special_district_amount: Scalars["Float"];
  special_tax_rate: Scalars["Float"];
  special_taxable_amount: Scalars["Float"];
  state_amount: Scalars["Float"];
  state_sales_tax_rate: Scalars["Float"];
  state_taxable_amount: Scalars["Float"];
  tax_collectable: Scalars["Float"];
  taxable_amount: Scalars["Float"];
};

export type Brochure = {
  __typename?: "Brochure";
  afterSolarUtilityBills: Array<BrochureUtilityBill>;
  annualSolarProduction: Scalars["Float"];
  baseGenabilityUtilityRate: Scalars["Float"];
  beforeSolarUtilityBills: Array<BrochureUtilityBill>;
  createdAt: Scalars["DateTime"];
  /** The average expected utility rate for 25 years */
  dialUtilityRates: Array<Scalars["Float"]>;
  energyOffset: Scalars["Float"];
  id: Scalars["String"];
  monthlyProductivity: Array<BrochureMonthlyProductivity>;
  proposalId: Scalars["String"];
  updatedAt: Scalars["DateTime"];
  userAdjustedUtilityRate?: Maybe<Scalars["Float"]>;
  utilityRateEscalationPercentage: Scalars["Float"];
};

export type BrochureMonthlyProductivity = {
  __typename?: "BrochureMonthlyProductivity";
  brochureId: Scalars["String"];
  createdAt: Scalars["DateTime"];
  id: Scalars["String"];
  month: Scalars["String"];
  production: Scalars["Float"];
  sortOrder: Scalars["Float"];
  updatedAt: Scalars["DateTime"];
};

export type BrochureSlidesObject = {
  __typename?: "BrochureSlidesObject";
  name: Scalars["String"];
  url?: Maybe<Scalars["String"]>;
};

export type BrochureSlidesObjectDtoInput = {
  name: Scalars["String"];
  url?: InputMaybe<Scalars["String"]>;
};

export type BrochureUtilityBill = {
  __typename?: "BrochureUtilityBill";
  afterSolarUtilityBillForId?: Maybe<Scalars["String"]>;
  beforeSolarUtilityBillForId?: Maybe<Scalars["String"]>;
  bill: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  energy: Scalars["Float"];
  fixed: Scalars["Float"];
  id: Scalars["String"];
  payment?: Maybe<Scalars["Float"]>;
  sortOrder: Scalars["Float"];
  updatedAt: Scalars["DateTime"];
};

/** Goodleap Citizenship Status Type */
export enum Citizenship_Status {
  LawfulPermanentResidentAlien = "LAWFUL_PERMANENT_RESIDENT_ALIEN",
  Other = "OTHER",
  UsCitizen = "US_CITIZEN",
}

export type Choice = {
  __typename?: "Choice";
  dataValue?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  displayValue?: Maybe<Scalars["String"]>;
  likelihood?: Maybe<Scalars["Float"]>;
  name?: Maybe<Scalars["String"]>;
  value: Scalars["String"];
};

export type ChoiceInput = {
  dataValue?: InputMaybe<Scalars["String"]>;
  displayName?: InputMaybe<Scalars["String"]>;
  displayValue?: InputMaybe<Scalars["String"]>;
  likelihood?: InputMaybe<Scalars["Float"]>;
  name?: InputMaybe<Scalars["String"]>;
  value: Scalars["String"];
};

export type CloneProposalDtoInput = {
  proposalId: Scalars["String"];
};

export type CoApplicant = {
  address?: InputMaybe<AddressInput>;
  annualIncome: CurrencyInput;
  citizenshipStatus: Citizenship_Status;
  dob: Scalars["String"];
  electronicConsent: Scalars["Boolean"];
  email: Scalars["String"];
  employer?: InputMaybe<Scalars["String"]>;
  employmentDuration?: InputMaybe<Scalars["Float"]>;
  employmentStatus: Employment_Status;
  firstName: Scalars["String"];
  homeOccupancy: Home_Occupancy;
  homeOwnership: Home_Ownership;
  lastName: Scalars["String"];
  occupation: Scalars["String"];
  primaryPhoneNumber?: InputMaybe<PhoneInput>;
  secondaryPhoneNumber?: InputMaybe<PhoneInput>;
  spokenLanguage: Spoken_Language;
  ssn: Scalars["String"];
};

export type CompatibleManufacturer = {
  __typename?: "CompatibleManufacturer";
  equipmentId: Scalars["Float"];
  manufacturer: PowurOsManufacturer;
  manufacturerId: Scalars["Float"];
};

export enum ContentType {
  Jpeg = "JPEG",
  Jpg = "JPG",
  Json = "JSON",
  Pdf = "PDF",
  Png = "PNG",
}

export type Contract = {
  __typename?: "Contract";
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  dateSent?: Maybe<Scalars["DateTime"]>;
  dateSentToPowurOs?: Maybe<Scalars["DateTime"]>;
  dateSigned?: Maybe<Scalars["DateTime"]>;
  envelopeId: Scalars["String"];
  /** version 4(random) UUID */
  id: Scalars["ID"];
  proposalId: Scalars["String"];
  systemSize?: Maybe<Scalars["Float"]>;
  totalCost?: Maybe<Scalars["Float"]>;
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
};

export type CreateCustomIncentiveInput = {
  activatedAt?: InputMaybe<Scalars["DateTime"]>;
  activeInZipCodes: Array<Scalars["String"]>;
  applicabilityMaxValue?: InputMaybe<Scalars["Float"]>;
  applicabilityMinValue?: InputMaybe<Scalars["Float"]>;
  applicabilityName?: InputMaybe<Scalars["String"]>;
  canApplyToLoan: Scalars["Boolean"];
  endDate?: InputMaybe<Scalars["String"]>;
  incentiveName: Scalars["String"];
  incentiveType: Scalars["String"];
  isPaidAsLumpSum: Scalars["Boolean"];
  isSungageRebate: Scalars["Boolean"];
  jurisdiction: Scalars["String"];
  paymentCap?: InputMaybe<Scalars["Float"]>;
  paymentDuration: Scalars["Float"];
  percentCostCap?: InputMaybe<Scalars["Float"]>;
  percentCostCapKey?: InputMaybe<PropertyKeyInput>;
  quantityKey: PropertyKeyInput;
  quantityKeyCap?: InputMaybe<Scalars["String"]>;
  rate: Scalars["Float"];
  rateUnit: Scalars["String"];
  startDate: Scalars["String"];
  state: Scalars["String"];
  turnedOnByDefaultAt?: InputMaybe<Scalars["DateTime"]>;
};

export type CreateProposalStatusInput = {
  message: Scalars["String"];
  proposalId: Scalars["String"];
};

export type CreateServiceIntegrationInput = {
  contactEmail: Scalars["String"];
  contactName: Scalars["String"];
  name: Scalars["String"];
};

export type CreateSiteInput = {
  /** Address of the site */
  address: Scalars["String"];
  /** Latitude of the site */
  latitude: Scalars["Float"];
  /** Longitude of the site */
  longitude: Scalars["Float"];
  /** street view latitude of the site */
  streetViewLatitude: Scalars["Float"];
  /** Street view longitude of the site */
  streetViewLongitude: Scalars["Float"];
  /** zoom level of the mapping service */
  zoom?: InputMaybe<Scalars["Float"]>;
};

export type CreateUserInput = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  permissions: Array<Permission>;
  phone?: InputMaybe<Scalars["String"]>;
  powurUserId?: InputMaybe<Scalars["Float"]>;
};

export type Currency = {
  __typename?: "Currency";
  type: Scalars["String"];
  value: Scalars["Float"];
};

export type CurrencyInput = {
  type: Scalars["String"];
  value: Scalars["Float"];
};

export enum DataType {
  Boolean = "BOOLEAN",
  Choice = "CHOICE",
  Date = "DATE",
  Decimal = "DECIMAL",
  Demand = "DEMAND",
  Formula = "FORMULA",
  Integer = "INTEGER",
  String = "STRING",
}

export type Decision = {
  __typename?: "Decision";
  applicantMessages: ApplicantMessage;
  creditApplicationId: Scalars["String"];
  description: Scalars["String"];
  expires: Scalars["String"];
  financingProducts: Array<FinancingProduct>;
  result: Scalars["String"];
  status: Scalars["String"];
  updated: Scalars["String"];
};

export type Design = {
  __typename?: "Design";
  activatedAt?: Maybe<Scalars["DateTime"]>;
  batteryCompatibility: Scalars["Boolean"];
  brochurePositionX: Scalars["Float"];
  brochurePositionY: Scalars["Float"];
  brochureZoom: Scalars["Float"];
  completedAt?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  designEquipment: Array<DesignEquipmentObject>;
  designRequest?: Maybe<DesignRequest>;
  designRequestId?: Maybe<Scalars["ID"]>;
  designers: Array<DesignUser>;
  displayImageryProvider: ImagerySource;
  edges: Scalars["JSON"];
  energyBackup?: Maybe<PricedEquipment>;
  energyBackupId?: Maybe<Scalars["String"]>;
  energyEfficiencyKitRebate: Scalars["Boolean"];
  energyEfficiencyKitRebateAmount: Scalars["Float"];
  estimates?: Maybe<Array<EstimateResult>>;
  evCharger: Scalars["Boolean"];
  evOutlet: Scalars["Boolean"];
  homeownerRebate: Scalars["Boolean"];
  homeownerRebateAmount: Scalars["Float"];
  id: Scalars["ID"];
  installAreas: Scalars["JSON"];
  installAreasToPanels: Scalars["JSON"];
  inverter?: Maybe<PricedEquipment>;
  inverterId?: Maybe<Scalars["String"]>;
  inverterManufacturer?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  nodes: Scalars["JSON"];
  obstructions: Scalars["JSON"];
  panels: Scalars["JSON"];
  parentId: Scalars["ID"];
  phase: Scalars["String"];
  poison?: Maybe<Poison>;
  powurCareThirtyYearWarranty: Scalars["Boolean"];
  profiles: Array<GenabilityProfile>;
  proposal?: Maybe<Proposal>;
  proposalId: Scalars["ID"];
  relatedEquipment: Array<DesignEquipmentObject>;
  roofType?: Maybe<Scalars["String"]>;
  roofWorkRebate: Scalars["Boolean"];
  roofWorkRebateAmount: Scalars["Float"];
  savings?: Maybe<SavingsResult>;
  secondaryInverter?: Maybe<PricedEquipment>;
  secondaryInverterId?: Maybe<Scalars["String"]>;
  setbacks: Scalars["JSON"];
  shadingAreas: Scalars["JSON"];
  solarEdgeTwentyFiveYearWarranty: Scalars["Boolean"];
  solarEdgeTwentyFiveYearWarrantyPrice: Scalars["Float"];
  solarEdgeTwentyYearWarranty: Scalars["Boolean"];
  solarEdgeTwentyYearWarrantyPrice: Scalars["Float"];
  solarModule?: Maybe<PricedEquipment>;
  solarModuleId?: Maybe<Scalars["String"]>;
  sunroofCheckedAt?: Maybe<Scalars["DateTime"]>;
  tool: Scalars["String"];
  treeTrimmingRebate: Scalars["Boolean"];
  treeTrimmingRebateAmount: Scalars["Float"];
  type?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

export type DesignEquipmentObject = {
  __typename?: "DesignEquipmentObject";
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  designId: Scalars["String"];
  equipment: PricedEquipment;
  equipmentId: Scalars["String"];
  /** version 4(random) UUID */
  id: Scalars["ID"];
  isRelated: Scalars["Boolean"];
  price: Scalars["Float"];
  quantity: Scalars["Float"];
  regionId?: Maybe<Scalars["String"]>;
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
};

export type DesignFindManyWhereInput = {
  before?: InputMaybe<Scalars["DateTime"]>;
  designerId?: InputMaybe<Scalars["ID"]>;
  homeownerId?: InputMaybe<Scalars["ID"]>;
  proposalId?: InputMaybe<Scalars["ID"]>;
  sellerId?: InputMaybe<Scalars["ID"]>;
  since?: InputMaybe<Scalars["DateTime"]>;
  type?: InputMaybe<Scalars["String"]>;
};

export type DesignNote = {
  __typename?: "DesignNote";
  createdAt: Scalars["DateTime"];
  createdBy: User;
  createdById: Scalars["String"];
  designRequest: DesignRequest;
  designRequestId: Scalars["String"];
  id: Scalars["String"];
  message: Scalars["String"];
  reason?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["DateTime"];
};

export type DesignOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  proposalId?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type DesignRequest = {
  __typename?: "DesignRequest";
  abandonedAt?: Maybe<Scalars["DateTime"]>;
  claimedUntil?: Maybe<Scalars["DateTime"]>;
  completedAt?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  design?: Maybe<Design>;
  designNotes: Array<DesignNote>;
  designUser?: Maybe<DesignUser>;
  designUserId?: Maybe<Scalars["String"]>;
  enterprise?: Maybe<Enterprise>;
  enterpriseId?: Maybe<Scalars["String"]>;
  escalatedAt?: Maybe<Scalars["DateTime"]>;
  id: Scalars["String"];
  metadata?: Maybe<Scalars["String"]>;
  pausedUntil?: Maybe<Scalars["DateTime"]>;
  priority: Scalars["Float"];
  promisedAt?: Maybe<Scalars["DateTime"]>;
  rejectedAt?: Maybe<Scalars["DateTime"]>;
  requestType?: Maybe<Scalars["String"]>;
  timeAdded?: Maybe<Scalars["Float"]>;
  updatedAt: Scalars["DateTime"];
};

export type DesignRequestCount = {
  __typename?: "DesignRequestCount";
  abandoned: Scalars["Float"];
  completed: Scalars["Float"];
  escalated: Scalars["Float"];
  inProgress: Scalars["Float"];
  requested: Scalars["Float"];
  total: Scalars["Float"];
};

export type DesignRequestFindManyWhereInput = {
  projectId?: InputMaybe<Scalars["Int"]>;
  status?: InputMaybe<Scalars["String"]>;
};

export type DesignRequestTypeDto = {
  __typename?: "DesignRequestTypeDto";
  prettyName: Scalars["String"];
  value?: Maybe<Scalars["String"]>;
};

export type DesignRevisionInput = {
  notes: Scalars["String"];
  parentId: Scalars["ID"];
  reasons: Array<Scalars["String"]>;
  revisionType?: InputMaybe<Scalars["String"]>;
};

export type DesignUser = {
  __typename?: "DesignUser";
  completedAt?: Maybe<Scalars["DateTime"]>;
  createdAt: Scalars["DateTime"];
  design: Design;
  designId: Scalars["String"];
  designNotes: Array<DesignNote>;
  designRequests: Array<DesignRequest>;
  id: Scalars["String"];
  startedAt?: Maybe<Scalars["DateTime"]>;
  updatedAt: Scalars["DateTime"];
  user: User;
  userId: Scalars["String"];
};

export type DesignsInput = {
  filter?: InputMaybe<DesignFindManyWhereInput>;
  paginate?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<DesignOrderByInput>;
};

export type DevCreateProposalDtoNew = {
  __typename?: "DevCreateProposalDtoNew";
  designRequestNote: Scalars["String"];
  designRequestType: Array<DesignRequestTypeDto>;
  mentors: Array<User>;
  /** Proposal name */
  name?: Maybe<Scalars["String"]>;
  platform: PlatformCreateProposalDto;
  roofTypes: Array<Scalars["String"]>;
  sellers: Array<User>;
};

export type DisplayAddress = {
  __typename?: "DisplayAddress";
  category: Scalars["String"];
  city: Scalars["String"];
  createdAt: Scalars["DateTime"];
  id: Scalars["String"];
  lat: Scalars["Float"];
  long: Scalars["Float"];
  postal: Scalars["String"];
  projectId?: Maybe<Scalars["Float"]>;
  state: Scalars["String"];
  street: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type DisplayAddressInput = {
  category: Scalars["String"];
  city: Scalars["String"];
  lat: Scalars["Float"];
  long: Scalars["Float"];
  postal: Scalars["String"];
  state: Scalars["String"];
  street: Scalars["String"];
};

export type Document = {
  __typename?: "Document";
  description: Scalars["String"];
  name: Scalars["String"];
  type: Scalars["String"];
};

export type DocumentObject = {
  __typename?: "DocumentObject";
  contentType: ContentType;
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  filename: Scalars["String"];
  /** version 4(random) UUID */
  id: Scalars["ID"];
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
};

/** Goodleap Employment Status Type */
export enum Employment_Status {
  Employed = "EMPLOYED",
  NotEmployed = "NOT_EMPLOYED",
  Other = "OTHER",
  Retired = "RETIRED",
}

export type EnfinApplicationDetails = {
  __typename?: "EnfinApplicationDetails";
  appStatus: Scalars["String"];
  message: Scalars["String"];
};

export type Enterprise = {
  __typename?: "Enterprise";
  cobrandSettings?: Maybe<EnterpriseCobrandSettingsObject>;
  commissionVisibility?: Maybe<Scalars["String"]>;
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  /** version 4(random) UUID */
  id: Scalars["ID"];
  logo?: Maybe<Logo>;
  logoUrl?: Maybe<Scalars["String"]>;
  marginVisibility?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  owner: User;
  ownerId: Scalars["String"];
  poisonId: Scalars["String"];
  powurId: Scalars["Float"];
  shouldCobrand: Scalars["Boolean"];
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
};

export type EnterpriseCobrandSettingsObject = {
  __typename?: "EnterpriseCobrandSettingsObject";
  brochureSlides: Array<BrochureSlidesObject>;
  createdAt: Scalars["DateTime"];
  enterpriseId: Scalars["String"];
  updatedAt: Scalars["DateTime"];
};

export type EnterpriseLogoDto = {
  __typename?: "EnterpriseLogoDto";
  bucket: Scalars["String"];
  key: Scalars["String"];
};

export type EnterpriseLogoDtoInput = {
  bucket: Scalars["String"];
  key: Scalars["String"];
};

export type EnterpriseMarginSettingsDto = {
  __typename?: "EnterpriseMarginSettingsDto";
  manager_margin_adjustment: Scalars["Boolean"];
  manager_minimum_margin: Scalars["Float"];
  margin_fixed: Scalars["Boolean"];
  proposal_margin: Scalars["Float"];
};

export type EnterpriseSettings = {
  __typename?: "EnterpriseSettings";
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  /** version 4(random) UUID */
  id: Scalars["ID"];
  managerMarginAdjustment: Scalars["Boolean"];
  managerMinimumMargin: Scalars["Float"];
  marginFixed: Scalars["Boolean"];
  proposalMargin: Scalars["Float"];
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
};

export type Equipment = {
  __typename?: "Equipment";
  basicAdderAssociations: Array<BasicAdderAssociation>;
  catalogNumber?: Maybe<Scalars["String"]>;
  cecWeightedEfficiency: Scalars["Float"];
  childEquipmentAssociations: Scalars["JSON"];
  compatibleInverterManufacturers: Array<CompatibleManufacturer>;
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  currentProposalPrice: Scalars["Float"];
  description?: Maybe<Scalars["String"]>;
  energyBackupData?: Maybe<Scalars["JSON"]>;
  equipmentDocuments: Scalars["JSON"];
  equipmentRegions?: Maybe<Array<EquipmentRegion>>;
  equipmentType: EquipmentType;
  equipmentTypeId: Scalars["String"];
  fallbackPrice?: Maybe<Scalars["Float"]>;
  height?: Maybe<Scalars["Float"]>;
  /** version 4(random) UUID */
  id: Scalars["ID"];
  inverterData?: Maybe<Scalars["JSON"]>;
  isKit: Scalars["Boolean"];
  manufacturer: Manufacturer;
  manufacturerId: Scalars["String"];
  marketingPdfUrl?: Maybe<Scalars["String"]>;
  moduleData?: Maybe<Scalars["JSON"]>;
  name: Scalars["String"];
  parentEquipmentAssociations: Scalars["JSON"];
  powurOsEnergyBackupDataId?: Maybe<Scalars["Float"]>;
  powurOsEquipmentTypeId: Scalars["Float"];
  powurOsId: Scalars["Float"];
  powurOsInverterDataId?: Maybe<Scalars["Float"]>;
  powurOsManufacturerId: Scalars["Float"];
  powurOsModuleDataId?: Maybe<Scalars["Float"]>;
  powurOwned: Scalars["Boolean"];
  proposalPrices: Array<Scalars["JSON"]>;
  sunnovaAccepted: Scalars["Boolean"];
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
  warranty?: Maybe<Scalars["JSON"]>;
  wattage?: Maybe<Scalars["Float"]>;
  width?: Maybe<Scalars["Float"]>;
};

export type EquipmentRegion = {
  __typename?: "EquipmentRegion";
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  equipment?: Maybe<Equipment>;
  equipmentId: Scalars["String"];
  /** version 4(random) UUID */
  id: Scalars["ID"];
  isDefault: Scalars["Boolean"];
  powurOsEquipmentId: Scalars["Float"];
  powurOsRegionId: Scalars["Float"];
  powurOsStatusId: Scalars["Float"];
  powurOsTaxStatusId?: Maybe<Scalars["Float"]>;
  price: Scalars["Float"];
  region?: Maybe<Region>;
  regionId: Scalars["String"];
  status: EquipmentStatus;
  taxStatus?: Maybe<TaxStatus>;
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
};

export type EquipmentRegionFindManyWhereInput = {
  /** limit to where the provided equipment has a relation */
  equipmentId?: InputMaybe<Scalars["String"]>;
  /** include only default equipment */
  isDefault?: InputMaybe<Scalars["Boolean"]>;
  /** limit to where the provided region has a relation */
  regionId?: InputMaybe<Scalars["String"]>;
  /** limit to only the provided status */
  status?: InputMaybe<Scalars["String"]>;
  /** limit to only the provided tax status */
  taxStatus?: InputMaybe<Scalars["String"]>;
};

export type EquipmentRegionOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type EquipmentRegionsInput = {
  filter?: InputMaybe<EquipmentRegionFindManyWhereInput>;
  paginate?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<EquipmentRegionOrderByInput>;
};

export type EquipmentStatus = {
  __typename?: "EquipmentStatus";
  id: Scalars["Float"];
  name: Scalars["String"];
};

export type EquipmentType = {
  __typename?: "EquipmentType";
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  equipment?: Maybe<Array<Equipment>>;
  /** version 4(random) UUID */
  id: Scalars["ID"];
  name: Scalars["String"];
  powurOsId: Scalars["Float"];
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
};

export type Estimate = {
  __typename?: "Estimate";
  /** Annual AC system output. (kWhac) */
  acAnnual: Scalars["Float"];
  /** Hourly AC system output (only when timeframe=hourly). (Wac) */
  acHourly: Array<Scalars["Float"]>;
  /** Monthly AC system output. (kWhac).  The array of values represents the value for each month, with the first element being for January and the last element being for December. */
  acMonthly: Array<Scalars["Float"]>;
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  /** The ID of the associated design */
  designId: Scalars["String"];
  /** Any error messages resulting from the request. */
  errors: Array<Scalars["String"]>;
  /** The ID of the estimate. */
  id: Scalars["ID"];
  /** Inputs used to make the request to PvWatts API */
  inputs?: Maybe<PvWatts_Inputs>;
  /** The ID of the associated install area */
  installAreaId: Scalars["String"];
  solarProfile?: Maybe<GenabilityProfile>;
  solarProfileId?: Maybe<Scalars["String"]>;
  /** Information about the SSC library. */
  sscInfo?: Maybe<SscInfo>;
  /** Information about the climate data used in the simulation. */
  stationInfo?: Maybe<StationInfo>;
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
};

export type EstimateResult = Estimate | PoisonedEstimate;

export type FinancingProduct = {
  __typename?: "FinancingProduct";
  id: Scalars["String"];
  maxBorrowingAmount: Scalars["Float"];
  minBorrowingAmount: Scalars["Float"];
  name: Scalars["String"];
};

export type FindEstimateInput = {
  id: Scalars["ID"];
};

export type FindProposalInput = {
  /** Proposal ID */
  id?: InputMaybe<Scalars["String"]>;
  /** Proposal unique slug */
  slug?: InputMaybe<Scalars["String"]>;
};

export type GenabilityAccountAnalysis = {
  __typename?: "GenabilityAccountAnalysis";
  currency?: Maybe<Scalars["String"]>;
  dataStatus: Scalars["Float"];
  designId?: Maybe<Scalars["String"]>;
  scenarios: Array<GenabilityScenario>;
  series: Array<GenabilitySeries>;
  seriesCosts: Array<GenabilityCalculatedCost>;
  seriesData: Array<GenabilitySeriesMeasure>;
  summary: GenabilityAccountAnalysisSummary;
};

export type GenabilityAccountAnalysisInputs = {
  __typename?: "GenabilityAccountAnalysisInputs";
  consumptionProviderProfileId?: Maybe<Scalars["String"]>;
  fromDateTime?: Maybe<Scalars["String"]>;
  genabilityAccountId: Scalars["String"];
  optimizedConsumptionProviderProfileId?: Maybe<Scalars["String"]>;
  optimizedProductionProviderProfileId?: Maybe<Scalars["String"]>;
  postSolarMasterTariffId: Scalars["Float"];
  preSolarMasterTariffId: Scalars["Float"];
  productionProviderProfileIds?: Maybe<Array<Scalars["String"]>>;
  projectDuration: Scalars["Float"];
  rateInflation: Scalars["Float"];
  solarDegradation: Scalars["Float"];
  storageProviderProfileId?: Maybe<Scalars["String"]>;
  userAdjustedRate?: Maybe<Scalars["Float"]>;
};

export type GenabilityAccountAnalysisSummary = {
  __typename?: "GenabilityAccountAnalysisSummary";
  lifeTimeUtilityAfterCost?: Maybe<Scalars["Float"]>;
  lifeTimeUtilityAvoidedRate?: Maybe<Scalars["Float"]>;
  lifetimeAvoidedCost?: Maybe<Scalars["Float"]>;
  lifetimeSolarCost?: Maybe<Scalars["Float"]>;
  lifetimeWithoutCost?: Maybe<Scalars["Float"]>;
  netAvoidedCost?: Maybe<Scalars["Float"]>;
  netAvoidedCostPctOffset?: Maybe<Scalars["Float"]>;
  netAvoidedKWh?: Maybe<Scalars["Float"]>;
  netAvoidedKWhPctOffset?: Maybe<Scalars["Float"]>;
  netAvoidedRate?: Maybe<Scalars["Float"]>;
  postTotalCost: Scalars["Float"];
  postTotalKWh: Scalars["Float"];
  postTotalKWhCost: Scalars["Float"];
  postTotalKWhRate: Scalars["Float"];
  postTotalMinCost: Scalars["Float"];
  postTotalNonBypassableCost?: Maybe<Scalars["Float"]>;
  postTotalNonMinCost?: Maybe<Scalars["Float"]>;
  postTotalRate?: Maybe<Scalars["Float"]>;
  preTotalCost: Scalars["Float"];
  preTotalKWh: Scalars["Float"];
  preTotalKWhCost: Scalars["Float"];
  preTotalKWhRate: Scalars["Float"];
  preTotalMinCost: Scalars["Float"];
  preTotalNonBypassableCost: Scalars["Float"];
  preTotalNonMinCost: Scalars["Float"];
  preTotalRate: Scalars["Float"];
};

export type GenabilityCalculatedCost = {
  __typename?: "GenabilityCalculatedCost";
  accuracy: Scalars["Float"];
  assumptions: Array<GenabilityPropertyData>;
  calculatedCostId?: Maybe<Scalars["String"]>;
  currency: Scalars["String"];
  fromDateTime: Scalars["String"];
  items: Array<GenabilityCalculatedCostItem>;
  masterTariffId: Scalars["Float"];
  summary: GenabilityCalculatedCostSummary;
  tariffName: Scalars["String"];
  toDateTime: Scalars["String"];
  totalCost: Scalars["Float"];
};

export type GenabilityCalculatedCostItem = {
  __typename?: "GenabilityCalculatedCostItem";
  chargeClass?: Maybe<Scalars["String"]>;
  chargeType?: Maybe<Scalars["String"]>;
  cost?: Maybe<Scalars["Float"]>;
  demandInterval?: Maybe<Scalars["String"]>;
  duration?: Maybe<Scalars["Float"]>;
  formula?: Maybe<Scalars["String"]>;
  fromDateTime: Scalars["String"];
  itemQuantity: Scalars["Float"];
  periodType?: Maybe<Scalars["String"]>;
  quantityKey: Scalars["String"];
  quantityKeyDescription?: Maybe<Scalars["String"]>;
  rateAmount: Scalars["Float"];
  rateName?: Maybe<Scalars["String"]>;
  rateProration?: Maybe<Scalars["Float"]>;
  rateSequenceNumber?: Maybe<Scalars["Float"]>;
  rateType?: Maybe<Scalars["String"]>;
  seasonId?: Maybe<Scalars["Float"]>;
  seasonName?: Maybe<Scalars["String"]>;
  tariffId?: Maybe<Scalars["Float"]>;
  tariffRateBandId?: Maybe<Scalars["Float"]>;
  tariffRateId?: Maybe<Scalars["Float"]>;
  tierLowerLimit?: Maybe<Scalars["Float"]>;
  tierUpperLimit?: Maybe<Scalars["Float"]>;
  toDateTime: Scalars["String"];
  touId?: Maybe<Scalars["Float"]>;
  touName?: Maybe<Scalars["String"]>;
  transactionType?: Maybe<Scalars["String"]>;
};

export type GenabilityCalculatedCostSummary = {
  __typename?: "GenabilityCalculatedCostSummary";
  ELECTRICITY?: Maybe<GenabilityKWkWh>;
  adjustedTotalCost: Scalars["Float"];
  export?: Maybe<Scalars["Float"]>;
  import?: Maybe<Scalars["Float"]>;
  kW: Scalars["Float"];
  kWh: Scalars["Float"];
  nonBypassableCost?: Maybe<Scalars["Float"]>;
  preTaxMinimumCost?: Maybe<Scalars["Float"]>;
  subTotalCost: Scalars["Float"];
  taxCost: Scalars["Float"];
  totalCost: Scalars["Float"];
};

export type GenabilityKWkWh = {
  __typename?: "GenabilityKWkWh";
  kW: Scalars["Float"];
  kWh: Scalars["Float"];
};

export type GenabilityProfile = {
  __typename?: "GenabilityProfile";
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  /** version 4(random) UUID */
  id: Scalars["ID"];
  poisonId?: Maybe<Scalars["String"]>;
  profileId: Scalars["String"];
  providerProfileId: Scalars["String"];
  savingsId?: Maybe<Scalars["String"]>;
  siteId: Scalars["String"];
  type: GenabilityProfileType;
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
};

export enum GenabilityProfileType {
  SolarPost = "solar_post",
  StoragePost = "storage_post",
  UsagePost = "usage_post",
  UsagePre = "usage_pre",
}

export type GenabilityPropertyData = {
  __typename?: "GenabilityPropertyData";
  accuracy?: Maybe<Scalars["Float"]>;
  dataFactor?: Maybe<Scalars["Float"]>;
  dataSeries?: Maybe<Array<Scalars["Float"]>>;
  dataType?: Maybe<DataType>;
  dataValue?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  displayName?: Maybe<Scalars["String"]>;
  duration?: Maybe<Scalars["Float"]>;
  fromDateTime?: Maybe<Scalars["String"]>;
  keyName: Scalars["String"];
  operator?: Maybe<Scalars["String"]>;
  toDateTime?: Maybe<Scalars["String"]>;
  unit?: Maybe<Scalars["String"]>;
};

export type GenabilityScenario = {
  __typename?: "GenabilityScenario";
  id?: Maybe<Scalars["String"]>;
  inputs: Array<GenabilityPropertyData>;
  name?: Maybe<Scalars["String"]>;
  rates?: Maybe<Array<GenabilityTariffRateSimple>>;
  serviceType?: Maybe<Scalars["String"]>;
};

export type GenabilitySeries = {
  __typename?: "GenabilitySeries";
  cost: Scalars["Float"];
  designId?: Maybe<Scalars["String"]>;
  displayLabel: Scalars["String"];
  fromDateTime: Scalars["String"];
  key?: Maybe<Scalars["String"]>;
  qty: Scalars["Float"];
  rate?: Maybe<Scalars["Float"]>;
  scenario: Scalars["String"];
  seriesDuration: Scalars["Float"];
  seriesId: Scalars["Float"];
  seriesPeriod: Scalars["String"];
  toDateTime: Scalars["String"];
};

export type GenabilitySeriesMeasure = {
  __typename?: "GenabilitySeriesMeasure";
  cost: Scalars["Float"];
  fromDateTime: Scalars["String"];
  qty: Scalars["Float"];
  rate?: Maybe<Scalars["Float"]>;
  seriesId: Scalars["Float"];
  toDateTime: Scalars["String"];
};

export type GenabilityTariffRateBand = {
  __typename?: "GenabilityTariffRateBand";
  hasConsumptionLimit?: Maybe<Scalars["Boolean"]>;
  hasDemandLimit?: Maybe<Scalars["Boolean"]>;
  hasPropertyLimit?: Maybe<Scalars["Boolean"]>;
  isCredit: Scalars["Boolean"];
  prevUpperLimit?: Maybe<Scalars["Float"]>;
  rateAmount?: Maybe<Scalars["Float"]>;
  rateSequenceNumber?: Maybe<Scalars["Float"]>;
  rateUnit?: Maybe<Scalars["String"]>;
  tariffRateBandId?: Maybe<Scalars["Float"]>;
  tariffRateId?: Maybe<Scalars["Float"]>;
};

export type GenabilityTariffRateSimple = {
  __typename?: "GenabilityTariffRateSimple";
  chargeClass?: Maybe<Scalars["String"]>;
  chargePeriod?: Maybe<Scalars["String"]>;
  chargeType?: Maybe<Scalars["String"]>;
  fromDateTime?: Maybe<Scalars["String"]>;
  rateBands?: Maybe<Array<GenabilityTariffRateBand>>;
  rateName: Scalars["String"];
  scenarios?: Maybe<Scalars["String"]>;
  toDateTime?: Maybe<Scalars["String"]>;
};

export type GetDisclosuresByLoanProductIdResponse =
  | GoodLeapGetDisclosuresResponseDto
  | MosaicGetDisclosuresResponseDto
  | SunlightGetDisclosuresResponse;

export type GetMockAddressInput = {
  state?: InputMaybe<MockAddressStateCode>;
};

export type GetSunlightIframeUrlDto = {
  loanProductId: Scalars["String"];
  proposalId: Scalars["String"];
};

export type GoodLeapCreateLoanRequestDto = {
  amount: CurrencyInput;
  applicant: ApplicantInput;
  applicantSignature: Scalars["String"];
  categoryId: Scalars["String"];
  channel: Scalars["String"];
  coApplicant?: InputMaybe<CoApplicant>;
  disclosureToken: Scalars["String"];
  enrollments: Array<Scalars["String"]>;
  offerId?: InputMaybe<Scalars["String"]>;
  referenceNumber?: InputMaybe<Scalars["String"]>;
  subjectProperty: SubjectPropertyInput;
  submittingUser: SubmittingUser;
};

export type GoodLeapCreateLoanResponseDto = {
  __typename?: "GoodLeapCreateLoanResponseDto";
  appliedFor?: Maybe<Scalars["JSON"]>;
  approvedFor?: Maybe<Scalars["JSON"]>;
  approvedLoanAmount: Currency;
  changes?: Maybe<Array<Scalars["JSON"]>>;
  id: Scalars["String"];
  offerId?: Maybe<Scalars["String"]>;
  pendingCases?: Maybe<Array<Scalars["JSON"]>>;
  referenceNumber: Scalars["String"];
  requestedLoanAmount: Currency;
  sentLoanDocuments: Scalars["Boolean"];
  status: Scalars["String"];
  stipulations: Array<Stipulation>;
  tags?: Maybe<Scalars["JSON"]>;
  type?: Maybe<Scalars["String"]>;
};

export type GoodLeapDisclosuresDataArrayDto = {
  __typename?: "GoodLeapDisclosuresDataArrayDto";
  current: Scalars["Boolean"];
  id: Scalars["String"];
  meta: GoodLeapDisclosuresMetaObjectDto;
  name: Scalars["String"];
  sections: Array<GoodLeapDisclosuresSectionArrayDto>;
  token?: Maybe<Scalars["String"]>;
  tokenExpiresAt?: Maybe<Scalars["String"]>;
};

export type GoodLeapDisclosuresMetaObjectDto = {
  __typename?: "GoodLeapDisclosuresMetaObjectDto";
  cursor?: Maybe<Scalars["String"]>;
};

export type GoodLeapDisclosuresSectionArrayDto = {
  __typename?: "GoodLeapDisclosuresSectionArrayDto";
  boldText: Scalars["Boolean"];
  checkbox: Scalars["Boolean"];
  signatureBox: Scalars["Boolean"];
  sort: Scalars["Float"];
  text: Scalars["String"];
};

export type GoodLeapGetDisclosuresResponseDto = {
  __typename?: "GoodLeapGetDisclosuresResponseDto";
  data: Array<GoodLeapDisclosuresDataArrayDto>;
  links: GoodleapDisclosuresLinks;
};

export type GoodleapAmount = {
  __typename?: "GoodleapAmount";
  type: Scalars["String"];
  value: Scalars["Float"];
};

export type GoodleapAttachment = {
  __typename?: "GoodleapAttachment";
  extension: Scalars["String"];
  groupId: Scalars["String"];
  id: Scalars["String"];
  label: Scalars["String"];
  name: Scalars["String"];
  status: Scalars["String"];
  type: Scalars["String"];
};

export type GoodleapBorrower = {
  __typename?: "GoodleapBorrower";
  address: Address;
  annualIncome: GoodleapValue;
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  phones: Array<Phone>;
};

export type GoodleapCalculation = {
  __typename?: "GoodleapCalculation";
  amount: GoodleapAmount;
  interval: Scalars["String"];
  paydownAmount?: Maybe<GoodleapAmount>;
  paymentDescription?: Maybe<Scalars["String"]>;
  roundedAmount: GoodleapAmount;
  type: Scalars["String"];
};

export type GoodleapDisclosuresLinks = {
  __typename?: "GoodleapDisclosuresLinks";
  next?: Maybe<Scalars["String"]>;
  prev?: Maybe<Scalars["String"]>;
};

export type GoodleapDocumentsToClear = {
  __typename?: "GoodleapDocumentsToClear";
  description: Scalars["String"];
  name: Scalars["String"];
  type: Scalars["String"];
};

export type GoodleapEnrollment = {
  __typename?: "GoodleapEnrollment";
  name: Scalars["String"];
};

export type GoodleapLoanDetails = {
  __typename?: "GoodleapLoanDetails";
  amount: GoodleapAmount;
  borrower: GoodleapBorrower;
  changes: Array<Scalars["String"]>;
  coBorrower?: Maybe<GoodleapBorrower>;
  createdAt: Scalars["String"];
  creditExpiresAt: Scalars["String"];
  enrollments: Array<GoodleapEnrollment>;
  estimatedInitialMonthlyPayment: GoodleapAmount;
  id: Scalars["String"];
  installationCategoryId: Scalars["String"];
  maxAmount: GoodleapAmount;
  monthlyPayment: GoodleapAmount;
  offerId: Scalars["String"];
  partnerId: Scalars["String"];
  referenceNumber: Scalars["String"];
  status: Scalars["String"];
  subjectProperty: SubjectProperty;
};

export type GoodleapPaymentsResponse = {
  __typename?: "GoodleapPaymentsResponse";
  calculations: Array<GoodleapCalculation>;
  disclaimerText?: Maybe<Scalars["String"]>;
  loanAmount: GoodleapAmount;
  offerId: Scalars["String"];
};

export type GoodleapStipulationDetailed = {
  __typename?: "GoodleapStipulationDetailed";
  appliesTo?: Maybe<Scalars["String"]>;
  attachments?: Maybe<Array<Scalars["JSON"]>>;
  createdAt: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  documentsToClear?: Maybe<Array<Scalars["JSON"]>>;
  domain: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
  reasonCodes: Array<Scalars["String"]>;
  status: Scalars["String"];
  type: Scalars["String"];
  updatedAt: Scalars["String"];
};

export type GoodleapStipulations = {
  __typename?: "GoodleapStipulations";
  appliesTo?: Maybe<Scalars["String"]>;
  attachments?: Maybe<Array<GoodleapAttachment>>;
  createdAt?: Maybe<Scalars["String"]>;
  deferred?: Maybe<Scalars["Boolean"]>;
  description?: Maybe<Scalars["String"]>;
  documentsToClear?: Maybe<Array<GoodleapDocumentsToClear>>;
  domain?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  name?: Maybe<Scalars["String"]>;
  reasonCodes?: Maybe<Array<Scalars["String"]>>;
  status?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
  updatedAt?: Maybe<Scalars["String"]>;
  updatedBy?: Maybe<Scalars["String"]>;
};

export type GoodleapValue = {
  __typename?: "GoodleapValue";
  type: Scalars["String"];
  value: Scalars["Float"];
};

/** Goodleap Home Type */
export enum Home_Occupancy {
  Investment = "INVESTMENT",
  Other = "OTHER",
  Primary = "PRIMARY",
  Secondary = "SECONDARY",
}

/** Goodleap Home Ownership Type */
export enum Home_Ownership {
  NotOwned = "NOT_OWNED",
  OwnedFreeAndClear = "OWNED_FREE_AND_CLEAR",
  OwnedWithMortgage = "OWNED_WITH_MORTGAGE",
}

export type ImageSizes = {
  __typename?: "ImageSizes";
  large?: Maybe<Scalars["String"]>;
  original?: Maybe<Scalars["String"]>;
  preview?: Maybe<Scalars["String"]>;
  retina?: Maybe<Scalars["String"]>;
  thumb?: Maybe<Scalars["String"]>;
};

export type Imagery = {
  __typename?: "Imagery";
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  filename: Scalars["String"];
  height: Scalars["Float"];
  /** version 4(random) UUID */
  id: Scalars["ID"];
  imageDate?: Maybe<Scalars["String"]>;
  positionedX?: Maybe<Scalars["Float"]>;
  positionedY?: Maybe<Scalars["Float"]>;
  positionedZoom?: Maybe<Scalars["Float"]>;
  /** The site this imagery belongs to */
  site: Site;
  siteId: Scalars["ID"];
  source: ImagerySource;
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
  width: Scalars["Float"];
  zoomLevel: Scalars["Float"];
};

export type ImageryResult = Imagery | PoisonedImagery;

export enum ImagerySource {
  Bing = "bing",
  Google = "google",
  Nearmap = "nearmap",
  Pictometry = "pictometry",
}

export type Incentive = {
  __typename?: "Incentive";
  activatedAt?: Maybe<Scalars["DateTime"]>;
  activeInZipCodes: Array<Scalars["String"]>;
  applicabilities?: Maybe<Array<Applicability>>;
  applicabilityMaxValue?: Maybe<Scalars["Float"]>;
  applicabilityMinValue?: Maybe<Scalars["Float"]>;
  applicabilityName?: Maybe<Scalars["String"]>;
  canApplyToLoan: Scalars["Boolean"];
  createdAt: Scalars["DateTime"];
  customDisplayName?: Maybe<Scalars["String"]>;
  customerClass: Scalars["String"];
  documents?: Maybe<Scalars["String"]>;
  eligibility: Scalars["String"];
  endDate?: Maybe<Scalars["String"]>;
  id: Scalars["String"];
  incentiveId?: Maybe<Scalars["Float"]>;
  incentiveName: Scalars["String"];
  incentivePaidTo?: Maybe<Scalars["String"]>;
  incentiveType: Scalars["String"];
  isExhausted: Scalars["Boolean"];
  isPaidAsLumpSum: Scalars["Boolean"];
  isSungageRebate: Scalars["Boolean"];
  jurisdiction: Scalars["String"];
  lseCode?: Maybe<Scalars["String"]>;
  lseId?: Maybe<Scalars["Float"]>;
  lseName?: Maybe<Scalars["String"]>;
  masterIncentiveId?: Maybe<Scalars["Float"]>;
  paymentCap?: Maybe<Scalars["Float"]>;
  paymentDuration: Scalars["Float"];
  percentCostCap?: Maybe<Scalars["Float"]>;
  percentCostCapKey?: Maybe<PropertyKey>;
  projectType: Scalars["String"];
  projectTypeExclusive?: Maybe<Scalars["Boolean"]>;
  quantityKey: PropertyKey;
  quantityKeyCap?: Maybe<Scalars["String"]>;
  rate: Scalars["Float"];
  rateUnit: Scalars["String"];
  requiredData?: Maybe<Array<Applicability>>;
  requirements?: Maybe<Scalars["String"]>;
  serviceType?: Maybe<Scalars["String"]>;
  source: Scalars["String"];
  startDate: Scalars["String"];
  state: Scalars["String"];
  summary: Scalars["String"];
  tariffCode?: Maybe<Scalars["String"]>;
  turnedOnByDefaultAt?: Maybe<Scalars["DateTime"]>;
  updatedAt: Scalars["DateTime"];
};

export type IncentiveFindManyWhereInput = {
  search?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  zipCode?: InputMaybe<Scalars["String"]>;
};

export type IncentiveInput = {
  filter?: InputMaybe<IncentiveFindManyWhereInput>;
  paginate?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<IncentiveOrderByInput>;
};

export type IncentiveOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
};

export type LoanProduct = {
  __typename?: "LoanProduct";
  activatedAt?: Maybe<Scalars["DateTime"]>;
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  /** version 4(random) UUID */
  id: Scalars["ID"];
  introPaymentFactor: Scalars["Float"];
  maximumLoanAmount?: Maybe<Scalars["Float"]>;
  minimumCreditScore?: Maybe<Scalars["Float"]>;
  minimumLoanAmount?: Maybe<Scalars["Float"]>;
  powurOsCurrentDealerFee: Scalars["Float"];
  powurOsCurrentPowurMargin: Scalars["Float"];
  powurOsDealerFeePlusMargin: Scalars["Float"];
  /** Unique ID for loan products as stored in OS's database */
  powurOsLoanId?: Maybe<Scalars["Float"]>;
  productName: Scalars["String"];
  provider: LoanProvider;
  providerFee: Scalars["Float"];
  /** Unique id generated by loan provider */
  providerId: Scalars["String"];
  providerMeta?: Maybe<Scalars["JSON"]>;
  providerName?: Maybe<Scalars["String"]>;
  rate: Scalars["Float"];
  /** States that this loan product is active in */
  statesActive: Array<StateCode>;
  /** States that this loan product can be active in */
  statesPossible: Array<StateCode>;
  syncHistory?: Maybe<Scalars["JSON"]>;
  term: Scalars["Float"];
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
};

export type LoanProductFindManyWhereInput = {
  provider?: InputMaybe<LoanProvider>;
  search?: InputMaybe<Scalars["String"]>;
  state?: InputMaybe<StateCode>;
};

export type LoanProductsInput = {
  filter?: InputMaybe<LoanProductFindManyWhereInput>;
  paginate?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<LoanProductsOrderByInput>;
};

export type LoanProductsOrderByInput = {
  activatedAt?: InputMaybe<SortOrder>;
  powurOsCurrentDealerFee?: InputMaybe<SortOrder>;
  powurOsCurrentPowurMargin?: InputMaybe<SortOrder>;
  powurOsDealerFeePlusMargin?: InputMaybe<SortOrder>;
  productName?: InputMaybe<SortOrder>;
  provider?: InputMaybe<SortOrder>;
  providerName?: InputMaybe<SortOrder>;
  rate?: InputMaybe<SortOrder>;
  syncHistory?: InputMaybe<SortOrder>;
  term?: InputMaybe<SortOrder>;
};

export enum LoanProvider {
  Enfin = "enfin",
  Goodleap = "goodleap",
  Mosaic = "mosaic",
  Sungage = "sungage",
  Sunlight = "sunlight",
}

export type Logo = {
  __typename?: "Logo";
  bucket: Scalars["String"];
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  /** version 4(random) UUID */
  id: Scalars["ID"];
  key: Scalars["String"];
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
};

export type Manufacturer = {
  __typename?: "Manufacturer";
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  /** version 4(random) UUID */
  id: Scalars["ID"];
  name: Scalars["String"];
  powurOsId: Scalars["Float"];
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
};

export enum MockAddressStateCode {
  Az = "AZ",
  Ca = "CA",
  Co = "CO",
  Ct = "CT",
  Dc = "DC",
  Fl = "FL",
  Ma = "MA",
  Md = "MD",
}

export type MoqProposalInput = {
  dynamicEquipmentTypeName?: InputMaybe<Scalars["String"]>;
  financing?: InputMaybe<Scalars["String"]>;
  latitude?: InputMaybe<Scalars["Float"]>;
  longitude?: InputMaybe<Scalars["Float"]>;
  manufacturer?: InputMaybe<Scalars["String"]>;
  role?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
  systemSize?: InputMaybe<Scalars["Float"]>;
};

export type MosaicApplicationDto = {
  __typename?: "MosaicApplicationDto";
  id: Scalars["String"];
  status: Scalars["String"];
};

export type MosaicCreateNewUserBodyDto = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  partnerIdentifier: Scalars["String"];
  phoneNumber: Scalars["String"];
  role: Scalars["String"];
  status: Scalars["String"];
};

export type MosaicDisclosuresArrayDto = {
  __typename?: "MosaicDisclosuresArrayDto";
  assetCode?: Maybe<Scalars["String"]>;
  assetType: Scalars["String"];
  contents: Array<MosaicDisclosuresContentArrayDto>;
  scope: Scalars["String"];
  title: Scalars["String"];
  version: Scalars["String"];
};

export type MosaicDisclosuresContentArrayDto = {
  __typename?: "MosaicDisclosuresContentArrayDto";
  contentType: Scalars["String"];
  data: Scalars["String"];
  title: Scalars["String"];
};

export type MosaicGetApplicationResponseDto = {
  __typename?: "MosaicGetApplicationResponseDto";
  decision: Decision;
  externalId: Scalars["String"];
  id: Scalars["String"];
  salesRepExternalIdentifier: Scalars["String"];
  salesRepIdentifier: Scalars["String"];
  status: Scalars["String"];
};

export type MosaicGetDisclosuresBodyDto = {
  category: Scalars["String"];
  combined: Scalars["Boolean"];
  format: Scalars["String"];
  language: Scalars["String"];
  tag: Scalars["String"];
};

export type MosaicGetDisclosuresResponseDto = {
  __typename?: "MosaicGetDisclosuresResponseDto";
  bundleId: Scalars["String"];
  disclosures: Array<MosaicDisclosuresArrayDto>;
};

export type MosaicGetLoginsResponseDto = {
  __typename?: "MosaicGetLoginsResponseDto";
  email: Scalars["String"];
  firstName: Scalars["String"];
  id: Scalars["String"];
  lastName: Scalars["String"];
  partnerIdentifier: Scalars["String"];
  role: Scalars["String"];
  status: Scalars["String"];
};

export type MosaicGetUsersBodyDto = {
  after?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  limit?: InputMaybe<Scalars["String"]>;
  partnerIdentifier?: InputMaybe<Scalars["String"]>;
  role?: InputMaybe<Scalars["String"]>;
  status?: InputMaybe<Scalars["String"]>;
};

export type MosaicGetUsersResponseDto = {
  __typename?: "MosaicGetUsersResponseDto";
  links?: Maybe<MosaicGetUsersResponseLinksDto>;
  logins: Array<MosaicGetLoginsResponseDto>;
};

export type MosaicGetUsersResponseLinksDto = {
  __typename?: "MosaicGetUsersResponseLinksDto";
  additionalResults?: Maybe<Scalars["String"]>;
};

export type MosaicPostApplicationResponseDto = {
  __typename?: "MosaicPostApplicationResponseDto";
  application: MosaicApplicationDto;
};

export type Mutation = {
  __typename?: "Mutation";
  activateDesign: Design;
  activateLoanProduct: LoanProduct;
  /** activate or deactivate a region */
  activateRegion: Region;
  /** activate or deactivate a utility company */
  activateUtilityCompany: UtilityCompany;
  /** activate or deactivate a utility rate */
  activateUtilityRate: UtilityRate;
  addNoteDesignRequest: DesignRequest;
  cancelDesignRequest: DesignRequest;
  claimDesignRequest: DesignRequest;
  claimNextDesignRequest: DesignRequest;
  cloneProposal: Scalars["String"];
  completeClaimedDesignRequest: DesignRequest;
  completeClaimedDesignRequestAndClaimNext: DesignRequest;
  completeDesignRequest: DesignRequest;
  completeDesignRequestAndClaimNext: DesignRequest;
  /** create a custom incentive */
  createCustomIncentive: Incentive;
  createDesignRequest: DesignRequest;
  createDevProposal: Scalars["String"];
  createServiceIntegration: ServiceIntegration;
  createShortLink: ShortLink;
  createSite: Site;
  /** created a new user */
  createUser: User;
  deactivateApiKey: ServiceIntegration;
  designRequestClaimedUntil: Scalars["DateTime"];
  duplicateDesignRequest: DesignRequest;
  escalateDesignRequest: DesignRequest;
  escalateDesignRequestAndClaimNext: DesignRequest;
  generateApiKey: ServiceIntegration;
  getTaxes: TaxjarTaxForOrderResponse;
  logout: Scalars["Boolean"];
  moqProposal: Proposal;
  mosaicPostUser: MosaicGetUsersResponseDto;
  pauseDesignRequest: DesignRequest;
  prioritizeDesignRequest: DesignRequest;
  rejectDesignRequest: DesignRequest;
  /** soft remove the site from database matching provided id */
  removeSite?: Maybe<Scalars["Boolean"]>;
  /** soft remove the user from database matching provided id */
  removeUser?: Maybe<Scalars["Boolean"]>;
  requestDesignRevision: Design;
  /** reset a design to its original state */
  resetDesign: Design;
  sendCreditApplication: SendCreditApplicationResponse;
  sendLoanDocuments: ActiveLoan;
  syncAdders: AdderSyncResult;
  syncEquipment: Scalars["Boolean"];
  /** Fetch invcentives from Genability and insert into db */
  syncIncentives: Scalars["String"];
  syncLoanProducts: SyncLoanOfferResponse;
  updateDesign: Design;
  /** Update an equipment region's status */
  updateEquipmentRegion: EquipmentRegion;
  updateImagePosition: ImageryResult;
  /** update a custom incentive */
  updateIncentive: Incentive;
  updateLoanProduct: LoanProduct;
  updateProposal: Proposal;
  updateServiceIntegration: ServiceIntegration;
  updateSite: Site;
  updateStatusDesignComplete: ProposalStatus;
  updateStatusDesignFailed: ProposalStatus;
  updateStatusDesignQueued: ProposalStatus;
  updateStatusDesignRejected: ProposalStatus;
  updateStatusDesignUnderway: ProposalStatus;
  updateStatusProposalContractRequested: ProposalStatus;
  updateStatusProposalCreated: ProposalStatus;
  updateStatusProposalPublished: ProposalStatus;
  updateStatusSummaryConfirmed: ProposalStatus;
  /** update the given user with provided fields, returns a Not Found Error if the user does not exist */
  updateUser: User;
};

export type MutationActivateDesignArgs = {
  id: Scalars["ID"];
};

export type MutationActivateLoanProductArgs = {
  id: Scalars["ID"];
};

export type MutationActivateRegionArgs = {
  regionId?: InputMaybe<Scalars["String"]>;
};

export type MutationActivateUtilityCompanyArgs = {
  utilityCompanyId?: InputMaybe<Scalars["String"]>;
};

export type MutationActivateUtilityRateArgs = {
  utilityRateId?: InputMaybe<Scalars["String"]>;
};

export type MutationAddNoteDesignRequestArgs = {
  id: Scalars["ID"];
  message: Scalars["String"];
};

export type MutationCancelDesignRequestArgs = {
  id: Scalars["ID"];
};

export type MutationClaimDesignRequestArgs = {
  id: Scalars["ID"];
};

export type MutationCloneProposalArgs = {
  input: CloneProposalDtoInput;
};

export type MutationCompleteDesignRequestArgs = {
  id: Scalars["ID"];
};

export type MutationCompleteDesignRequestAndClaimNextArgs = {
  id: Scalars["ID"];
};

export type MutationCreateCustomIncentiveArgs = {
  createIncentiveInput: CreateCustomIncentiveInput;
};

export type MutationCreateDesignRequestArgs = {
  initialMessage?: InputMaybe<Scalars["String"]>;
  proposalId: Scalars["ID"];
  requestType?: InputMaybe<Scalars["String"]>;
};

export type MutationCreateDevProposalArgs = {
  input: PlatformCreateProposalDtoInput;
};

export type MutationCreateServiceIntegrationArgs = {
  createServiceIntegrationInput: CreateServiceIntegrationInput;
};

export type MutationCreateShortLinkArgs = {
  proposalId: Scalars["ID"];
};

export type MutationCreateSiteArgs = {
  createSiteInput: CreateSiteInput;
};

export type MutationCreateUserArgs = {
  createUserInput: CreateUserInput;
};

export type MutationDeactivateApiKeyArgs = {
  id: Scalars["ID"];
};

export type MutationDesignRequestClaimedUntilArgs = {
  id: Scalars["ID"];
};

export type MutationDuplicateDesignRequestArgs = {
  designRequestId: Scalars["ID"];
  message?: InputMaybe<Scalars["String"]>;
};

export type MutationEscalateDesignRequestArgs = {
  id: Scalars["ID"];
  message: Scalars["String"];
};

export type MutationEscalateDesignRequestAndClaimNextArgs = {
  id: Scalars["ID"];
  message: Scalars["String"];
};

export type MutationGenerateApiKeyArgs = {
  id: Scalars["ID"];
};

export type MutationGetTaxesArgs = {
  input: TaxjarTaxForOrderParams;
};

export type MutationMoqProposalArgs = {
  input: MoqProposalInput;
};

export type MutationMosaicPostUserArgs = {
  input: MosaicCreateNewUserBodyDto;
};

export type MutationPauseDesignRequestArgs = {
  pauseDesignRequestInput: PauseDesignRequestInput;
};

export type MutationPrioritizeDesignRequestArgs = {
  id: Scalars["ID"];
  priority: Scalars["Float"];
};

export type MutationRejectDesignRequestArgs = {
  id: Scalars["ID"];
  message: Scalars["String"];
};

export type MutationRemoveSiteArgs = {
  id: Scalars["ID"];
};

export type MutationRemoveUserArgs = {
  id: Scalars["ID"];
};

export type MutationRequestDesignRevisionArgs = {
  input: DesignRevisionInput;
};

export type MutationResetDesignArgs = {
  id: Scalars["ID"];
};

export type MutationSendCreditApplicationArgs = {
  input: SendCreditApplicationInput;
};

export type MutationSendLoanDocumentsArgs = {
  activeLoanId: Scalars["ID"];
};

export type MutationSyncAddersArgs = {
  regionIds?: InputMaybe<Array<Scalars["String"]>>;
};

export type MutationUpdateDesignArgs = {
  input: UpdateDesignInput;
};

export type MutationUpdateEquipmentRegionArgs = {
  id: Scalars["ID"];
  updateEquipmentRegionInput?: InputMaybe<UpdateEquipmentRegionInput>;
};

export type MutationUpdateImagePositionArgs = {
  imageryId: Scalars["ID"];
  updateImageryPositionInput: UpdateImageryPositionInput;
};

export type MutationUpdateIncentiveArgs = {
  updateIncentiveInput: UpdateIncentiveInput;
};

export type MutationUpdateLoanProductArgs = {
  UpdateLoanProductInput: UpdateLoanProductInput;
};

export type MutationUpdateProposalArgs = {
  updateProposalInput: UpdateProposalInput;
};

export type MutationUpdateServiceIntegrationArgs = {
  updateServiceIntegrationInput: UpdateServiceIntegrationInput;
};

export type MutationUpdateSiteArgs = {
  updateSiteInput: UpdateSiteInput;
};

export type MutationUpdateStatusDesignCompleteArgs = {
  data: CreateProposalStatusInput;
};

export type MutationUpdateStatusDesignFailedArgs = {
  data: CreateProposalStatusInput;
};

export type MutationUpdateStatusDesignQueuedArgs = {
  data: CreateProposalStatusInput;
};

export type MutationUpdateStatusDesignRejectedArgs = {
  data: CreateProposalStatusInput;
};

export type MutationUpdateStatusDesignUnderwayArgs = {
  data: CreateProposalStatusInput;
};

export type MutationUpdateStatusProposalContractRequestedArgs = {
  data: CreateProposalStatusInput;
};

export type MutationUpdateStatusProposalCreatedArgs = {
  data: CreateProposalStatusInput;
};

export type MutationUpdateStatusProposalPublishedArgs = {
  publishProposalInput: PublishProposalInput;
};

export type MutationUpdateStatusSummaryConfirmedArgs = {
  data: CreateProposalStatusInput;
};

export type MutationUpdateUserArgs = {
  id: Scalars["ID"];
  updateUserInput: UpdateUserInput;
};

export type NexusAddress = {
  city: Scalars["String"];
  country: Scalars["String"];
  id: Scalars["String"];
  state: Scalars["String"];
  street: Scalars["String"];
  zip: Scalars["String"];
};

export type OpenSolarGetTokenInput = {
  siteId: Scalars["String"];
  userId: Scalars["String"];
};

/** Goodleap Phone Type Type */
export enum Phone_Type {
  Home = "HOME",
  Mobile = "MOBILE",
}

/** Inputs used to make the request to PvWatts API */
export type PvWatts_Inputs = {
  __typename?: "PVWatts_Inputs";
  /**
   * Array type.
   *   Option	Description
   *   - 0	Fixed - Open Rack
   *   - 1	Fixed - Roof Mounted
   *   - 2	1-Axis
   *   - 3	1-Axis Backtracking
   *   - 4	2-Axis
   */
  array_type: Scalars["String"];
  /** Azimuth angle (degrees). */
  azimuth: Scalars["Float"];
  /** DC to AC ratio. */
  dc_ac_ratio?: Maybe<Scalars["Float"]>;
  /** Inverter efficiency. */
  inv_eff?: Maybe<Scalars["Float"]>;
  /** Latitude of the input location. (degrees) */
  lat: Scalars["Float"];
  /** Longitude of the input location. (degrees) */
  lon: Scalars["Float"];
  /**  System losses (percent). */
  losses: Scalars["Float"];
  /**
   * Module type.
   *
   *   Option	Description
   *   - 0	Standard
   *   - 1	Premium
   *   - 2	Thin film
   */
  module_type: Scalars["String"];
  /** Nameplate capacity (kW). */
  system_capacity?: Maybe<Scalars["Float"]>;
  /** Tilt angle (degrees) */
  tilt?: Maybe<Scalars["Float"]>;
  /** Time frame */
  timeframe?: Maybe<Scalars["String"]>;
};

export type PaginationInput = {
  skip?: InputMaybe<Scalars["Float"]>;
  take?: InputMaybe<Scalars["Float"]>;
};

export type PauseDesignRequestInput = {
  id: Scalars["String"];
  /** link to the ticket */
  url: Scalars["String"];
};

export enum Permission {
  ActiveSubscribers = "active_subscribers",
  Admin = "admin",
  Ambassador = "ambassador",
  ApiKeyAdmin = "api_key_admin",
  CanResetDesign = "can_reset_design",
  CanViewEnterpriseProposalPricingBreakdown = "can_view_enterprise_proposal_pricing_breakdown",
  CareersAdministrator = "careers_administrator",
  CommissionSpecialist = "commission_specialist",
  CompedSubscribers = "comped_subscribers",
  Consultants = "consultants",
  DesignerUser = "designer_user",
  Energy = "energy",
  EnterpriseAdmin = "enterprise_admin",
  EnterpriseAdminPro = "enterprise_admin_pro",
  EnterpriseComped = "enterprise_comped",
  EnterpriseConsultant = "enterprise_consultant",
  EnterpriseManager = "enterprise_manager",
  EnterpriseOwner = "enterprise_owner",
  EnterpriseOwnerPro = "enterprise_owner_pro",
  EnterpriseSuperAdmin = "enterprise_super_admin",
  EnterpriseSupport = "enterprise_support",
  EquipmentAdmin = "equipment_admin",
  Finance = "finance",
  FinancingAdmin = "financing_admin",
  FormerAmbassador = "former_ambassador",
  Guest = "guest",
  HasNoEnterpriseMarginLimit = "has_no_enterprise_margin_limit",
  Homeowner = "homeowner",
  Installer = "installer",
  Inv = "inv",
  LeadGenerator = "lead_generator",
  LicensesAdministrator = "licenses_administrator",
  MarketingStaff = "marketing_staff",
  MayUseLowerEnterpriseMarginLimit = "may_use_lower_enterprise_margin_limit",
  MentorshipAssignment = "mentorship_assignment",
  OpenSolarDesigner = "open_solar_designer",
  OpsSupport = "ops_support",
  Opsmanager = "opsmanager",
  OrgMentorAccess = "org_mentor_access",
  PlatformAdmins = "platform_admins",
  PlatformCommunications = "platform_communications",
  ProposalAdmin = "proposal_admin",
  ProposalCreation = "proposal_creation",
  SalesOrg = "sales_org",
  SalesPro = "sales_pro",
  SalesSupport = "sales_support",
  ServiceBureauAdmin = "service_bureau_admin",
  ServiceBureauUser = "service_bureau_user",
  Serviceareaupdater = "serviceareaupdater",
  Tier_2VisionDesigner = "tier_2_vision_designer",
  UserAdmin = "user_admin",
  VisionAdmin = "vision_admin",
  VisionApiKeyAdmin = "vision_api_key_admin",
  VisionDesignerUser = "vision_designer_user",
  VisionDeveloper = "vision_developer",
  VisionEquipmentAdmin = "vision_equipment_admin",
  VisionFinancingAdmin = "vision_financing_admin",
  VisionProposalAdmin = "vision_proposal_admin",
  VisionServiceBureauAdmin = "vision_service_bureau_admin",
  VisionServiceBureauUser = "vision_service_bureau_user",
  VisionUserAdmin = "vision_user_admin",
}

export type Phone = {
  __typename?: "Phone";
  type: Phone_Type;
  value: Scalars["String"];
};

export type PhoneInput = {
  type: Phone_Type;
  value: Scalars["String"];
};

export type PlatformCreateProposalDto = {
  __typename?: "PlatformCreateProposalDto";
  metadata?: Maybe<Scalars["String"]>;
  project: PowurProjectDto;
  request_type?: Maybe<Scalars["String"]>;
};

export type PlatformCreateProposalDtoInput = {
  metadata?: InputMaybe<Scalars["String"]>;
  project: PowurProjectDtoInput;
  request_type?: InputMaybe<Scalars["String"]>;
};

export type PlatformEnterpriseCobrandSettingsDto = {
  __typename?: "PlatformEnterpriseCobrandSettingsDto";
  brochure_slides: Array<BrochureSlidesObject>;
  enterprise_id: Scalars["Float"];
};

export type PlatformEnterpriseCobrandSettingsDtoInput = {
  brochure_slides: Array<BrochureSlidesObjectDtoInput>;
  enterprise_id: Scalars["Float"];
};

export type Poison = {
  __typename?: "Poison";
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  data: Scalars["JSON"];
  /** version 4(random) UUID */
  id: Scalars["ID"];
  reason: Scalars["String"];
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
};

export type PoisonedEstimate = {
  __typename?: "PoisonedEstimate";
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  data: Scalars["JSON"];
  estimate: Estimate;
  /** version 4(random) UUID */
  id: Scalars["ID"];
  poisonIds: Scalars["String"];
  reason: Scalars["String"];
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
};

export type PoisonedImagery = {
  __typename?: "PoisonedImagery";
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  data: Scalars["JSON"];
  /** version 4(random) UUID */
  id: Scalars["ID"];
  imagery: Imagery;
  reason: Scalars["String"];
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
};

export type PoisonedQuote = {
  __typename?: "PoisonedQuote";
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  data: Scalars["JSON"];
  /** version 4(random) UUID */
  id: Scalars["ID"];
  quote: Quote;
  reason: Scalars["String"];
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
};

export type PoisonedSavings = {
  __typename?: "PoisonedSavings";
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  data: Scalars["JSON"];
  /** version 4(random) UUID */
  id: Scalars["ID"];
  reason: Scalars["String"];
  savings: Savings;
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
};

export type PowurAddressDto = {
  __typename?: "PowurAddressDto";
  category: Scalars["String"];
  city: Scalars["String"];
  lat: Scalars["Float"];
  long: Scalars["Float"];
  postal: Scalars["String"];
  state: Scalars["String"];
  street: Scalars["String"];
};

export type PowurConstructionDetailsDto = {
  __typename?: "PowurConstructionDetailsDto";
  other: Array<Scalars["String"]>;
  preference: Scalars["String"];
};

export type PowurConstructionDetailsInput = {
  other: Array<Scalars["String"]>;
  preference: Scalars["String"];
};

export type PowurEnterpriseDto = {
  __typename?: "PowurEnterpriseDto";
  cobrand_settings?: Maybe<PlatformEnterpriseCobrandSettingsDto>;
  commission_visibility?: Maybe<Scalars["Boolean"]>;
  id: Scalars["Float"];
  logo?: Maybe<EnterpriseLogoDto>;
  margin_settings?: Maybe<EnterpriseMarginSettingsDto>;
  margin_visibility?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  owner: PutUserDto;
  should_cobrand?: Maybe<Scalars["Boolean"]>;
};

export type PowurEnterpriseDtoInput = {
  cobrand_settings?: InputMaybe<PlatformEnterpriseCobrandSettingsDtoInput>;
  commission_visibility?: InputMaybe<Scalars["Boolean"]>;
  id: Scalars["Float"];
  logo?: InputMaybe<EnterpriseLogoDtoInput>;
  margin_settings?: InputMaybe<PowurEnterpriseMarginSettingsDtoInput>;
  margin_visibility?: InputMaybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  owner: PutUserDtoInput;
  should_cobrand?: InputMaybe<Scalars["Boolean"]>;
};

export type PowurEnterpriseMarginSettingsDtoInput = {
  manager_margin_adjustment: Scalars["Boolean"];
  manager_minimum_margin: Scalars["Float"];
  margin_fixed: Scalars["Boolean"];
  proposal_margin: Scalars["Float"];
};

export type PowurHoaDto = {
  __typename?: "PowurHoaDto";
  name: Scalars["String"];
  phone: Scalars["String"];
};

export type PowurHoaInput = {
  name: Scalars["String"];
  phone: Scalars["String"];
};

export type PowurImageSizeDto = {
  __typename?: "PowurImageSizeDto";
  large?: Maybe<Scalars["String"]>;
  original?: Maybe<Scalars["String"]>;
  preview?: Maybe<Scalars["String"]>;
  retina?: Maybe<Scalars["String"]>;
  thumb?: Maybe<Scalars["String"]>;
};

export type PowurImageSizeDtoInput = {
  large?: InputMaybe<Scalars["String"]>;
  original?: InputMaybe<Scalars["String"]>;
  preview?: InputMaybe<Scalars["String"]>;
  retina?: InputMaybe<Scalars["String"]>;
  thumb?: InputMaybe<Scalars["String"]>;
};

export type PowurOsManufacturer = {
  __typename?: "PowurOsManufacturer";
  createdById?: Maybe<Scalars["String"]>;
  createdDateTime: Scalars["String"];
  deletedById: Scalars["String"];
  deletedDateTime: Scalars["String"];
  id?: Maybe<Scalars["Float"]>;
  modifiedById: Scalars["String"];
  modifiedDateTime: Scalars["String"];
  name: Scalars["String"];
};

export type PowurProfileImgDto = {
  __typename?: "PowurProfileImgDto";
  bucket?: Maybe<Scalars["String"]>;
  sizes?: Maybe<PowurImageSizeDto>;
};

export type PowurProfileImgDtoInput = {
  bucket?: InputMaybe<Scalars["String"]>;
  sizes?: InputMaybe<PowurImageSizeDtoInput>;
};

export type PowurProjectDto = {
  __typename?: "PowurProjectDto";
  enterprise: PowurEnterpriseDto;
  project_info: PowurProjectInfoDto;
  proposal_settings: ProposalSettingsDto;
  seller: PowurUserDto;
  site_info: PowurSiteInfoDto;
  utility_bill_info: PowurUtilityBillInfoDto;
};

export type PowurProjectDtoInput = {
  enterprise: PowurEnterpriseDtoInput;
  project_info: PowurProjectInfoInput;
  proposal_settings: ProposalSettingsDtoInput;
  seller: PowurUserDtoInput;
  site_info: PowurSiteInfoInput;
  utility_bill_info: PowurUtilityBillInfoInput;
};

export type PowurProjectInfoDto = {
  __typename?: "PowurProjectInfoDto";
  address: PowurAddressDto;
  email: Scalars["String"];
  first_name: Scalars["String"];
  id: Scalars["Float"];
  last_name: Scalars["String"];
  phone: Scalars["String"];
};

export type PowurProjectInfoInput = {
  address: DisplayAddressInput;
  email: Scalars["String"];
  first_name: Scalars["String"];
  id: Scalars["Float"];
  last_name: Scalars["String"];
  phone: Scalars["String"];
};

export type PowurRoofDto = {
  __typename?: "PowurRoofDto";
  age: Scalars["String"];
  main_service_panel: Scalars["String"];
  type: Scalars["String"];
};

export type PowurRoofDtoInput = {
  age: Scalars["String"];
  main_service_panel: Scalars["String"];
  type: Scalars["String"];
};

export type PowurSiteInfoDto = {
  __typename?: "PowurSiteInfoDto";
  construction_details: PowurConstructionDetailsDto;
  financing: Scalars["String"];
  hoa: PowurHoaDto;
  installer: Scalars["String"];
  project_notes: Scalars["String"];
  proposal_notes: Scalars["String"];
  roof: PowurRoofDto;
};

export type PowurSiteInfoInput = {
  construction_details: PowurConstructionDetailsInput;
  financing: Scalars["String"];
  hoa: PowurHoaInput;
  installer: Scalars["String"];
  project_notes: Scalars["String"];
  proposal_notes: Scalars["String"];
  roof: PowurRoofDtoInput;
};

export type PowurUserDto = {
  __typename?: "PowurUserDto";
  bio?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  enterprise_id?: Maybe<Scalars["Float"]>;
  first_name: Scalars["String"];
  id: Scalars["Float"];
  last_name: Scalars["String"];
  mentor?: Maybe<PowurUserDto>;
  phone?: Maybe<Scalars["String"]>;
  profile_img?: Maybe<PowurProfileImgDto>;
};

export type PowurUserDtoInput = {
  bio?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  enterprise_id?: InputMaybe<Scalars["Float"]>;
  first_name: Scalars["String"];
  id: Scalars["Float"];
  last_name: Scalars["String"];
  mentor?: InputMaybe<PowurUserDtoInput>;
  phone?: InputMaybe<Scalars["String"]>;
  profile_img?: InputMaybe<PowurProfileImgDtoInput>;
};

export type PowurUtilityBillInfoDto = {
  __typename?: "PowurUtilityBillInfoDto";
  annual_consumption_kwh: Scalars["Float"];
  company: Scalars["String"];
  url?: Maybe<Scalars["String"]>;
};

export type PowurUtilityBillInfoInput = {
  annual_consumption_kwh: Scalars["Float"];
  company: Scalars["String"];
  url?: InputMaybe<Scalars["String"]>;
};

export type PricedEquipment = {
  __typename?: "PricedEquipment";
  basicAdderAssociations: Array<BasicAdderAssociation>;
  catalogNumber?: Maybe<Scalars["String"]>;
  cecWeightedEfficiency: Scalars["Float"];
  childEquipmentAssociations: Scalars["JSON"];
  compatibleInverterManufacturers: Array<CompatibleManufacturer>;
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  currentProposalPrice: Scalars["Float"];
  description?: Maybe<Scalars["String"]>;
  energyBackupData?: Maybe<Scalars["JSON"]>;
  equipmentDocuments: Scalars["JSON"];
  equipmentRegions?: Maybe<Array<EquipmentRegion>>;
  equipmentType: EquipmentType;
  equipmentTypeId: Scalars["String"];
  fallbackPrice?: Maybe<Scalars["Float"]>;
  height?: Maybe<Scalars["Float"]>;
  /** version 4(random) UUID */
  id: Scalars["ID"];
  inverterData?: Maybe<Scalars["JSON"]>;
  isKit: Scalars["Boolean"];
  manufacturer: Manufacturer;
  manufacturerId: Scalars["String"];
  marketingPdfUrl?: Maybe<Scalars["String"]>;
  moduleData?: Maybe<Scalars["JSON"]>;
  name: Scalars["String"];
  parentEquipmentAssociations: Scalars["JSON"];
  powurOsEnergyBackupDataId?: Maybe<Scalars["Float"]>;
  powurOsEquipmentTypeId: Scalars["Float"];
  powurOsId: Scalars["Float"];
  powurOsInverterDataId?: Maybe<Scalars["Float"]>;
  powurOsManufacturerId: Scalars["Float"];
  powurOsModuleDataId?: Maybe<Scalars["Float"]>;
  powurOwned: Scalars["Boolean"];
  proposalPrices: Array<Scalars["JSON"]>;
  sunnovaAccepted: Scalars["Boolean"];
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
  warranty?: Maybe<Scalars["JSON"]>;
  wattage?: Maybe<Scalars["Float"]>;
  width?: Maybe<Scalars["Float"]>;
};

export type ProfileImg = {
  __typename?: "ProfileImg";
  bucket?: Maybe<Scalars["String"]>;
  sizes?: Maybe<ImageSizes>;
};

export type ProjectBodyResponse = {
  __typename?: "ProjectBodyResponse";
  amountDrawn: Scalars["Float"];
  amountRemaining: Scalars["Float"];
  applicants: Array<ApplicantBodyResponse>;
  approvedForPayments: Scalars["Boolean"];
  apr: Scalars["Float"];
  blockDraw: Scalars["Boolean"];
  drawAutoApproved: Scalars["Boolean"];
  drawCount: Scalars["Float"];
  drawExpired: Scalars["Boolean"];
  externalId: Scalars["String"];
  hashId: Scalars["String"];
  id: Scalars["String"];
  installCity: Scalars["String"];
  installStateName: Scalars["String"];
  installStreet: Scalars["String"];
  installZipCode: Scalars["Float"];
  installerEnglishOnly: Scalars["Boolean"];
  installerId?: Maybe<Scalars["String"]>;
  installerName?: Maybe<Scalars["String"]>;
  isACH: Scalars["Boolean"];
  isCreditAuthorized: Scalars["Boolean"];
  itcCutoff: Scalars["String"];
  language: Scalars["String"];
  lender: Scalars["String"];
  maxAvailable: Scalars["Float"];
  nonACHApr: Scalars["Float"];
  ownerEmail: Scalars["String"];
  ownerName: Scalars["String"];
  productType: Scalars["String"];
  projectCategory: Scalars["String"];
  projectStatus?: Maybe<Scalars["String"]>;
  quotes: Array<QuoteBodyResponse>;
  requestedLoanAmount: Scalars["Float"];
  showTranslationServices: Scalars["Boolean"];
  slfProductMinLoanAmount: Scalars["Float"];
  stage: Scalars["String"];
  statusText: Scalars["String"];
  stips?: Maybe<Array<StipsBodyResponse>>;
  systemDesigns: Array<SystemDesignBodyResponse>;
  term: Scalars["Float"];
};

export type PropertyKey = {
  __typename?: "PropertyKey";
  /** For properties of type CHOICE or LOOKUP, this contains the possible choices to choose from. */
  choices?: Maybe<Array<Choice>>;
  /** The data type of this property. Possible values are STRING, CHOICE, BOOLEAN, DATE, DECIMAL, INTEGER, FORMULA, LOOKUP (see Lookup API), or DEMAND. */
  dataType?: Maybe<Scalars["String"]>;
  /** A user-friendly detailed description of the purpose of this property. */
  description?: Maybe<Scalars["String"]>;
  /** A string that can be used to display to users. */
  displayName?: Maybe<Scalars["String"]>;
  /** The ID of the entity this property key belongs to. For example, for an LSE-specific property, this will be the LSE ID. For general properties, this will be null. */
  entityId?: Maybe<Scalars["Float"]>;
  /** The type of entity this property key belongs to. For example, for an LSE-specific property, this will be "LSE". For general properties, this will be null. */
  entityType?: Maybe<Scalars["String"]>;
  /** Second level of the categorization hierarchy, family groups keyNames together into logical areas, such as "billing", "generation", "load", "peakDemand", and "ratchetDemand". */
  family?: Maybe<Scalars["String"]>;
  /** For properties that specify a formula, this is the actual formula used. */
  formulaDetail?: Maybe<Scalars["String"]>;
  /** The unique name for this property. */
  keyName?: Maybe<Scalars["String"]>;
  /** The top level of the categorization hierarchy, keyspace defines major groups of keyNames, such as "customer", "electricity", "market", “tariff”, and "solarPV". */
  keyspace?: Maybe<Scalars["String"]>;
  /** For properties of type DEMAND, this specifies the size of the interval in minutes. */
  lookbackIntervalQuantity?: Maybe<Scalars["Float"]>;
  /** For properties of type DEMAND, this specifies the duration of a single lookback period. */
  lookbackPeriod?: Maybe<Scalars["String"]>;
  /** For properties of type DEMAND, this specifies the number of lookback periods. */
  lookbackQuantity?: Maybe<Scalars["Float"]>;
  /** For properties of type DEMAND, this specifies the applicable seasonId. */
  lookbackSeasonId?: Maybe<Scalars["Float"]>;
  /** For properties of type DEMAND, this specifies the applicable timeOfUseId. */
  lookbackTimeOfUseId?: Maybe<Scalars["Float"]>;
  /** The unit that applies to this property. e.g. "kWh", "Inches", "HP" */
  quantityUnit?: Maybe<Scalars["String"]>;
  /** Sub-property name for this property. */
  subKeyName?: Maybe<Scalars["String"]>;
};

export type PropertyKeyInput = {
  /** For properties of type CHOICE or LOOKUP, this contains the possible choices to choose from. */
  choices?: InputMaybe<Array<ChoiceInput>>;
  /** The data type of this property. Possible values are STRING, CHOICE, BOOLEAN, DATE, DECIMAL, INTEGER, FORMULA, LOOKUP (see Lookup API), or DEMAND. */
  dataType?: InputMaybe<Scalars["String"]>;
  /** A user-friendly detailed description of the purpose of this property. */
  description?: InputMaybe<Scalars["String"]>;
  /** A string that can be used to display to users. */
  displayName?: InputMaybe<Scalars["String"]>;
  /** The ID of the entity this property key belongs to. For example, for an LSE-specific property, this will be the LSE ID. For general properties, this will be null. */
  entityId?: InputMaybe<Scalars["Float"]>;
  /** The type of entity this property key belongs to. For example, for an LSE-specific property, this will be "LSE". For general properties, this will be null. */
  entityType?: InputMaybe<Scalars["String"]>;
  /** Second level of the categorization hierarchy, family groups keyNames together into logical areas, such as "billing", "generation", "load", "peakDemand", and "ratchetDemand". */
  family?: InputMaybe<Scalars["String"]>;
  /** For properties that specify a formula, this is the actual formula used. */
  formulaDetail?: InputMaybe<Scalars["String"]>;
  /** The unique name for this property. */
  keyName?: InputMaybe<Scalars["String"]>;
  /** The top level of the categorization hierarchy, keyspace defines major groups of keyNames, such as "customer", "electricity", "market", “tariff”, and "solarPV". */
  keyspace?: InputMaybe<Scalars["String"]>;
  /** For properties of type DEMAND, this specifies the size of the interval in minutes. */
  lookbackIntervalQuantity?: InputMaybe<Scalars["Float"]>;
  /** For properties of type DEMAND, this specifies the duration of a single lookback period. */
  lookbackPeriod?: InputMaybe<Scalars["String"]>;
  /** For properties of type DEMAND, this specifies the number of lookback periods. */
  lookbackQuantity?: InputMaybe<Scalars["Float"]>;
  /** For properties of type DEMAND, this specifies the applicable seasonId. */
  lookbackSeasonId?: InputMaybe<Scalars["Float"]>;
  /** For properties of type DEMAND, this specifies the applicable timeOfUseId. */
  lookbackTimeOfUseId?: InputMaybe<Scalars["Float"]>;
  /** The unit that applies to this property. e.g. "kWh", "Inches", "HP" */
  quantityUnit?: InputMaybe<Scalars["String"]>;
  /** Sub-property name for this property. */
  subKeyName?: InputMaybe<Scalars["String"]>;
};

export type Proposal = {
  __typename?: "Proposal";
  activeLoans?: Maybe<Array<ActiveLoan>>;
  allowChangeOrderEquipment: Scalars["Boolean"];
  /** approximate annual energy use in kwh */
  annualEnergyUse?: Maybe<Scalars["Float"]>;
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  currentStatus?: Maybe<Scalars["String"]>;
  designs: Array<Design>;
  displayAddress: DisplayAddress;
  displayAddressId: Scalars["String"];
  downPayment?: Maybe<Scalars["Float"]>;
  enterprise?: Maybe<Enterprise>;
  enterpriseId?: Maybe<Scalars["String"]>;
  enterpriseLogoUrl?: Maybe<Scalars["String"]>;
  enterpriseSettings?: Maybe<EnterpriseSettings>;
  exciseTaxRate: Scalars["Float"];
  exciseTaxRateUpdatedAt?: Maybe<Scalars["DateTime"]>;
  /** genability default contracted rate if no adjusted rate is provided */
  genabilityDefaultContractedRate?: Maybe<Scalars["Float"]>;
  /** fixed genability usage */
  genabilityUsageEstimate?: Maybe<GenabilityCalculatedCost>;
  /** Homeowner for whom the proposal is being prepared */
  homeowner: User;
  homeownerId: Scalars["String"];
  /** version 4(random) UUID */
  id: Scalars["ID"];
  incentives?: Maybe<Array<ProposalIncentive>>;
  margin: Scalars["Float"];
  mentor?: Maybe<User>;
  mentorId?: Maybe<Scalars["String"]>;
  metadata: Scalars["String"];
  /** historic energy cost in $ */
  monthlyEnergyCost: Array<Scalars["Float"]>;
  /** historic energy usage kWh */
  monthlyEnergyUse: Array<Scalars["Float"]>;
  /** Proposal name */
  name?: Maybe<Scalars["String"]>;
  newCustomerIncentive: Scalars["Boolean"];
  /** Post-solar-install utility rate for the proposal */
  postSolarUtilityRate?: Maybe<UtilityRate>;
  postSolarUtilityRateId: Scalars["String"];
  powurProjectId?: Maybe<Scalars["Float"]>;
  preferredLanguage: Scalars["String"];
  primaryPresenter?: Maybe<Scalars["Float"]>;
  /** Project adders */
  proposalAdders: Array<ProposalAdderObject>;
  proposalCost: ProposalCost;
  proposalStatuses?: Maybe<Array<ProposalStatus>>;
  quotes?: Maybe<Array<QuoteResult>>;
  requestType: Scalars["String"];
  requiresRefresh: Scalars["Boolean"];
  salesTaxRate: Scalars["Float"];
  salesTaxRateUpdatedAt?: Maybe<Scalars["DateTime"]>;
  secondaryPresenter?: Maybe<Scalars["Float"]>;
  selectedLoanProducts: Array<LoanProduct>;
  /** Consultant for the proposal, has limited edit access */
  seller: User;
  sellerId: Scalars["String"];
  shortLink?: Maybe<ShortLink>;
  /** Site for which the proposal is being prepared */
  site: Site;
  siteId: Scalars["String"];
  /** Short unique identifier for the proposal */
  slug: Scalars["String"];
  /** system type (roof mount, ground mount, or both) */
  systemType?: Maybe<SystemType>;
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
  usageProfile?: Maybe<GenabilityProfile>;
  usageProfileId?: Maybe<Scalars["String"]>;
  userAdjustedAfterSolarContractedRate?: Maybe<Scalars["Float"]>;
  userAdjustedBeforeSolarContractedRate?: Maybe<Scalars["Float"]>;
  userAdjustedUtilityRate?: Maybe<Scalars["Float"]>;
  /** Utility rate plan for the proposal */
  utilityRate?: Maybe<UtilityRate>;
  /** utility rate escalation percentage */
  utilityRateEscalationPercentage: Scalars["Float"];
  utilityRateId: Scalars["String"];
};

export type ProposalAdderObject = {
  __typename?: "ProposalAdderObject";
  adder: Adder;
  createdAt: Scalars["DateTime"];
  customPrice?: Maybe<Scalars["Float"]>;
  customQuantity?: Maybe<Scalars["Float"]>;
  powurOsAdderId: Scalars["Float"];
  proposalId: Scalars["String"];
  /** Whether or not the adder is included in cost calculations */
  selected: Scalars["Boolean"];
  updatedAt: Scalars["DateTime"];
};

export type ProposalCost = {
  __typename?: "ProposalCost";
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  designCost: Scalars["Float"];
  exciseTax: Scalars["Float"];
  /** version 4(random) UUID */
  id: Scalars["ID"];
  lineItems: Array<ProposalLineItem>;
  proposalId: Scalars["String"];
  salesTax: Scalars["Float"];
  totalCost: Scalars["Float"];
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
};

export type ProposalIncentive = {
  __typename?: "ProposalIncentive";
  applyToLoan: Scalars["Boolean"];
  createdAt: Scalars["DateTime"];
  incentive: Incentive;
  incentiveId: Scalars["String"];
  proposalId: Scalars["String"];
  showOnBrochure: Scalars["Boolean"];
  updatedAt: Scalars["DateTime"];
  value: Scalars["Float"];
};

export type ProposalLineItem = {
  __typename?: "ProposalLineItem";
  children: Array<ProposalLineItem>;
  cost: Scalars["Float"];
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  displayName: Scalars["String"];
  /** version 4(random) UUID */
  id: Scalars["ID"];
  quantity: Scalars["Float"];
  subType?: Maybe<Scalars["String"]>;
  taxable: Scalars["Boolean"];
  type: Scalars["String"];
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
};

export type ProposalSettingsDto = {
  __typename?: "ProposalSettingsDto";
  primary_seller?: Maybe<Scalars["Float"]>;
  secondary_seller?: Maybe<Scalars["Float"]>;
};

export type ProposalSettingsDtoInput = {
  primary_seller?: InputMaybe<Scalars["Float"]>;
  secondary_seller?: InputMaybe<Scalars["Float"]>;
};

export type ProposalStatus = {
  __typename?: "ProposalStatus";
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  /** version 4(random) UUID */
  id: Scalars["ID"];
  /** Status message */
  message: Scalars["String"];
  /** Proposal ID */
  proposalId: Scalars["String"];
  /** Proposal status type */
  status: ProposalStatusType;
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
  /** Proposal updated by */
  updatedBy: Scalars["String"];
};

export enum ProposalStatusType {
  ContractRequested = "contract_requested",
  ContractSent = "contract_sent",
  ContractSigned = "contract_signed",
  DesignComplete = "design_complete",
  DesignFailed = "design_failed",
  DesignQueued = "design_queued",
  DesignRejected = "design_rejected",
  DesignUnderway = "design_underway",
  ProposalCreated = "proposal_created",
  ProposalPublished = "proposal_published",
  SummaryConfirmed = "summary_confirmed",
}

export type ProposalsFindManyWhereInput = {
  search?: InputMaybe<Scalars["String"]>;
};

export type ProposalsInput = {
  filter?: InputMaybe<ProposalsFindManyWhereInput>;
  paginate?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<ProposalsOrderByInput>;
};

export type ProposalsOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
};

export type PublishProposalInput = {
  message: Scalars["String"];
  proposalId: Scalars["String"];
};

export type PutUserDto = {
  __typename?: "PutUserDto";
  bio?: Maybe<Scalars["String"]>;
  email: Scalars["String"];
  enterprise_id?: Maybe<Scalars["Float"]>;
  first_name: Scalars["String"];
  id: Scalars["Float"];
  last_name: Scalars["String"];
  phone?: Maybe<Scalars["String"]>;
  profile_img?: Maybe<PowurProfileImgDto>;
};

export type PutUserDtoInput = {
  bio?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  enterprise_id?: InputMaybe<Scalars["Float"]>;
  first_name: Scalars["String"];
  id: Scalars["Float"];
  last_name: Scalars["String"];
  phone?: InputMaybe<Scalars["String"]>;
  profile_img?: InputMaybe<PowurProfileImgDtoInput>;
};

export type Query = {
  __typename?: "Query";
  contract: Contract;
  contractsForProposal: Array<Contract>;
  design: Design;
  designHasSunroof: Scalars["Boolean"];
  designRequest: DesignRequest;
  designerDataByRequestId: DesignRequest;
  designs: Array<Design>;
  equipmentForSite: Array<PricedEquipment>;
  /** Find equipment regions */
  equipmentRegions: Array<EquipmentRegion>;
  equipmentRegionsCount: Scalars["Float"];
  estimate: EstimateResult;
  estimatesForDesign: Array<EstimateResult>;
  /** finds all loan products */
  findAllLoanProducts: Array<LoanProduct>;
  findDisclosuresForLoanProduct: GetDisclosuresByLoanProductIdResponse;
  findLoanProductById: LoanProduct;
  findLoanProductsForProposal: Array<LoanProduct>;
  generate?: Maybe<Array<Maybe<Array<Maybe<Scalars["String"]>>>>>;
  getActiveLoanStatus: Scalars["String"];
  /** Get the brochure data for a proposal */
  getBrochure: Brochure;
  getCronJobActiveStatus: Scalars["Boolean"];
  getDevProposalData: DevCreateProposalDtoNew;
  getEnfinApplicationDetails: EnfinApplicationDetails;
  getEnfinFrame: Scalars["String"];
  getGoodLeapDisclosures: GoodLeapGetDisclosuresResponseDto;
  getGoodleapLoanDetails: GoodleapLoanDetails;
  getGoodleapStipulations: Array<GoodleapStipulationDetailed>;
  getInProgressDesign: Design;
  getIncentivesByProposalId: Array<ProposalIncentive>;
  getMockAddress: PowurAddressDto;
  /** finds all loan products */
  getOpenSolarToken: Scalars["String"];
  getProposalStatusEvents: Array<ProposalStatus>;
  getQueue: Array<DesignRequest>;
  getQueueCount: DesignRequestCount;
  getQuotesForProposal: Array<QuoteResult>;
  getShortLink?: Maybe<ShortLink>;
  getSungageApplicationDetails: SungageApplicationDetails;
  getSungageFrame: Scalars["String"];
  getSunlightFrame: Scalars["String"];
  getSunlightProjectStatus: SunlightGetStatusResponse;
  incentives: Array<Incentive>;
  incentivesCount: Scalars["Float"];
  mosaicGetApplication: MosaicGetApplicationResponseDto;
  mosaicGetUsers: MosaicGetUsersResponseDto;
  mosaicListDisclosures: MosaicGetDisclosuresResponseDto;
  profile: User;
  proposal: Proposal;
  proposalStatuses: Array<ProposalStatus>;
  proposals: Array<Proposal>;
  proposalsCount: Scalars["Float"];
  /** Find all powur os regions */
  regions: Array<Region>;
  regionsCount: Scalars["Float"];
  savingsForDesignId?: Maybe<SavingsResult>;
  /** Search equipment by name */
  searchEquipment: Array<Equipment>;
  /** performs case insensitive pattern matching search against provided fields */
  searchLoanProducts: Array<LoanProduct>;
  /** performs case insensitive pattern matching search against provided fields */
  searchUsers: Array<User>;
  serviceIntegration: ServiceIntegration;
  serviceIntegrations: Array<ServiceIntegration>;
  site: Site;
  sites: Array<Site>;
  /** finds user by id */
  user: User;
  /** finds all users */
  users: Array<User>;
  /** Search all utility companies */
  utilityCompanies: Array<UtilityCompany>;
  utilityCompaniesCount: Scalars["Float"];
  /** Find utility companies for a given site's zip code */
  utilityCompaniesForSite: Array<UtilityCompany>;
  /** Find all utility rates */
  utilityRates: Array<UtilityRate>;
  utilityRatesCount: Scalars["Float"];
  /** Find utility rates for a given site */
  utilityRatesForSite: Array<UtilityRate>;
  /** Find utility rates for a utility company */
  utilityTariffsForCompany: Array<UtilityRate>;
};

export type QueryContractArgs = {
  id: Scalars["String"];
};

export type QueryContractsForProposalArgs = {
  proposalId: Scalars["String"];
};

export type QueryDesignArgs = {
  id: Scalars["ID"];
};

export type QueryDesignHasSunroofArgs = {
  id: Scalars["ID"];
};

export type QueryDesignRequestArgs = {
  id: Scalars["ID"];
};

export type QueryDesignerDataByRequestIdArgs = {
  id: Scalars["ID"];
};

export type QueryDesignsArgs = {
  designsInput?: InputMaybe<DesignsInput>;
};

export type QueryEquipmentForSiteArgs = {
  proposalId: Scalars["ID"];
  siteId: Scalars["ID"];
};

export type QueryEquipmentRegionsArgs = {
  equipmentRegionsInput?: InputMaybe<EquipmentRegionsInput>;
};

export type QueryEquipmentRegionsCountArgs = {
  equipmentRegionsInput?: InputMaybe<EquipmentRegionsInput>;
};

export type QueryEstimateArgs = {
  findEstimateInput: FindEstimateInput;
};

export type QueryEstimatesForDesignArgs = {
  designId: Scalars["ID"];
};

export type QueryFindAllLoanProductsArgs = {
  loanProductsInput?: InputMaybe<LoanProductsInput>;
};

export type QueryFindDisclosuresForLoanProductArgs = {
  id: Scalars["ID"];
};

export type QueryFindLoanProductByIdArgs = {
  id: Scalars["ID"];
};

export type QueryFindLoanProductsForProposalArgs = {
  proposalId: Scalars["ID"];
};

export type QueryGenerateArgs = {
  input: ReportsInput;
};

export type QueryGetActiveLoanStatusArgs = {
  id: Scalars["String"];
};

export type QueryGetBrochureArgs = {
  proposalId: Scalars["ID"];
};

export type QueryGetCronJobActiveStatusArgs = {
  jobName: Scalars["String"];
};

export type QueryGetEnfinApplicationDetailsArgs = {
  providerId: Scalars["String"];
};

export type QueryGetEnfinFrameArgs = {
  proposalId: Scalars["String"];
};

export type QueryGetGoodleapLoanDetailsArgs = {
  providerId: Scalars["String"];
};

export type QueryGetGoodleapStipulationsArgs = {
  providerId: Scalars["String"];
};

export type QueryGetIncentivesByProposalIdArgs = {
  proposalId: Scalars["String"];
};

export type QueryGetMockAddressArgs = {
  input: GetMockAddressInput;
};

export type QueryGetOpenSolarTokenArgs = {
  input?: InputMaybe<OpenSolarGetTokenInput>;
};

export type QueryGetProposalStatusEventsArgs = {
  proposalId: Scalars["String"];
};

export type QueryGetQueueArgs = {
  queueInput: QueueInput;
};

export type QueryGetQuotesForProposalArgs = {
  id: Scalars["ID"];
};

export type QueryGetShortLinkArgs = {
  id?: InputMaybe<Scalars["String"]>;
  slug?: InputMaybe<Scalars["String"]>;
};

export type QueryGetSungageApplicationDetailsArgs = {
  proposalId: Scalars["String"];
};

export type QueryGetSungageFrameArgs = {
  proposalId: Scalars["String"];
};

export type QueryGetSunlightFrameArgs = {
  input: GetSunlightIframeUrlDto;
};

export type QueryGetSunlightProjectStatusArgs = {
  id: Scalars["String"];
};

export type QueryIncentivesArgs = {
  incentiveListInput?: InputMaybe<IncentiveInput>;
};

export type QueryIncentivesCountArgs = {
  incentiveListInput?: InputMaybe<IncentiveInput>;
};

export type QueryMosaicGetApplicationArgs = {
  input: Scalars["ID"];
};

export type QueryMosaicGetUsersArgs = {
  input: MosaicGetUsersBodyDto;
};

export type QueryMosaicListDisclosuresArgs = {
  input: MosaicGetDisclosuresBodyDto;
};

export type QueryProposalArgs = {
  findProposalInput: FindProposalInput;
};

export type QueryProposalsArgs = {
  proposalListInput?: InputMaybe<ProposalsInput>;
};

export type QueryProposalsCountArgs = {
  proposalListInput?: InputMaybe<ProposalsInput>;
};

export type QueryRegionsArgs = {
  regionsInput?: InputMaybe<RegionInput>;
};

export type QueryRegionsCountArgs = {
  regionInput?: InputMaybe<RegionInput>;
};

export type QuerySavingsForDesignIdArgs = {
  designId: Scalars["ID"];
};

export type QuerySearchEquipmentArgs = {
  name?: InputMaybe<Scalars["String"]>;
};

export type QuerySearchLoanProductsArgs = {
  searchTerms: SearchLoanProductInput;
};

export type QuerySearchUsersArgs = {
  searchTerms: SearchUserInput;
};

export type QueryServiceIntegrationArgs = {
  id: Scalars["ID"];
};

export type QueryServiceIntegrationsArgs = {
  serviceIntegrationsInput?: InputMaybe<ServiceIntegrationsInput>;
};

export type QuerySiteArgs = {
  id: Scalars["ID"];
};

export type QueryUserArgs = {
  id: Scalars["ID"];
};

export type QueryUsersArgs = {
  usersInput?: InputMaybe<UsersInput>;
};

export type QueryUtilityCompaniesArgs = {
  utilityCompanyInput?: InputMaybe<UtilityCompanyInput>;
};

export type QueryUtilityCompaniesCountArgs = {
  utilityCompanyInput?: InputMaybe<UtilityCompanyInput>;
};

export type QueryUtilityCompaniesForSiteArgs = {
  siteId: Scalars["ID"];
};

export type QueryUtilityRatesArgs = {
  utilityRateInput?: InputMaybe<UtilityRateInput>;
};

export type QueryUtilityRatesCountArgs = {
  utilityRateInput?: InputMaybe<UtilityRateInput>;
};

export type QueryUtilityRatesForSiteArgs = {
  siteId: Scalars["ID"];
};

export type QueryUtilityTariffsForCompanyArgs = {
  lseId: Scalars["String"];
  nameMatch?: InputMaybe<Scalars["String"]>;
};

export type QueueInput = {
  filter?: InputMaybe<DesignRequestFindManyWhereInput>;
  skip?: InputMaybe<Scalars["Float"]>;
  take: Scalars["Float"];
};

export type Quote = {
  __typename?: "Quote";
  alternateMonthlyPayment?: Maybe<Scalars["Float"]>;
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  finalEscalatedMonthlyPayment?: Maybe<Scalars["Float"]>;
  finalMonthlyPayment?: Maybe<Scalars["Float"]>;
  goodleapJson?: Maybe<GoodleapPaymentsResponse>;
  /** version 4(random) UUID */
  id: Scalars["ID"];
  loanAmount?: Maybe<Scalars["Float"]>;
  loanProductId: Scalars["String"];
  monthlyAdjustedPayment: Scalars["Float"];
  monthlyInitialPayment: Scalars["Float"];
  monthlyPayments?: Maybe<Array<Scalars["JSON"]>>;
  paydownAmount?: Maybe<Scalars["Float"]>;
  paydownPercentage?: Maybe<Scalars["Float"]>;
  paymentFactors?: Maybe<Scalars["JSON"]>;
  poisonId?: Maybe<Scalars["String"]>;
  proposalId: Scalars["String"];
  totalProjectCost?: Maybe<Scalars["Float"]>;
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
};

export type QuoteBodyResponse = {
  __typename?: "QuoteBodyResponse";
  apr: Scalars["Float"];
  createdDateTime: Scalars["String"];
  escalatedMonthlyPayment: Scalars["Float"];
  finalEscalatedMonthlyPayment: Scalars["Float"];
  finalMonthlyPayment: Scalars["Float"];
  id: Scalars["String"];
  includesBattery: Scalars["Boolean"];
  isACH: Scalars["Boolean"];
  isSyncing: Scalars["Boolean"];
  loanAmount: Scalars["Float"];
  monthlyPayment: Scalars["Float"];
  nonACHApr: Scalars["Float"];
  prePaymentPercentage: Scalars["Float"];
  productName: Scalars["String"];
  productType: Scalars["String"];
  quoteNumber: Scalars["String"];
  systemDesignId: Scalars["String"];
  term: Scalars["Float"];
};

export type QuoteResult = PoisonedQuote | Quote;

export type Region = {
  __typename?: "Region";
  /** vision managed activated date */
  activatedAt?: Maybe<Scalars["DateTime"]>;
  adderRegionAdders?: Maybe<Array<AdderRegionAdder>>;
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  getPostCodes: Array<Scalars["String"]>;
  /** version 4(random) UUID */
  id: Scalars["ID"];
  name: Scalars["String"];
  postalCodes?: Maybe<Scalars["String"]>;
  powurOsId: Scalars["Float"];
  region?: Maybe<Scalars["JSON"]>;
  regionCode?: Maybe<Scalars["String"]>;
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
};

export type RegionFindManyWhereInput = {
  search?: InputMaybe<Scalars["String"]>;
};

export type RegionInput = {
  filter?: InputMaybe<RegionFindManyWhereInput>;
  paginate?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<RegionOrderByInput>;
};

export type RegionOrderByInput = {
  regionCode?: InputMaybe<SortOrder>;
};

export type RegionalAdderPricing = {
  __typename?: "RegionalAdderPricing";
  cost: Scalars["Float"];
  createdAt: Scalars["DateTime"];
  end?: Maybe<Scalars["DateTime"]>;
  isRequired: Scalars["Boolean"];
  isVariable: Scalars["Boolean"];
  powurOsAdderId: Scalars["Float"];
  powurOsRegionId: Scalars["Float"];
  start: Scalars["DateTime"];
  updatedAt: Scalars["DateTime"];
};

export type ReportsInput = {
  endDate: Scalars["DateTime"];
  reportType: Scalars["Float"];
  startDate: Scalars["DateTime"];
};

/** Goodleap Spoken Language Type */
export enum Spoken_Language {
  English = "ENGLISH",
  Spanish = "SPANISH",
}

export type Savings = {
  __typename?: "Savings";
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  designId: Scalars["String"];
  /** version 4(random) UUID */
  id: Scalars["ID"];
  inputs: GenabilityAccountAnalysisInputs;
  outputs: GenabilityAccountAnalysis;
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
};

export type SavingsResult = PoisonedSavings | Savings;

export type SearchLoanProductInput = {
  /** string for the provider */
  provider?: InputMaybe<Scalars["String"]>;
};

export type SearchUserInput = {
  email?: InputMaybe<Scalars["String"]>;
  enterpriseId?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  lastName?: InputMaybe<Scalars["String"]>;
  permissions?: InputMaybe<Array<Permission>>;
  phone?: InputMaybe<Scalars["String"]>;
};

export type SendCreditApplicationInput = {
  goodLeapRequest?: InputMaybe<GoodLeapCreateLoanRequestDto>;
  provider: LoanProvider;
  selectedLoanProductId: Scalars["String"];
};

export type SendCreditApplicationResponse = GoodLeapCreateLoanResponseDto | MosaicPostApplicationResponseDto;

export type ServiceIntegration = {
  __typename?: "ServiceIntegration";
  apiKeys: Array<ApiKey>;
  contactEmail: Scalars["String"];
  contactName: Scalars["String"];
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  /** version 4(random) UUID */
  id: Scalars["ID"];
  name: Scalars["String"];
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
};

export type ServiceIntegrationFilterInput = {
  search?: InputMaybe<Scalars["String"]>;
};

export type ServiceIntegrationSortInput = {
  contactEmail?: InputMaybe<SortOrder>;
  contactName?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type ServiceIntegrationsInput = {
  filter?: InputMaybe<ServiceIntegrationFilterInput>;
  paginate?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<ServiceIntegrationSortInput>;
};

export type ShortLink = {
  __typename?: "ShortLink";
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  expiresAt: Scalars["DateTime"];
  /** version 4(random) UUID */
  id: Scalars["ID"];
  proposalId: Scalars["String"];
  slug: Scalars["String"];
  timesVisited: Scalars["Float"];
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
  url: Scalars["String"];
};

export type Site = {
  __typename?: "Site";
  /** Normalized address (123 S Main St. Anytown USA, 00000) */
  address: Scalars["String"];
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  /** Account ID from Genability */
  genabilityAccountId?: Maybe<Scalars["String"]>;
  /** version 4(random) UUID */
  id: Scalars["ID"];
  /** Imagery for this site */
  imagery: Array<ImageryResult>;
  /** Site Latitude (-90.0 - 90.0 */
  latitude: Scalars["Float"];
  /** Site locality */
  locality: Scalars["String"];
  /** Site Longitude (-180.0 - 180.0 */
  longitude: Scalars["Float"];
  /** Site zip code */
  postalCode: Scalars["String"];
  /** Site zip code suffix */
  postalCodeSuffix: Scalars["String"];
  /** Region ID */
  regionId: Scalars["String"];
  /** Site state */
  state: StateCode;
  /** street address */
  street: Scalars["String"];
  /** Street View Latitude (-90.0 - 90.0 */
  streetViewLatitude?: Maybe<Scalars["Float"]>;
  /** Street View Longitude (-180.0 - 180.0 */
  streetViewLongitude?: Maybe<Scalars["Float"]>;
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
};

export enum SortOrder {
  Asc = "asc",
  Desc = "desc",
}

/** Information about the SSC library. */
export type SscInfo = {
  __typename?: "SscInfo";
  /** Build Identifier */
  build: Scalars["String"];
  /** Module name */
  module: Scalars["String"];
  /** Version number */
  version: Scalars["Float"];
};

export enum StateCode {
  Ak = "AK",
  Al = "AL",
  Ar = "AR",
  Az = "AZ",
  Ca = "CA",
  Co = "CO",
  Ct = "CT",
  Dc = "DC",
  De = "DE",
  Fl = "FL",
  Ga = "GA",
  Hi = "HI",
  Ia = "IA",
  Id = "ID",
  Il = "IL",
  In = "IN",
  Ks = "KS",
  Ky = "KY",
  La = "LA",
  Ma = "MA",
  Md = "MD",
  Me = "ME",
  Mi = "MI",
  Mn = "MN",
  Mo = "MO",
  Ms = "MS",
  Mt = "MT",
  Nc = "NC",
  Nd = "ND",
  Ne = "NE",
  Nh = "NH",
  Nj = "NJ",
  Nm = "NM",
  Nv = "NV",
  Ny = "NY",
  Oh = "OH",
  Ok = "OK",
  Or = "OR",
  Pa = "PA",
  Pr = "PR",
  Ri = "RI",
  Sc = "SC",
  Sd = "SD",
  Tn = "TN",
  Tx = "TX",
  Ut = "UT",
  Va = "VA",
  Vt = "VT",
  Wa = "WA",
  Wi = "WI",
  Wv = "WV",
  Wy = "WY",
}

/** Information about the climate data used in the simulation. */
export type StationInfo = {
  __typename?: "StationInfo";
  /** City where climate station is located. */
  city: Scalars["String"];
  /** Distance between the input location and the climate station. (meters) */
  distance?: Maybe<Scalars["Float"]>;
  /** Elevation of the climate station. (meters) */
  elev: Scalars["Float"];
  /** Latitude of the climate station. */
  lat: Scalars["Float"];
  /** ID of the climate station. */
  location: Scalars["String"];
  /** Longitude of the climate station. */
  lon: Scalars["Float"];
  /** Solar resource filename. */
  solar_resource_file: Scalars["String"];
  /** State where climate station is located. */
  state: Scalars["String"];
  /** Timezone offset from GMT. */
  tz: Scalars["Float"];
  /** Weather data source. */
  weather_data_source: Scalars["String"];
};

export type StipsBodyResponse = {
  __typename?: "StipsBodyResponse";
  description: Scalars["String"];
  id: Scalars["String"];
  installerOnlyEmail: Scalars["Boolean"];
  isStipSuppressed: Scalars["Boolean"];
  name: Scalars["String"];
  status: Scalars["String"];
};

export type Stipulation = {
  __typename?: "Stipulation";
  appliesTo?: Maybe<Scalars["String"]>;
  attachments: Array<Attachment>;
  createdAt?: Maybe<Scalars["String"]>;
  deferred?: Maybe<Scalars["Boolean"]>;
  description?: Maybe<Scalars["String"]>;
  documentsToClear: Array<Document>;
  domain: Scalars["String"];
  id: Scalars["String"];
  name: Scalars["String"];
  reasonCodes: Array<Scalars["String"]>;
  status: Scalars["String"];
  type: Scalars["String"];
  updatedAt?: Maybe<Scalars["String"]>;
  updatedBy?: Maybe<Scalars["String"]>;
};

export type SubjectProperty = {
  __typename?: "SubjectProperty";
  address: Address;
  mortgageTotalBalances?: Maybe<Currency>;
  mortgageTotalPayments?: Maybe<Currency>;
};

export type SubjectPropertyInput = {
  address: AddressInput;
  mortgageTotalBalances?: InputMaybe<CurrencyInput>;
  mortgageTotalPayments?: InputMaybe<CurrencyInput>;
};

export type SubmittingUser = {
  email: Scalars["String"];
  firstName: Scalars["String"];
  lastName: Scalars["String"];
  phone?: InputMaybe<Scalars["String"]>;
};

export type SungageApplicationDetails = {
  __typename?: "SungageApplicationDetails";
  applicationDate: Scalars["String"];
  approvalAmount: Scalars["Float"];
  approvedTerm: Scalars["String"];
  creditDeadline: Scalars["String"];
  key: Scalars["String"];
  roofAndBattery: Scalars["Boolean"];
  status: Scalars["String"];
  stipulations?: Maybe<Array<Scalars["String"]>>;
  url: Scalars["String"];
};

export type SunlightDisclosure = {
  __typename?: "SunlightDisclosure";
  disclosureText: Scalars["String"];
  isHome: Scalars["Boolean"];
  isSolar: Scalars["Boolean"];
  spanishDisclosureText: Scalars["String"];
  type: Scalars["String"];
};

export type SunlightGetDisclosuresResponse = {
  __typename?: "SunlightGetDisclosuresResponse";
  projects: Array<SunlightProjectsArray>;
  returnCode: Scalars["String"];
};

export type SunlightGetStatusResponse = {
  __typename?: "SunlightGetStatusResponse";
  projects: Array<ProjectBodyResponse>;
  returnCode: Scalars["String"];
};

export type SunlightProjectsArray = {
  __typename?: "SunlightProjectsArray";
  disclosures: Array<SunlightDisclosure>;
};

export type SyncLoanOfferResponse = {
  __typename?: "SyncLoanOfferResponse";
  created: Scalars["Float"];
  fetched: Scalars["Float"];
  unchanged: Scalars["Float"];
  updated: Scalars["Float"];
};

export type SystemDesignBodyResponse = {
  __typename?: "SystemDesignBodyResponse";
  estAnnualProductionkwh: Scalars["Float"];
  id: Scalars["String"];
  inverterCount: Scalars["Float"];
  inverterMake: Scalars["String"];
  inverterMakeName: Scalars["String"];
  inverterModel: Scalars["String"];
  moduleCount: Scalars["Float"];
  moduleMake: Scalars["String"];
  moduleMakeName: Scalars["String"];
  moduleModel: Scalars["String"];
  projectType: Scalars["String"];
  systemSize: Scalars["Float"];
};

export enum SystemType {
  Both = "both",
  GroundMount = "ground_mount",
  RoofMount = "roof_mount",
}

export type TaxLineItem = {
  discount: Scalars["Float"];
  id: Scalars["String"];
  product_tax_code: Scalars["String"];
  quantity: Scalars["Float"];
  unit_price: Scalars["Float"];
};

export type TaxStatus = {
  __typename?: "TaxStatus";
  id?: Maybe<Scalars["Float"]>;
  name?: Maybe<Scalars["String"]>;
};

export type TaxjarJurisdictions = {
  __typename?: "TaxjarJurisdictions";
  city: Scalars["String"];
  country: Scalars["String"];
  county: Scalars["String"];
  state: Scalars["String"];
};

export type TaxjarTaxField = {
  __typename?: "TaxjarTaxField";
  amount_to_collect: Scalars["Float"];
  breakdown: Breakdown;
  exemption_type: Scalars["String"];
  freight_taxable: Scalars["Boolean"];
  has_nexus: Scalars["Boolean"];
  jurisdictions: TaxjarJurisdictions;
  order_total_amount: Scalars["Float"];
  rate: Scalars["Float"];
  shipping: Scalars["Float"];
  tax_source: Scalars["String"];
  taxable_amount: Scalars["Float"];
};

export type TaxjarTaxForOrderParams = {
  amount?: InputMaybe<Scalars["Float"]>;
  customer_id?: InputMaybe<Scalars["String"]>;
  exemption_type?: InputMaybe<Scalars["String"]>;
  from_city?: InputMaybe<Scalars["String"]>;
  from_country?: InputMaybe<Scalars["String"]>;
  from_state?: InputMaybe<Scalars["String"]>;
  from_street?: InputMaybe<Scalars["String"]>;
  from_zip?: InputMaybe<Scalars["String"]>;
  line_items?: InputMaybe<Array<TaxLineItem>>;
  nexus_addresses?: InputMaybe<Array<NexusAddress>>;
  shipping: Scalars["Float"];
  to_city?: InputMaybe<Scalars["String"]>;
  to_country?: InputMaybe<Scalars["String"]>;
  to_state?: InputMaybe<Scalars["String"]>;
  to_street?: InputMaybe<Scalars["String"]>;
  to_zip?: InputMaybe<Scalars["String"]>;
};

export type TaxjarTaxForOrderResponse = {
  __typename?: "TaxjarTaxForOrderResponse";
  tax: TaxjarTaxField;
};

export type UpdateDesignInput = {
  activatedAt?: InputMaybe<Scalars["DateTime"]>;
  batteryCompatibility?: InputMaybe<Scalars["Boolean"]>;
  brochurePositionX?: InputMaybe<Scalars["Float"]>;
  brochurePositionY?: InputMaybe<Scalars["Float"]>;
  brochureZoom?: InputMaybe<Scalars["Float"]>;
  completedAt?: InputMaybe<Scalars["DateTime"]>;
  displayImageryProvider?: InputMaybe<Scalars["String"]>;
  edges?: InputMaybe<Scalars["JSON"]>;
  energyBackupId?: InputMaybe<Scalars["String"]>;
  energyEfficiencyKitRebate?: InputMaybe<Scalars["Boolean"]>;
  energyEfficiencyKitRebateAmount?: InputMaybe<Scalars["Float"]>;
  evCharger?: InputMaybe<Scalars["Boolean"]>;
  evOutlet?: InputMaybe<Scalars["Boolean"]>;
  homeownerRebate?: InputMaybe<Scalars["Boolean"]>;
  homeownerRebateAmount?: InputMaybe<Scalars["Float"]>;
  id: Scalars["ID"];
  installAreas?: InputMaybe<Scalars["JSON"]>;
  installAreasToPanels?: InputMaybe<Scalars["JSON"]>;
  inverterId?: InputMaybe<Scalars["String"]>;
  inverterManufacturer?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  nodes?: InputMaybe<Scalars["JSON"]>;
  obstructions?: InputMaybe<Scalars["JSON"]>;
  panels?: InputMaybe<Scalars["JSON"]>;
  phase?: InputMaybe<Scalars["String"]>;
  powurCareThirtyYearWarranty?: InputMaybe<Scalars["Boolean"]>;
  roofWorkRebate?: InputMaybe<Scalars["Boolean"]>;
  roofWorkRebateAmount?: InputMaybe<Scalars["Float"]>;
  secondaryInverterId?: InputMaybe<Scalars["String"]>;
  /** Selected additional equipment ids */
  selectedDesignEquipmentIds?: InputMaybe<Array<Scalars["String"]>>;
  setbacks?: InputMaybe<Scalars["JSON"]>;
  shadingAreas?: InputMaybe<Scalars["JSON"]>;
  solarEdgeTwentyFiveYearWarranty?: InputMaybe<Scalars["Boolean"]>;
  solarEdgeTwentyFiveYearWarrantyPrice?: InputMaybe<Scalars["Float"]>;
  solarEdgeTwentyYearWarranty?: InputMaybe<Scalars["Boolean"]>;
  solarEdgeTwentyYearWarrantyPrice?: InputMaybe<Scalars["Float"]>;
  solarModuleId?: InputMaybe<Scalars["String"]>;
  tool?: InputMaybe<Scalars["String"]>;
  treeTrimmingRebate?: InputMaybe<Scalars["Boolean"]>;
  treeTrimmingRebateAmount?: InputMaybe<Scalars["Float"]>;
  type?: InputMaybe<Scalars["String"]>;
};

export type UpdateEquipmentRegionInput = {
  powurOsEquipmentStatusId?: InputMaybe<Scalars["Float"]>;
  powurOsTaxStatusId?: InputMaybe<Scalars["Float"]>;
};

export type UpdateImageryPositionInput = {
  positionedX?: InputMaybe<Scalars["Float"]>;
  positionedY?: InputMaybe<Scalars["Float"]>;
  positionedZoom?: InputMaybe<Scalars["Float"]>;
};

export type UpdateIncentiveInput = {
  activatedAt?: InputMaybe<Scalars["DateTime"]>;
  canApplyToLoan: Scalars["Boolean"];
  customDisplayName?: InputMaybe<Scalars["String"]>;
  endDate?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
  isPaidAsLumpSum: Scalars["Boolean"];
  isSungageRebate: Scalars["Boolean"];
  turnedOnByDefaultAt?: InputMaybe<Scalars["DateTime"]>;
};

export type UpdateLoanProductInput = {
  activatedAt?: InputMaybe<Scalars["DateTime"]>;
  id: Scalars["ID"];
  maximumLoanAmount?: InputMaybe<Scalars["Float"]>;
  minimumCreditScore?: InputMaybe<Scalars["Float"]>;
  minimumLoanAmount?: InputMaybe<Scalars["Float"]>;
  powurOsLoanId?: InputMaybe<Scalars["Float"]>;
  providerFee?: InputMaybe<Scalars["Float"]>;
  providerName?: InputMaybe<Scalars["String"]>;
  statesActive?: InputMaybe<Array<Scalars["String"]>>;
};

export type UpdateProposalAdderInput = {
  customPrice?: InputMaybe<Scalars["Float"]>;
  customQuantity?: InputMaybe<Scalars["Float"]>;
  powurOsAdderId: Scalars["Float"];
  proposalId: Scalars["String"];
  selected?: InputMaybe<Scalars["Boolean"]>;
};

export type UpdateProposalIncentivesInput = {
  applyToLoan: Scalars["Boolean"];
  incentiveId: Scalars["String"];
  proposalId: Scalars["String"];
  showOnBrochure: Scalars["Boolean"];
};

export type UpdateProposalInput = {
  annualEnergyUse?: InputMaybe<Scalars["Float"]>;
  displayAddressUpdates?: InputMaybe<DisplayAddressInput>;
  /** Down Payment */
  downPayment?: InputMaybe<Scalars["Float"]>;
  id: Scalars["ID"];
  incentiveStatuses?: InputMaybe<Array<UpdateProposalIncentivesInput>>;
  /** Margin (priced in $ per watt) */
  margin?: InputMaybe<Scalars["Float"]>;
  /** Selected mentor ID */
  mentorId?: InputMaybe<Scalars["String"]>;
  monthlyEnergyCost?: InputMaybe<Array<Scalars["Float"]>>;
  monthlyEnergyUse?: InputMaybe<Array<Scalars["Float"]>>;
  /** Proposal name */
  name?: InputMaybe<Scalars["String"]>;
  newCustomerIncentive?: InputMaybe<Scalars["Boolean"]>;
  newCustomerIncentiveMonths?: InputMaybe<Scalars["Float"]>;
  /** Post-solar-install utility rate id */
  postSolarInstallUtilityRateId?: InputMaybe<Scalars["String"]>;
  preferredLanguage?: InputMaybe<Scalars["String"]>;
  /** primary presenter powurUserId */
  primaryPresenter?: InputMaybe<Scalars["Float"]>;
  /** Proposal Adder */
  proposalAdders?: InputMaybe<Array<UpdateProposalAdderInput>>;
  salesTax?: InputMaybe<Scalars["Float"]>;
  salesTaxRate?: InputMaybe<Scalars["Float"]>;
  /** secondary presenter powurUserId */
  secondaryPresenter?: InputMaybe<Scalars["Float"]>;
  /** Selected loan product Ids */
  selectedLoanProductIds?: InputMaybe<Array<Scalars["String"]>>;
  /** Consultant powur id */
  sellerId?: InputMaybe<Scalars["ID"]>;
  /** system type (roof mount, ground mount, or both) */
  systemType?: InputMaybe<SystemType>;
  userAdjustedAfterSolarContractedRate?: InputMaybe<Scalars["Float"]>;
  userAdjustedBeforeSolarContractedRate?: InputMaybe<Scalars["Float"]>;
  /** user has overridden the average utility rate */
  userAdjustedUtilityRate?: InputMaybe<Scalars["Float"]>;
  /** Utility rate escalation percentage */
  utilityRateEscalationPercentage?: InputMaybe<Scalars["Float"]>;
  /** Utility rate id */
  utilityRateId?: InputMaybe<Scalars["String"]>;
};

export type UpdateServiceIntegrationInput = {
  contactEmail?: InputMaybe<Scalars["String"]>;
  contactName?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  name?: InputMaybe<Scalars["String"]>;
};

export type UpdateSiteInput = {
  /** Address of the site */
  address?: InputMaybe<Scalars["String"]>;
  id: Scalars["ID"];
  /** Latitude of the site */
  latitude?: InputMaybe<Scalars["Float"]>;
  /** Longitude of the site */
  longitude?: InputMaybe<Scalars["Float"]>;
  /** street view latitude of the site */
  streetViewLatitude?: InputMaybe<Scalars["Float"]>;
  /** Street view longitude of the site */
  streetViewLongitude?: InputMaybe<Scalars["Float"]>;
  /** zoom level of the mapping service */
  zoom?: InputMaybe<Scalars["Float"]>;
};

export type UpdateUserInput = {
  email?: InputMaybe<Scalars["String"]>;
  enterpriseId?: InputMaybe<Scalars["String"]>;
  firstName?: InputMaybe<Scalars["String"]>;
  id: Scalars["String"];
  lastName?: InputMaybe<Scalars["String"]>;
  permissions?: InputMaybe<Array<Permission>>;
  phone?: InputMaybe<Scalars["String"]>;
};

export type User = {
  __typename?: "User";
  /** user bio */
  bio?: Maybe<Scalars["String"]>;
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  /** user email */
  email: Scalars["String"];
  /** user enterprise id */
  enterpriseId?: Maybe<Scalars["String"]>;
  /** user first name */
  firstName: Scalars["String"];
  /** version 4(random) UUID */
  id: Scalars["ID"];
  /** user last name */
  lastName: Scalars["String"];
  /** user permission for determining application permissions */
  permissions: Array<Permission>;
  /** user phone number */
  phone?: Maybe<Scalars["String"]>;
  /** powur.com user id */
  powurUserId?: Maybe<Scalars["Float"]>;
  /** user profile image url */
  profileImage?: Maybe<ProfileImg>;
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
};

export type UserFindManyWhereInput = {
  permission?: InputMaybe<Permission>;
  search?: InputMaybe<Scalars["String"]>;
};

export type UsersInput = {
  filter?: InputMaybe<UserFindManyWhereInput>;
  paginate?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<UsersOrderByInput>;
};

export type UsersOrderByInput = {
  email?: InputMaybe<SortOrder>;
  firstName?: InputMaybe<SortOrder>;
  lastName?: InputMaybe<SortOrder>;
};

export type UtilityCompany = {
  __typename?: "UtilityCompany";
  /** Activated date */
  activatedAt?: Maybe<Scalars["DateTime"]>;
  /** Details about how the LSE represents billing periods. See https://developer.genability.com/api-reference/tariff-api/load-serving-entity/ for more details */
  billingPeriodRepresentation?: Maybe<Scalars["JSON"]>;
  /** Short code (an alternate key). For US companies this is the EIA ID */
  code?: Maybe<Scalars["String"]>;
  /** Commercial customer count */
  commercialCustomers?: Maybe<Scalars["Float"]>;
  /** Annual commercial revenue in thousands of local currency (e.g. USD) */
  commercialRevenues?: Maybe<Scalars["Float"]>;
  /** Annual commercial sales in MWh */
  commercialSales?: Maybe<Scalars["Float"]>;
  /** Service types offered to commercial customers. Current values include "ELECTRICITY" and "SOLAR_PV". Blank means not offered */
  commercialServiceTypes?: Maybe<Scalars["String"]>;
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  /** version 4(random) UUID */
  id: Scalars["ID"];
  /** 	Industrial customer count */
  industrialCustomers?: Maybe<Scalars["Float"]>;
  /** Annual industrial revenue in thousands of local currency (e.g. USD) */
  industrialRevenues?: Maybe<Scalars["Float"]>;
  /** Annual industrial sales in MWh */
  industrialSales?: Maybe<Scalars["Float"]>;
  /** Service types offered to industrial customers. Current values include "ELECTRICITY" and "SOLAR_PV". Blank means not offered */
  industrialServiceTypes?: Maybe<Scalars["String"]>;
  /** Shorthand code for the company (PGE for Pacific Gas & Electric) */
  lseCode?: Maybe<Scalars["String"]>;
  /** Unique Genability ID (primary key) for each LSE */
  lseId: Scalars["Float"];
  /** Published name of the company */
  name: Scalars["String"];
  /** Utility classification based on the type of service they offer */
  offeringType: Scalars["String"];
  /** Ownership structure */
  ownership: Scalars["String"];
  ptcTduId?: Maybe<Scalars["String"]>;
  /** Residential customer count */
  residentialCustomers?: Maybe<Scalars["Float"]>;
  /** Annual residential revenue in thousands of local currency (e.g. USD) */
  residentialRevenues?: Maybe<Scalars["Float"]>;
  /** Annual residential sales in MWh */
  residentialSales?: Maybe<Scalars["Float"]>;
  /** Service types offered to residential customers. Current values include "ELECTRICITY" and "SOLAR_PV". Blank means not offered */
  residentialServiceTypes?: Maybe<Scalars["String"]>;
  /** Service types offered to any customer */
  serviceTypes?: Maybe<Scalars["String"]>;
  /** Total customer count */
  totalCustomers?: Maybe<Scalars["Float"]>;
  /** Annual total revenue in thousands of local currency (e.g. USD) */
  totalRevenues?: Maybe<Scalars["Float"]>;
  /** Annual total sales in MWh */
  totalSales?: Maybe<Scalars["Float"]>;
  /** Transportation customer count */
  transportationCustomers?: Maybe<Scalars["Float"]>;
  /** Annual transportation revenue in thousands of local currency (e.g. USD) */
  transportationRevenues?: Maybe<Scalars["Float"]>;
  /** Annual transportation sales in MWh */
  transportationSales?: Maybe<Scalars["Float"]>;
  /** Service types offered to transportation customers (such as municipal bus services, regional mass transit etc). Current values include "ELECTRICITY" and "SOLAR_PV". Blank means not offered */
  transportationServiceTypes?: Maybe<Scalars["String"]>;
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
  /** Utility Rates associated with this Utility Company */
  utilityRates?: Maybe<Array<UtilityRate>>;
  /** The URL to the home page of the LSE website */
  websiteHome?: Maybe<Scalars["String"]>;
};

export type UtilityCompanyFindManyWhereInput = {
  search?: InputMaybe<Scalars["String"]>;
};

export type UtilityCompanyInput = {
  filter?: InputMaybe<UtilityCompanyFindManyWhereInput>;
  paginate?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<UtilityCompanyOrderByInput>;
};

export type UtilityCompanyOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
};

export type UtilityRate = {
  __typename?: "UtilityRate";
  /** vision managed activated date */
  activatedAt?: Maybe<Scalars["DateTime"]>;
  /** Genability billing period */
  billingPeriod: Scalars["String"];
  chargePeriod?: Maybe<Scalars["String"]>;
  chargeTypes?: Maybe<Scalars["String"]>;
  closedDate?: Maybe<Scalars["String"]>;
  /** resource creation date */
  createdAt: Scalars["DateTime"];
  /** Genability currency */
  currency: Scalars["String"];
  /** Genability customer class */
  customerClass?: Maybe<Scalars["String"]>;
  customerCount?: Maybe<Scalars["Float"]>;
  customerCountSource?: Maybe<Scalars["String"]>;
  customerLikelihood?: Maybe<Scalars["Float"]>;
  /** Date on which the tariff was or will be effective */
  effectiveDate?: Maybe<Scalars["String"]>;
  effectiveOnRule?: Maybe<Scalars["String"]>;
  /** Genability end date */
  endDate?: Maybe<Scalars["String"]>;
  hasContractedRates?: Maybe<Scalars["Boolean"]>;
  hasNetMetering?: Maybe<Scalars["Boolean"]>;
  hasRateApplicability?: Maybe<Scalars["Boolean"]>;
  hasTariffApplicability?: Maybe<Scalars["Boolean"]>;
  hasTieredRates?: Maybe<Scalars["Boolean"]>;
  hasTimeOfUseRates?: Maybe<Scalars["Boolean"]>;
  /** version 4(random) UUID */
  id: Scalars["ID"];
  /** active status per genability */
  isActive: Scalars["Boolean"];
  isNEM3: Scalars["Boolean"];
  isSolarPvEligible: Scalars["Boolean"];
  lseCode?: Maybe<Scalars["String"]>;
  /** Genability lse (Utility Company) id */
  lseId: Scalars["Float"];
  /** Genability lse (Utility Company) name */
  lseName: Scalars["String"];
  /** Genability master tariff (Rate) id */
  masterTariffId: Scalars["Float"];
  maxMonthlyConsumption?: Maybe<Scalars["Float"]>;
  maxMonthlyDemand?: Maybe<Scalars["Float"]>;
  minMonthlyConsumption?: Maybe<Scalars["Float"]>;
  minMonthlyDemand?: Maybe<Scalars["Float"]>;
  priorTariffId?: Maybe<Scalars["Float"]>;
  privacy?: Maybe<Scalars["String"]>;
  serviceType?: Maybe<Scalars["String"]>;
  tariffBookName?: Maybe<Scalars["String"]>;
  /** Genability tariff (Rate) code */
  tariffCode: Scalars["String"];
  /** Genability tariff Id */
  tariffId: Scalars["Float"];
  /** Genability tariff (Rate) name */
  tariffName: Scalars["String"];
  tariffType?: Maybe<Scalars["String"]>;
  territoryId?: Maybe<Scalars["Float"]>;
  /** Genability time zone */
  timeZone: Scalars["String"];
  /** resource last updated update date */
  updatedAt: Scalars["DateTime"];
  utilityCompany?: Maybe<UtilityCompany>;
  /** ID of utility company */
  utilityCompanyId?: Maybe<Scalars["String"]>;
  utilityRateZipCodeLikelihoods?: Maybe<Array<UtilityRateZipCodeLikelihood>>;
};

export type UtilityRateFindManyWhereInput = {
  search?: InputMaybe<Scalars["String"]>;
};

export type UtilityRateInput = {
  filter?: InputMaybe<UtilityRateFindManyWhereInput>;
  paginate?: InputMaybe<PaginationInput>;
  sort?: InputMaybe<UtilityRateOrderByInput>;
};

export type UtilityRateOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
};

export type UtilityRateZipCodeLikelihood = {
  __typename?: "UtilityRateZipCodeLikelihood";
  createdAt: Scalars["DateTime"];
  likelihood?: Maybe<Scalars["Float"]>;
  rateTariffId: Scalars["Float"];
  updatedAt: Scalars["DateTime"];
  zipcode: Scalars["String"];
};

export type RequestRevisionMutationVariables = Exact<{
  input: DesignRevisionInput;
}>;

export type RequestRevisionMutation = {
  __typename?: "Mutation";
  requestDesignRevision: {
    __typename?: "Design";
    id: string;
    createdAt: any;
    activatedAt?: any | null;
    completedAt?: any | null;
    designRequest?: {
      __typename?: "DesignRequest";
      id: string;
      requestType?: string | null;
      createdAt: any;
      promisedAt?: any | null;
      timeAdded?: number | null;
      designUserId?: string | null;
    } | null;
  };
};

export type GetQueueQueryVariables = Exact<{
  queueInput: QueueInput;
}>;

export type GetQueueQuery = {
  __typename?: "Query";
  getQueue: Array<{
    __typename?: "DesignRequest";
    id: string;
    priority: number;
    createdAt: any;
    promisedAt?: any | null;
    completedAt?: any | null;
    abandonedAt?: any | null;
    escalatedAt?: any | null;
    design?: {
      __typename?: "Design";
      id: string;
      proposal?: {
        __typename?: "Proposal";
        id: string;
        powurProjectId?: number | null;
        slug: string;
        homeowner: { __typename?: "User"; id: string; firstName: string; lastName: string };
        seller: { __typename?: "User"; id: string; firstName: string; lastName: string };
        mentor?: { __typename?: "User"; id: string; firstName: string; lastName: string } | null;
        site: { __typename?: "Site"; id: string; address: string; state: StateCode; postalCode: string };
      } | null;
    } | null;
    designUser?: {
      __typename?: "DesignUser";
      user: { __typename?: "User"; firstName: string; lastName: string };
    } | null;
  }>;
};

export type ClaimDesignMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ClaimDesignMutation = {
  __typename?: "Mutation";
  claimDesignRequest: { __typename?: "DesignRequest"; id: string };
};

export type ClaimNextDesignMutationVariables = Exact<{ [key: string]: never }>;

export type ClaimNextDesignMutation = {
  __typename?: "Mutation";
  claimNextDesignRequest: { __typename?: "DesignRequest"; id: string };
};

export type UpdatePriorityMutationVariables = Exact<{
  id: Scalars["ID"];
  priority: Scalars["Float"];
}>;

export type UpdatePriorityMutation = {
  __typename?: "Mutation";
  prioritizeDesignRequest: { __typename?: "DesignRequest"; id: string };
};

export type QueueCountQueryVariables = Exact<{ [key: string]: never }>;

export type QueueCountQuery = {
  __typename?: "Query";
  getQueueCount: {
    __typename?: "DesignRequestCount";
    total: number;
    inProgress: number;
    completed: number;
    requested: number;
    abandoned: number;
    escalated: number;
  };
};

export const RequestRevisionDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "RequestRevision" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "input" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "DesignRevisionInput" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "requestDesignRevision" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: { kind: "Variable", name: { kind: "Name", value: "input" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "activatedAt" } },
                { kind: "Field", name: { kind: "Name", value: "completedAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "designRequest" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "requestType" } },
                      { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                      { kind: "Field", name: { kind: "Name", value: "promisedAt" } },
                      { kind: "Field", name: { kind: "Name", value: "timeAdded" } },
                      { kind: "Field", name: { kind: "Name", value: "designUserId" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<RequestRevisionMutation, RequestRevisionMutationVariables>;
export const GetQueueDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "GetQueue" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "queueInput" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "QueueInput" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getQueue" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "queueInput" },
                value: { kind: "Variable", name: { kind: "Name", value: "queueInput" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "priority" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                { kind: "Field", name: { kind: "Name", value: "promisedAt" } },
                { kind: "Field", name: { kind: "Name", value: "completedAt" } },
                { kind: "Field", name: { kind: "Name", value: "abandonedAt" } },
                { kind: "Field", name: { kind: "Name", value: "escalatedAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "design" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "proposal" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "id" } },
                            { kind: "Field", name: { kind: "Name", value: "powurProjectId" } },
                            { kind: "Field", name: { kind: "Name", value: "slug" } },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "homeowner" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "firstName" } },
                                  { kind: "Field", name: { kind: "Name", value: "lastName" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "seller" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "firstName" } },
                                  { kind: "Field", name: { kind: "Name", value: "lastName" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mentor" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "firstName" } },
                                  { kind: "Field", name: { kind: "Name", value: "lastName" } },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "site" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  { kind: "Field", name: { kind: "Name", value: "id" } },
                                  { kind: "Field", name: { kind: "Name", value: "address" } },
                                  { kind: "Field", name: { kind: "Name", value: "state" } },
                                  { kind: "Field", name: { kind: "Name", value: "postalCode" } },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "designUser" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            { kind: "Field", name: { kind: "Name", value: "firstName" } },
                            { kind: "Field", name: { kind: "Name", value: "lastName" } },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GetQueueQuery, GetQueueQueryVariables>;
export const ClaimDesignDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ClaimDesign" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "claimDesignRequest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClaimDesignMutation, ClaimDesignMutationVariables>;
export const ClaimNextDesignDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "ClaimNextDesign" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "claimNextDesignRequest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<ClaimNextDesignMutation, ClaimNextDesignMutationVariables>;
export const UpdatePriorityDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "UpdatePriority" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "ID" } } },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "priority" } },
          type: { kind: "NonNullType", type: { kind: "NamedType", name: { kind: "Name", value: "Float" } } },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "prioritizeDesignRequest" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: { kind: "Variable", name: { kind: "Name", value: "id" } },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "priority" },
                value: { kind: "Variable", name: { kind: "Name", value: "priority" } },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [{ kind: "Field", name: { kind: "Name", value: "id" } }],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<UpdatePriorityMutation, UpdatePriorityMutationVariables>;
export const QueueCountDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "QueueCount" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "getQueueCount" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "total" } },
                { kind: "Field", name: { kind: "Name", value: "inProgress" } },
                { kind: "Field", name: { kind: "Name", value: "completed" } },
                { kind: "Field", name: { kind: "Name", value: "requested" } },
                { kind: "Field", name: { kind: "Name", value: "abandoned" } },
                { kind: "Field", name: { kind: "Name", value: "escalated" } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<QueueCountQuery, QueueCountQueryVariables>;
