import z from "zod";

export const VisionProposalSchema = z.object({
  id: z.string(),
  site: z.object({
    id: z.string(),
    state: z.string(),
    street: z.string(),
    address: z.string(),
    latitude: z.number(),
    locality: z.string(),
    createdAt: z.string(),
    deletedAt: z.nullable(z.string()),
    longitude: z.number(),
    updatedAt: z.string(),
    postalCode: z.string(),
    postalCodeSuffix: z.string(),
  }),
  slug: z.string(),
  seller: z.object({
    id: z.string(),
    bio: z.string(),
    email: z.string(),
    phone: z.string(),
    lastName: z.string(),
    createdAt: z.string(),
    deletedAt: z.nullable(z.string()),
    firstName: z.string(),
    updatedAt: z.string(),
    powurUserId: z.number(),
  }),
  designs: z.array(
    z.object({
      id: z.string(),
      createdAt: z.string(),
      deletedAt: z.string().nullable(),
      updatedAt: z.string(),
      activatedAt: z.string().nullable(),
      completedAt: z.string(),
      requestType: z.unknown(),
      designUserId: z.number(),
      designRequest: z.object({
        id: z.string(),
        priority: z.number(),
        createdAt: z.string(),
        deletedAt: z.string().nullable(),
        timeAdded: z.number(),
        updatedAt: z.string(),
        deletedWhy: z.unknown(),
        designUser: z.object({
          id: z.string(),
          user: z.object({
            id: z.string(),
            bio: z.string(),
            email: z.string(),
            phone: z.string(),
            lastName: z.string(),
            createdAt: z.string(),
            deletedAt: z.string(),
            firstName: z.string(),
            updatedAt: z.string(),
            powurUserId: z.number(),
          }),
          createdAt: z.string(),
          deletedAt: z.string(),
          startedAt: z.string(),
          updatedAt: z.string(),
          completedAt: z.string(),
        }),
        promisedAt: z.string(),
        rejectedAt: z.string(),
        abandonedAt: z.string(),
        completedAt: z.string(),
        designNotes: z.array(
          z.object({
            id: z.string(),
            reason: z.string(),
            message: z.string(),
            createdAt: z.string(),
            createdBy: z.object({
              id: z.string(),
              bio: z.string(),
              email: z.string(),
              phone: z.string(),
              lastName: z.string(),
              createdAt: z.string(),
              deletedAt: z.string(),
              firstName: z.string(),
              updatedAt: z.string(),
              powurUserId: z.number(),
            }),
            deletedAt: z.string(),
            updatedAt: z.string(),
          }),
        ),
        escalatedAt: z.string(),
        pausedUntil: z.string(),
        claimedUntil: z.string(),
      }),
      designRequestId: z.number(),
    }),
  ),
  sellerId: z.number(),
  createdAt: z.string(),
  deletedAt: z.string(),
  homeowner: z.object({
    id: z.string(),
    bio: z.string(),
    email: z.string(),
    phone: z.string(),
    lastName: z.string(),
    createdAt: z.string(),
    deletedAt: z.string(),
    firstName: z.string(),
    updatedAt: z.string(),
    powurUserId: z.number(),
  }),
  updatedAt: z.string(),
  homeownerId: z.number(),
  proposalStatuses: z.array(
    z.object({
      id: z.string(),
      status: z.string(),
      message: z.string(),
      createdAt: z.string(),
      deletedAt: z.string(),
      updatedAt: z.string(),
      updatedBy: z.string(),
    }),
  ),
});

export const SoloProposalSchema = z.object({
  id: z.number(),
  projectId: z.number(),
  typeVersion: z.number(),
  overallVersion: z.number(),
  url: z.string(),
  createdAt: z.string(),
  updatedAt: z.string(),
  meta: z.object({
    id: z.number(),
    lat: z.string(),
    lon: z.string(),
    path: z.string(),
    sent: z.string(),
    ppaOr: z.unknown(),
    module: z.object({
      id: z.number(),
      name: z.string(),
      type: z.number(),
      width: z.number(),
      length: z.number(),
      wattage: z.number(),
      manufacturer: z.object({ id: z.number(), name: z.string() }),
      companyModules: z.array(z.object({ perWattAdder: z.number() })),
      manufacturerId: z.number(),
    }),
    offset: z.string(),
    aprbill: z.string(),
    augbill: z.string(),
    decbill: z.string(),
    febbill: z.string(),
    finance: z.object({
      id: z.number(),
      rate: z.number(),
      term: z.number(),
      type: z.string(),
      lenderId: z.number(),
      displayName: z.string(),
    }),
    janbill: z.string(),
    julbill: z.string(),
    junbill: z.string(),
    marbill: z.string(),
    maybill: z.string(),
    novbill: z.string(),
    octbill: z.string(),
    sepbill: z.string(),
    solarimg: z.string(),
    aprPrep: z.number(),
    augPrep: z.number(),
    customer: z.object({
      id: z.number(),
      lat: z.string(),
      lon: z.string(),
      zip: z.string(),
      city: z.string(),
      user: z.object({
        id: z.number(),
        email: z.string(),
        crmId: z.number(),
        lastName: z.string(),
        companyId: z.number(),
        firstName: z.string(),
      }),
      email: z.string(),
      phone: z.string(),
      state: z.string(),
      address: z.string(),
      userId: z.number(),
      realtime: z.boolean(),
      lastName: z.string(),
      leadType: z.string(),
      companyId: z.number(),
      firstName: z.string(),
      leadNumber: z.string(),
      sameDayLabel: z.string(),
      realtimeLabel: z.string(),
    }),
    decPrep: z.number(),
    febPrep: z.number(),
    inverter: z.object({
      id: z.number(),
      type: z.string(),
      model: z.string(),
      manufacturer: z.object({ id: z.number(), name: z.string() }),
      manufacturerId: z.number(),
      companyInverters: z.array(z.object({ perWattAdder: z.number() })),
    }),
    janPrep: z.number(),
    julPrep: z.number(),
    junPrep: z.number(),
    marPrep: z.number(),
    mayPrep: z.number(),
    novPrep: z.number(),
    octPrep: z.number(),
    sepPrep: z.number(),
    firstNote: z.object({ note: z.string() }),
    moduleId: z.number(),
    overrides: z.object({}),
    batteryId: z.number(),
    companyId: z.number(),
    customerId: z.number(),
    financeId: z.number(),
    leadNumber: z.string(),
    proposalId: z.string(),
    utilityId: z.number(),
    externalId: z.string(),
    inProgress: z.boolean(),
    inverterId: z.number(),
    loanAmount: z.string(),
    panelCount: z.number(),
    proposalPdf: z.string(),
    proposalRep: z.object({
      id: z.number(),
      email: z.string(),
      crmId: z.number(),
      manager: z.unknown(),
      lastName: z.string(),
      firstName: z.string(),
      managerId: z.number(),
      externalSalesRepId: z.number(),
    }),
    proposalUrl: z.string(),
    systemSize: z.string(),
    companyName: z.string(),
    dateCreated: z.string(),
    nearmapUsed: z.boolean(),
    batteryCount: z.number(),
    generatedDate: z.string(),
    pricePerKWh: z.string(),
    realtimeDate: z.string().nullable(),
    requestNotes: z.string().nullable(),
    updateReason: z.string(),
    companyCustom: z.unknown(),
    computedValues: z.unknown(),
    dateRequested: z.string(),
    googlemapUsed: z.boolean(),
    inverterCount: z.number(),
    pricePerWatt: z.string(),
    requestSource: z.string(),
    sketchDrawing: z.unknown(),
    monthlySavings: z.string(),
    proposalRequest: z.object({
      id: z.number(),
      usage: z.object({
        type: z.string(),
        annual: z.string(),
        monthly: z.array(z.unknown()),
      }),
      offset: z.number(),
      realtime: z.boolean(),
      createdAt: z.string(),
      updatedAt: z.string(),
      utilityId: z.number(),
      customerId: z.number(),
      disabledAt: z.string().nullable(),
      customPpkwh: z.unknown().nullable(),
      customUtility: z.unknown().nullable(),
      desiredOffset: z.boolean(),
      expectedTimeDue: z.unknown(),
      isSunpixelReady: z.boolean(),
      shouldUseSunpixel: z.boolean(),
    }),
    utilityCompany: z.object({ id: z.number(), name: z.string() }),
    valueQuestions: z.array(z.unknown()),
    requestPriority: z.string(),
    annualProduction: z.number(),
    longTermSavings: z.string(),
    nonBillableDate: z.string().nullable(),
    customerSignature: z.unknown().nullable(),
    firstYearSavings: z.string(),
    trueRequestedDate: z.string(),
    dateProposalSigned: z.string().nullable(),
    lastAccessByLegacyKey: z.unknown().nullable(),
  }),
  rejectedAt: z.string().nullable(),
  revisionData: z.unknown().nullable(),
  rejecterId: z.number().nullable(),
  revisionDenialReason: z.unknown().nullable(),
  revisionCompletedAt: z.string().nullable(),
  s3ProposalData: z.unknown().nullable(),
  externalId: z.string(),
  hiddenAt: z.string().nullable(),
  version: z.number(),
});

export const RoofingProposalSchema = z.object({
  latestProposal: z.object({
    id: z.number(),
    providerLeadId: z.number(),
    version: z.number(),
    assigneeId: z.number().nullable(),
    assignedAt: z.string().nullable(),
    pendingReason: z.string().nullable(),
    formData: z
      .object({
        reRoof: z.string(),
        woodRot: z.boolean(),
        underType: z.string(),
        otherNotes: z.string(),
        newRoofType: z.string(),
        flatRoofType: z.string(),
        existingRoofType: z.string(),
        otherExistingRoofType: z.string(),
      })
      .partial(),
    dueDate: z.string().nullable(),
    createdAt: z.string(),
    updatedAt: z.string(),
    roofingProposalUrl: z.string().nullable(),
    s3RoofingProposalData: z
      .object({
        key: z.string(),
        name: z.string(),
        bucket: z.string(),
        date_time: z.string(),
      })
      .partial(),
    measurementDocUrl: z.string().nullable(),
    s3MeasurementDocData: z
      .object({
        key: z.string(),
        name: z.string(),
        bucket: z.string(),
        date_time: z.string(),
      })
      .partial(),
    completedAt: z.string().nullable(),
    submittedById: z.number().nullable(),
    canceledAt: z.string().nullable(),
    proposalNotes: z.string().nullable(),
    measurementNotes: z.string().nullable(),
  }),
  roofingAssets: z.array(z.unknown()),
});

export const TpoPricingWorksheetSchema = z.object({
  id: z.number(),
  worksheetUrl: z.string(),
  projectId: z.number(),
  slug: z.string(),
  version: z.number(),
  createdAt: z.string(),
});
