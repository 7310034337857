export enum ProjectBlockerStatus {
  SELLER_ACTION_NEEDED = "seller_action_needed",
  HOMEOWNER_ACTION_NEEDED = "homeowner_action_needed",
  PENDING_REVIEW = "pending_review",
  PENDING_CORRECTIONS = "pending_corrections",
  COMPLETE = "complete",
}

export enum ProjectBlockerSlug {
  OS = "os",
  BUILDABLE_WITH_CHANGES = "buildable_with_changes",
  CHANGE_ORDER_REQUIRED = "change_order_required",
}

export enum ProjectBlockerAssetType {
  BLOCKER = "blocker",
  OS = "os",
}
