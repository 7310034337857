export { default as AlertIcon } from "./AlertIcon";
export { default as ApprovedCheckIcon } from "./ApprovedCheckIcon";
export { default as ArchiveIcon } from "./archiveIcon";
export { default as BackdropChevron } from "./Chevrons/BackdropChevron";
export { default as BillingSupport } from "./MainNav/BillingSupport";
export { default as BlockerIcon } from "./BlockerIcon";
export { default as BorderedCloseIcon } from "./BorderedCloseIcon";
export { default as CalendarIcon } from "./Calendars/CalendarIcon";
export { default as CalendarIconDates } from "./Calendars/CalendarIconDates";
export { default as ScheduleCalendar } from "./Calendars/scheduleCalendar";
export { default as CalendarXIcon } from "./Calendars/CalendarXIcon";
export { default as CalendarTimerIcon } from "./Calendars/CalendarTimerIcon";
export { default as CancelIcon } from "./CancelIcon";
export { default as CancelIconFilled } from "./CancelIconFilled";
export { default as CaretLeftOutlined } from "./Chevrons/CaretLeftOutlined";
export { default as CaretRightOutlined } from "./Chevrons/CaretRightOutlined";
export { default as LeftCaretFilled } from "./Chevrons/LeftCaretFilled";
export { default as CCIcon } from "./CCIcon";
export { default as CheckIcon } from "./KPI/CheckIcon";
export { default as CheckIcon2 } from "./CheckIcon2";
export { default as CheckMarkIcon } from "./CheckMarkIcon";
export { default as CheckMarkBullet } from "./CheckMarkBullet";
export { default as CheckboxChecked } from "./CheckboxChecked";
export { default as CheckboxUnchecked } from "./CheckboxUnchecked";
export { default as CheckWithShadowIcon } from "./CheckWithShadowIcon";
export { default as ChevronVertical } from "./Chevrons/ChevronVertical";
export { default as CircleCheckGradient } from "./CircleCheckGradient";
export { default as CircleCheckFilled } from "./CircleCheckFilled";
export { default as CleanDealIcon } from "./CleanDealIcon";
export { default as ClockIcon } from "./ClockIcon";
export { default as CloseIconPlain } from "./CancelIcon";
export { default as CloseIconOutlinedSquare } from "./CloseIconOutlinedSquare";
export { default as CoffeeMugIcon } from "./CoffeeMugIcon";
export { default as CommissionIcon } from "./CommissionIcon";
export { default as ConfettiYellowIcon } from "./ConfettiYellowIcon";
export { default as SellerProject } from "./KPI/SellerProject";
export { default as copyIcon } from "./copyIcon";
export { default as CopyToClipboardIcon } from "./CopyToClipboardIcon";
export { default as CrabIcon } from "./CrabIcon";
export { default as DailyCalendar } from "./Payouts/DailyCalendar";
export { default as Dashboard } from "./MainNav/BillingSupport";
export { default as DefaultAvatar } from "./Avatars/DefaultAvatar";
export { default as PublicNoteAvatar } from "./Avatars/PublicNoteAvatar";
export { default as DeletePaymentIcon } from "./DeletePaymentIcon";
export { default as DocumentIcon } from "./DocumentIcon";
export { default as DoubleDownCaret } from "./Chevrons/DoubleDownCaret";
export { default as DownCaret } from "./Chevrons/DownCaret";
export { default as DownCaretFilled } from "./Chevrons/DownCaretFilled";
export { default as DownCaretThicker } from "./Chevrons/downCaretThicker";
export { default as Earnings } from "./MainNav/Earnings";
export { default as EditIcon } from "./EditIcon";
export { default as ellipses } from "./ellipses";
export { default as EnterpriseCompensation } from "./MainNav/EnterpriseCompensation";
export { default as EnterpriseDashboard } from "./MainNav/EnterpriseDashboard";
export { default as EnterpriseEarnings } from "./MainNav/EnterpriseEarnings";
export { default as EnterpriseJoinBanner } from "./EnterpriseJoinBanner";
export { default as EnterprisePersonnel } from "./MainNav/EnterprisePersonnel";
export { default as EnterpriseProjects } from "./MainNav/EnterpriseProjects";
export { default as EnterpriseReports } from "./MainNav/EnterpriseReports";
export { default as EnterpriseSettings } from "./MainNav/EnterpriseSettings";
export { default as EnterpriseTileIcon } from "./EnterpriseTileIcon";
export { default as EnvelopeCrmIcon } from "./EnvelopeCrmIcon";
export { default as EnvelopeCrmGradientIcon } from "./EnvelopeCrmGradientIcon";
export { default as EyeIcon } from "./EyeIcon";
export { default as EyeIconDash } from "./EyeIconDash";
export { default as FacebookIcon } from "./FacebookIcon";
export { default as FilterIcon } from "./FilterIcon";
export { default as FlagIcon } from "./FlagIcon";
export { default as GoogleIcon } from "./GoogleIcon";
export { default as GroupSilhouetteIcon } from "./GroupSilhouetteIcon";
export { default as HandshakeIcon } from "./HandshakeIcon";
export { default as HeaderLogo } from "./Logos/HeaderLogo";
export { default as HeaderLogoBlue } from "./Logos/HeaderLogoBlue";
export { default as homeIcon } from "./homeIcon";
export { default as homeOwnerIcon } from "./homeOwnerIcon";
export { default as HomeOwnerGradientIcon } from "./homeOwnerGradientIcon";
export { default as HomeOwnerIcon } from "./homeOwnerIcon";
export { default as HomeownerIconLarge } from "./HomeownerIconLarge";
export { default as HomeownerNotesIcon } from "./HomeownerNotesIcon";
export { default as InfoIcon } from "./InfoIcon";
export { default as InfoIconOutlined } from "./InfoIconOutlined";
export { default as InProgressIcon } from "./KPI/InProgressIcon";
export { default as KnowledgeBase } from "./MainNav/KnowledgeBase";
export { default as LightbulbIcon } from "./LightbulbIcon";
export { default as LightbulbOutlineIcon } from "./LightbulbOutlineIcon";
export { default as LightningIcon } from "./LightningIcon";
export { default as ToolsIcon } from "./ToolsIcon";
export { default as logbackIcon } from "./logbackIcon";
export { default as MagnifyingGlassIcon } from "./MagnifyingGlassIcon";
export { default as MagnifyingGlassBold } from "./MagnifyingGlassBold";
export { default as markReadIcon } from "./markReadIcon";
export { default as markUnreadIcon } from "./markUnreadIcon";
export { default as MarketingIconStatic } from "./MarketingIconStatic";
export { default as MenuIconFilled } from "./MenuIconFilled";
export { default as MoneyEnvelopeIcon } from "./MoneyEnvelopeIcon";
export { default as MoneyGraphIcon } from "./MoneyGraphIcon";
export { default as notesIcon } from "./notesIcon";
export { default as OpenInNewWindowIcon } from "./OpenInNewWindowIcon";
export { default as Notices } from "./MainNav/Notices";
export { default as OfferPendingIcon } from "./KPI/OfferPendingIcon";
export { default as Organizations } from "./MainNav/Organizations";
export { default as OrgSellerStar } from "./OrgSellerStar";
export { default as PencilEditIcon } from "./PencilEditIcon";
export { default as PersonSilhouetteIcon } from "./PersonSilhouetteIcon";
export { default as PhoneIcon } from "./PhoneIcons/phoneIcon";
export { default as PhoneIconFilled } from "./PhoneIcons/PhoneIconFilled";
export { default as PhoneIconFilledGradient } from "./PhoneIcons/PhoneIconFilledGradient";
export { default as PlusIcon } from "./PlusIcon";
export { default as PlusOutlined } from "./PlusOutlined";
export { default as PmNotesIcon } from "./PmNotesIcon";
export { default as PowurPairIcon } from "./PowurPairIcon";
export { default as PowurCreditsGradientIcon } from "./PowurCreditsGradientIcon";
export { default as PowurLive } from "./MainNav/PowurLive";
export { default as PowurLiveAvatar } from "./Avatars/PowurLiveAvatar";
export { default as PowurUniversity } from "./MainNav/PowurUniversity";
export { default as PowurUniversityGradientIcon } from "./PowurUniversityGradientIcon";
export { default as ProjectAlert } from "./ProjectAlert";
export { default as ProjectAlertTriangle } from "./ProjectAlertTriangle";
export { default as ProjectIcon } from "./ProjectIcon";
export { default as Projects } from "./MainNav/Projects";
export { default as ProposalRequestCreatedIcon } from "./ProposalRequestCreatedIcon";
export { default as SandtimerIcon } from "./SandtimerIcon";
export { default as SolarProposals } from "./MainNav/Proposals";
export { default as questionHouseIcon } from "./questionHouseIcon";
export { default as ReminderIcon } from "./ReminderIcon";
export { default as ReminderGradientIcon } from "./ReminderGradientIcon";
export { default as rightArrow } from "./Arrows/rightArrow";
export { default as RightArrowLong } from "./Arrows/rightArrowLong";
export { default as rightArrow2 } from "./Arrows/rightArrow2";
export { default as RightCarouselArrow } from "./Arrows/rightCarouselArrow";
export { default as LeftCarouselArrow } from "./Arrows/leftCarouselArrow";
export { default as RightGradArrow } from "./Arrows/RightGradArrow";
export { default as RightCaretFilled } from "./Chevrons/RightCaretFilled";
export { default as RightCaretIcon } from "./Chevrons/RightCaretIcon";
export { default as rotaryPhoneIcon } from "./PhoneIcons/rotaryPhoneIcon";
export { default as scheduleCalendar } from "./Calendars/scheduleCalendar";
export { default as scriptIcon } from "./scriptIcon";
export { default as SettingsTuneIcon } from "./SettingsTuneIcon";
export { default as SilhouetteDoubleIcon } from "./SilhouetteDoubleIcon";
export { default as SilhouetteDoubleGradientIcon } from "./SilhouetteDoubleGradientIcon";
export { default as SplitArrow } from "./Arrows/splitArrow";
export { default as Settings } from "./MainNav/Settings";
export { default as SquareIcon } from "./SquareIcon";
export { default as starIcon } from "./starIcon";
export { default as StarOutlineIcon } from "./StarOutlineIcon";
export { default as starIconBordered } from "./starIconBordered";
export { default as StatusIconComplete } from "./StatusIconComplete";
export { default as StatusIconCurrent } from "./StatusIconCurrent";
export { default as StripeIcon } from "./StripeIcon";
export { default as SupportTickets } from "./MainNav/SupportTickets";
export { default as SwapIcon } from "./KPI/SwapIcon";
export { default as Team } from "./MainNav/Team";
export { default as ThumbsUpIcon } from "./ThumbsUpIcon";
export { default as TicketsButtonIcon } from "./TicketsButtonIcon";
export { default as tripleUpChevron } from "./Chevrons/tripleUpChevron";
export { default as TrophyIcon } from "./trophyIcon";
export { default as TrophyNoIcon } from "./TrophyNoIcon";
export { default as upDownIcon } from "./upDownIcon";
export { default as Users } from "./MainNav/Users";
export { default as WeeklyCalendar } from "./Payouts/WeeklyCalendar";
export { default as Workflows } from "./MainNav/Workflows";
export { default as CircleCloseIcon } from "./CircleCloseIcon";
export { default as CloseIcon } from "./CloseIcon";
export { default as DollarSignCircled } from "./DollarSignCircled";
export { default as WarningOutlineInCircleIcon } from "./WarningOutlineInCircleIcon";
export { default as UploadIcon } from "./UploadIcon";
export { default as PdfIcon } from "./PdfIcon";
export { default as ClockIconSolid } from "./ClockIconSolid";
export { default as ClockIconOutline } from "./ClockIconOutline";
export { default as TrashcanIcon } from "./TrashcanIcon";
export { default as TrashCanFile } from "./TrashCanFile";
export { default as FileIcon } from "./FileIcon";
export { default as UnqualifiedLeadsIcon } from "./KPI/UnqualifiedLeads";
export { default as UnqualifiedLeadsGradient } from "./KPI/UnqualifiedLeadsGradient";
export { default as QualifiedLeadsGradient } from "./KPI/QualifiedLeadsGradient";
export { default as AmbassadorSubmittedIcon } from "./KPI/AmbassadorSubmitted";
export { default as ProposalsPresentedGradient } from "./KPI/ProposalsPresentedGradient";
export { default as ProposalsGeneratedGradient } from "./KPI/ProposalsGeneratedGradient";
export { default as ContractsOutGradient } from "./KPI/ContractsOutGradient";
export { default as ContractsSignedGradient } from "./KPI/ContractsSignedGradient";
export { default as UtilityIcon } from "./UtilityIcon";
export { default as LocationPin } from "./LocationPin";
export { default as LocationPinFilledIcon } from "./LocationPinFilledIcon";
export { default as InstallerIcon } from "./InstallerIcon";
export { default as DownloadIcon } from "./DownloadIcon";
export { default as UncheckedRadioButton } from "./UncheckedRadioButton";
export { default as CheckedRadioButton } from "./CheckedRadioButton";
export { default as RequestedIcon } from "./RequestedIcon";
export { default as ClosedProposalsIcon } from "./ClosedProposalsIcon";
export { default as EscalatedIcon } from "./EscalatedIcon";
export { default as PendingLeadOfferIcon } from "./KPI/PendingLeadOfferIcon";
export { default as ProjectBlockerIcon } from "./ProjectBlockerIcon";
export { default as FilledBlockerIcon } from "./FilledBlockerIcon";

// Powur Updates Icons
export { default as EquipmentUpdateIcon } from "./PowurUpdatesIcons/EquipmentUpdateIcon";
export { default as FinancingUpdateIcon } from "./PowurUpdatesIcons/FinancingUpdateIcon";
export { default as GeneralUpdateIcon } from "./PowurUpdatesIcons/GeneralUpdateIcon";
export { default as PlatformUpdateIcon } from "./PowurUpdatesIcons/PlatformUpdateIcon";
export { default as PricingUpdateIcon } from "./PowurUpdatesIcons/PricingUpdateIcon";
export { default as TrainingUpdateIcon } from "./PowurUpdatesIcons/TrainingUpdateIcon";
export { default as NeedsAttentionIcon } from "./PowurUpdatesIcons/NeedsAttentionIcon";

// Main Nav
export { default as ActivityCenter } from "./MainNav/ActivityCenter";
export { default as BillingSupportIcon } from "./MainNav/BillingSupport";
export { default as CompensationIcon } from "./MainNav/Compensation";
export { default as DashboardIcon } from "./MainNav/Dashboard";
export { default as EarningsIcon } from "./MainNav/Earnings";
export { default as GettingStartedIcon } from "./MainNav/GettingStarted";
export { default as KnowledgeBaseIcon } from "./MainNav/KnowledgeBase";
export { default as NoticesIcon } from "./MainNav/Notices";
export { default as OnlineMarketingIcon } from "./MainNav/OnlineMarketing";
export { default as OrganizationsIcon } from "./MainNav/Organizations";
export { default as PowurLiveIcon } from "./MainNav/PowurLive";
export { default as PowurLogoIcon } from "./MainNav/PowurLogo";
export { default as PowurLogoMobileIcon } from "./MainNav/PowurLogoMobile";
export { default as PowurLogoXIcon } from "./MainNav/PowurLogoX";
export { default as PowurUniversityIcon } from "./MainNav/PowurUniversity";
export { default as PreSalesSupportIcon } from "./MainNav/PreSalesSupport";
export { default as ProjectsIcon } from "./MainNav/Projects";
export { default as SolarProposalsIcon } from "./MainNav/Proposals";
export { default as RoofingProposalsIcon } from "./MainNav/RoofingProposals";
export { default as EnterpriseApplications } from "./MainNav/EnterpriseApplications";
export { default as ReportsIcon } from "./MainNav/Reports";
export { default as SettingsIcon } from "./MainNav/Settings";
export { default as SupportTicketsIcon } from "./MainNav/SupportTickets";
export { default as TeamIcon } from "./MainNav/Team";
export { default as UsersIcon } from "./MainNav/Users";
export { default as WorkflowsIcon } from "./MainNav/Workflows";
export { default as CommunicationsIcon } from "./MainNav/Communications";
export { default as PlatformAlerts } from "./MainNav/PlatformAlerts";
export { default as AmbassadorIcon } from "./MainNav/Ambassador";
export { default as PowurPlayIcon } from "./MainNav/PowurPlay";
export { default as ChatWindowIcon } from "./ChatWindowIcon";
export { default as LinkIcon } from "./LinkIcon";
export { default as LinkIcon2 } from "./LinkIcon2";
export { default as ModulesIcon } from "./MainNav/ModulesIcon";
export { default as WarningIconGradient } from "./WarningIconGradient";
export { CalendarDotsIcon } from "./Calendars/CalendarDotsIcon";
export { default as UpCaretFilled } from "./Chevrons/UpCaretFilled";
export { default as ListIcon } from "./listIcon";
export { default as KanbanIcon } from "./kanbanIcon";

export { default as IncompleteCheck } from "./IncompleteCheck";
export { default as ContractIcon } from "./ContractIcon";
export { default as ContractComparison } from "./ContractComparison";
// Enterprise Icons:
export { default as EnterpriseDashboardIcon } from "./MainNav/EnterpriseDashboard";
export { default as EnterpriseProjectsIcon } from "./MainNav/EnterpriseProjects";
export { default as EnterprisePersonnelIcon } from "./MainNav/EnterprisePersonnel";
export { default as EnterpriseReportsIcon } from "./MainNav/EnterpriseReports";
export { default as EnterpriseEarningsIcon } from "./MainNav/EnterpriseEarnings";
export { default as EnterpriseCompensationIcon } from "./MainNav/EnterpriseCompensation";
export { default as EnterpriseSettingsIcon } from "./MainNav/EnterpriseSettings";
export { default as EnterpriseOfferLeadIcon } from "./MainNav/EnterpriseOfferLead";

export { default as RescindIcon } from "./RescindIcon";
export { default as NearInstallIcon } from "./KPI/NearInstallIcon";
export { default as TotalContractsIcon } from "./KPI/TotalContractsIcon";
export { default as ContractConversionRateIcon } from "./KPI/ContractConversionRateIcon";
export { default as InstalledThisMonth } from "./KPI/InstalledThisMonth";
export { default as LifetimeAssignedInstalls } from "./KPI/LifetimeAssignedInstalls";
export { default as LifetimeTotalInstalls } from "./KPI/LifetimeTotalInstalls";
export { default as ClosedThisMonth } from "./KPI/ClosedThisMonth";
export { default as CancellationRate } from "./KPI/CancellationRate";
export { default as TotalClosed } from "./KPI/TotalClosed";
export { default as TotalActiveProjects } from "./KPI/TotalActiveProjects";
export { default as AccountIcon } from "./MainHeader/AccountIcon";
export { default as LogoutIcon } from "./MainHeader/LogoutIcon";
export { default as ProfileIcon } from "./MainHeader/ProfileIcon";
export { default as FireEmojiIcon } from "./FireEmojiIcon";
export { default as WarningIcon } from "./WarningIcon";
export { default as WarningIconFilled } from "./WarningIconFilled";

// Milestones
export { default as AwaitingPermit } from "./Milestones/AwaitingPermit";
export { default as CustomDesign } from "./Milestones/CustomDesign";

export { default as Inspection } from "./Milestones/Inspection";
export { default as Installation } from "./Milestones/Installation";
export { default as PermissionToOperate } from "./Milestones/PermissionToOperate";
export { default as SchedulingInstall } from "./Milestones/SchedulingInstall";
export { default as SiteSurvey } from "./Milestones/SiteSurvey";
export { default as ProjectKickoff } from "./Milestones/ProjectKickoff";

// Project Activity Log
export { default as NewLeadIcon } from "./ProjectActivityLog/NewLeadIcon";
export { default as QualifiedIcon } from "./ProjectActivityLog/QualifiedIcon";
export { default as ProposalRequestedIcon } from "./ProjectActivityLog/ProposalRequestedIcon";
export { default as ProposalGeneratedIcon } from "./ProjectActivityLog/ProposalGeneratedIcon";
export { default as ProposalPresentedIcon } from "./ProjectActivityLog/ProposalPresentedIcon";
export { default as DocumentsOutIcon } from "./ProjectActivityLog/DocumentsOutIcon";
export { default as ContractSignedIcon } from "./ProjectActivityLog/ContractSignedIcon";
export { default as SaleCompleteIcon } from "./ProjectActivityLog/SaleCompleteIcon";
export { default as ProjectKickoffIcon } from "./ProjectActivityLog/ProjectKickoffIcon";
export { default as SiteSurveyCompleteIcon } from "./ProjectActivityLog/SiteSurveyCompleteIcon";
export { default as SiteSurveyScheduledIcon } from "./ProjectActivityLog/SiteSurveyScheduledIcon";
export { default as DesignCompleteIcon } from "./ProjectActivityLog/DesignCompleteIcon";
export { default as PermitApprovedIcon } from "./ProjectActivityLog/PermitApprovedIcon";
export { default as InstallScheduledIcon } from "./ProjectActivityLog/InstallScheduledIcon";
export { default as InspectionScheduledIcon } from "./ProjectActivityLog/InspectionScheduledIcon";
export { default as PtoAppSubmittedIcon } from "./ProjectActivityLog/PtoAppSubmittedIcon";
export { default as PermissionToOpIcon } from "./ProjectActivityLog/PermissionToOpIcon";
export { default as InspectionPassedIcon } from "./ProjectActivityLog/InspectionPassedIcon";
export { default as PowurOnIcon } from "./ProjectActivityLog/PowurOnIcon";

// Certification Badges
export { default as RoofingCertificationIcon } from "./Certifications/RoofingIcon";
export { default as TierTwoCertificationIcon } from "./Certifications/TierTwoIcon";

export { default as BellIcon } from "./BellIcon";
export { default as CaretBundle } from "./CaretBundle";
export { default as MortarCapIcon } from "./MortarCapIcon";

// Survey Tab Icons
export { default as EnvelopeIcon } from "./SiteSurvey/envelope";
export { default as MessageIcon } from "./SiteSurvey/message";

export { default as ImageIcon } from "./Files/ImageIcon";
export { default as PreferredPartnerModuleIcon } from "./PreferredPartnerModule";

// Project Dashboard Icons
export { default as ProfitIcon } from "./ProfitIcon";
export { default as MaximizeIcon } from "./MaximizeIcon";
export { default as PlugIcon } from "./PlugIcon";
export { default as WrenchIcon } from "./WrenchIcon";
export { default as PowurTileIcon } from "./PowurTileIcon";
export { default as PadlockIcon } from "./PadlockIcon";
export { default as FinancialIcon } from "./FinancialIcon";

export const iconList = [
  "AccountIcon",
  "ActivityCenter",
  "AlertIcon",
  "AmbassadorIcon",
  "ApprovedCheckIcon",
  "ArchiveIcon",
  "AwaitingPermit",
  "BackdropChevron",
  "BellIcon",
  "BillingSupport",
  "BillingSupportIcon",
  "BlockerIcon",
  "BorderedCloseIcon",
  "CCIcon",
  "CalendarIcon",
  "CalendarIconDates",
  "CalendarDotsIcon",
  "ScheduleCalendar",
  "CalendarXIcon",
  "CalendarTimerIcon",
  "CancelIcon",
  "CancelIconFilled",
  "CancellationRate",
  "CaretBundle",
  "CaretLeftOutlined",
  "CaretRightOutlined",
  "LeftCaretFilled",
  "ChatWindowIcon",
  "CheckIcon",
  "CheckIcon2",
  "CheckMarkBullet",
  "CheckMarkIcon",
  "CheckboxChecked",
  "CheckboxUnchecked",
  "CheckWithShadowIcon",
  "ChevronVertical",
  "CircleCheckGradient",
  "CircleCloseIcon",
  "CleanDealIcon",
  "ClockIcon",
  "CloseIcon",
  "CloseIconPlain",
  "CloseIconOutlinedSquare",
  "ClosedThisMonth",
  "CoffeeMugIcon",
  "CommissionIcon",
  "CommunicationsIcon",
  "CompensationIcon",
  "ConfettiYellowIcon",
  "ContractComparison",
  "ContractConversionRateIcon",
  "ContractIcon",
  "ContractSignedIcon",
  "CopyToClipboardIcon",
  "CrabIcon",
  "CustomDesign",
  "DailyCalendar",
  "Dashboard",
  "DefaultAvatar",
  "DocumentIcon",
  "DocumentsOutIcon",
  "PublicNoteAvatar",
  "DollarSignCircled",
  "DoubleDownCaret",
  "DownCaret",
  "DownCaretFilled",
  "DownCaretThicker",
  "Earnings",
  "EarningsIcon",
  "EditIcon",
  "EnterpriseCompensation",
  "EnterpriseDashboard",
  "EnterpriseEarnings",
  "EnterpriseJoinBanner",
  "EnterprisePersonnel",
  "EnterpriseProjects",
  "EnterpriseReports",
  "EnterpriseSettings",
  "EnterpriseTileIcon",
  "EnvelopeCrmIcon",
  "EnvelopeCrmGradientIcon",
  "EnvelopeIcon",
  "EquipmentUpdateIcon",
  "EyeIcon",
  "EyeIconDash",
  "FacebookIcon",
  "FinancingUpdateIcon",
  "FilterIcon",
  "FlagIcon",
  "GeneralUpdateIcon",
  "GettingStartedIcon",
  "GoogleIcon",
  "GroupSilhouetteIcon",
  "HandshakeIcon",
  "HeaderLogo",
  "HeaderLogoBlue",
  "HomeownerIconLarge",
  "HomeownerNotesIcon",
  "IncompleteCheck",
  "InProgressIcon",
  "InfoIcon",
  "InfoIconOutlined",
  "Inspection",
  "Installation",
  "InstalledThisMonth",
  "KanbanIcon",
  "KnowledgeBase",
  "KnowledgeBaseIcon",
  "LifetimeAssignedInstalls",
  "LifetimeTotalInstalls",
  "LightbulbIcon",
  "LightbulbOutlineIcon",
  "LightningIcon",
  "ListIcon",
  "LinkIcon2",
  "LogoutIcon",
  "MagnifyingGlassBold",
  "MagnifyingGlassIcon",
  "MenuIconFilled",
  "MessageIcon",
  "MoneyEnvelopeIcon",
  "MoneyGraphIcon",
  "MarketingIconStatic",
  "NearInstallIcon",
  "NeedsAttentionIcon",
  "NewLeadIcon",
  "Notices",
  "NoticesIcon",
  "OfferPendingIcon",
  "OnlineMarketingIcon",
  "OpenInNewWindowIcon",
  "WarningIconGradient",
  "OrgSellerStar",
  "Organizations",
  "OrganizationsIcon",
  "PencilEditIcon",
  "PersonSilhouetteIcon",
  "PermissionToOperate",
  "PhoneIconFilled",
  "PhoneIconFilledGradient",
  "PlatformAlerts",
  "PlatformUpdateIcon",
  "PlusIcon",
  "PlusOutlined",
  "PmNotesIcon",
  "PowurCreditsGradientIcon",
  "PowurPairIcon",
  "PowurLive",
  "PowurLiveAvatar",
  "PowurLiveIcon",
  "PowurLogoIcon",
  "PowurLogoMobileIcon",
  "PowurLogoXIcon",
  "PowurUniversity",
  "PowurUniversityIcon",
  "PowurUniversityGradientIcon",
  "PreSalesSupportIcon",
  "PricingUpdateIcon",
  "ProfileIcon",
  "ProposalRequestedIcon",
  "ProposalGeneratedIcon",
  "ProposalPresentedIcon",
  "ProposalRequestCreatedIcon",
  "FireEmojiIcon",
  "ProjectAlert",
  "ProjectAlertTriangle",
  "ProjectIcon",
  "ProjectKickoff",
  "Projects",
  "ProjectsIcon",
  "SolarProposals",
  "RoofingProposalsIcon",
  "EnterpriseApplications",
  "ReminderIcon",
  "ReminderGradientIcon",
  "ReportsIcon",
  "RescindIcon",
  "RightCaretFilled",
  "RightCaretIcon",
  "RightGradArrow",
  "SandtimerIcon",
  "SchedulingInstall",
  "Settings",
  "SettingsIcon",
  "SilhouetteDoubleIcon",
  "SilhouetteDoubleGradientIcon",
  "SiteSurvey",
  "SplitArrow",
  "StarOutlineIcon",
  "StatusIconComplete",
  "StatusIconCurrent",
  "StripeIcon",
  "SupportTickets",
  "SupportTicketsIcon",
  "SwapIcon",
  "Team",
  "TeamIcon",
  "ThumbsUpIcon",
  "TicketsButtonIcon",
  "ToolsIcon",
  "TotalClosed",
  "TotalActiveProjects",
  "TotalContractsIcon",
  "TrainingUpdateIcon",
  "UpCaretFilled",
  "Users",
  "UsersIcon",
  "WeeklyCalendar",
  "Workflows",
  "EnterpriseOfferLeadIcon",
  "SellerProject",
  "PreferredPartnerModuleIcon",
  "PdfIcon",
  "UploadIcon",
  "WorkflowsIcon",
  "copyIcon",
  "ellipses",
  "homeIcon",
  "homeOwnerIcon",
  "HomeOwnerGradientIcon",
  "HomeOwnerIcon",
  "logbackIcon",
  "markReadIcon",
  "markUnreadIcon",
  "notesIcon",
  "questionHouseIcon",
  "QualifiedIcon",
  "rightArrow",
  "rightArrow2",
  // "rightArrowLong", causing error, temp commenting out
  "rotaryPhoneIcon",
  "scheduleCalendar",
  "scriptIcon",
  "SettingsTuneIcon",
  "SquareIcon",
  "starIcon",
  "starIconBordered",
  "tripleUpChevron",
  "TrophyIcon",
  "TrophyNoIcon",
  "upDownIcon",
  "ImageIcon",
  "ModulesIcon",
  "WarningIcon",
  "WarningIconFilled",
  "WarningOutlineInCircleIcon",
  "MortarCapIcon",
  "ClockIconSolid",
  "ClockIconOutline",
  "UnqualifiedLeadsIcon",
  "UnqualifiedLeadsGradient",
  "QualifiedLeadsGradient",
  "ProposalsGeneratedGradient",
  "ProposalsPresentedGradient",
  "ContractsOutGradient",
  "ContractsSignedGradient",
  "AmbassadorSubmittedIcon",
  "UtilityIcon",
  "LocationPin",
  "LocationPinFilledIcon",
  "InstallerIcon",
  "DownloadIcon",
  "RoofingCertificationIcon",
  "TierTwoCertificationIcon",
  "SaleCompleteIcon",
  "ProjectKickoffIcon",
  "SiteSurveyCompleteIcon",
  "SiteSurveyScheduledIcon",
  "DesignCompleteIcon",
  "PermitApprovedIcon",
  "InstallScheduledIcon",
  "InspectionScheduledIcon",
  "PtoAppSubmittedIcon",
  "PowurOnIcon",
  "PermissionToOpIcon",
  "InspectionPassedIcon",
  "UncheckedRadioButton",
  "CheckedRadioButton",
  "PowurPlayIcon",
  "RequestedIcon",
  "ClosedProposalsIcon",
  "EscalatedIcon",
  "PendingLeadOfferIcon",
  "ProjectBlockerIcon",
  "ProfitIcon",
  "MaximizeIcon",
  "PlugIcon",
  "WrenchIcon",
  "PowurTileIcon",
  "PadlockIcon",
  "FinancialIcon",
]
  .sort()
  .filter((icon, index, arr) => {
    return arr.indexOf(icon) === index;
  });
