import { ProjectBlockerSlug, ProjectBlockerStatus } from "rdx/modules/projectBlockers/enums";
import { ActivityLogDataT, ProjectActivityBlockerDataT } from "rdx/modules/projects/types/projects/pre-sales";
import { CrmActivityT } from "types/Project/crmActivity";

type FormattedDataReturnT = {
  updatedHistoricalActivityLog: ActivityLogDataT[];
  updatedCrmActivities: CrmActivityT[];
};

const formatCompletedBlocker = (
  crmActivities: CrmActivityT[],
  historicalActivityLog: ActivityLogDataT[],
): FormattedDataReturnT => {
  const changeOrderBlockerSlugs = [ProjectBlockerSlug.BUILDABLE_WITH_CHANGES, ProjectBlockerSlug.CHANGE_ORDER_REQUIRED];
  const crmActivityIdx = crmActivities.findIndex(
    (activity) =>
      !!activity?.slug &&
      activity.kind === "blocker" &&
      changeOrderBlockerSlugs.includes(activity.slug) &&
      activity.completeType === ProjectBlockerStatus.SELLER_ACTION_NEEDED,
  );
  const completedActivity = { ...crmActivities[crmActivityIdx] };
  const updatedCrmActivities = [...crmActivities];
  updatedCrmActivities.splice(crmActivityIdx, 1);
  const updatedHistoricalActivityLog = [...historicalActivityLog];

  const coBlockerDescriptions = {
    change_order_required: "Change Order Approved",
    buildable_with_changes: "Project Change Approved",
  };

  if (completedActivity.slug) {
    const newActivityLogItem: ProjectActivityBlockerDataT = {
      id: completedActivity.id,
      title: completedActivity.title,
      description: coBlockerDescriptions[completedActivity.slug],
      activityDate: new Date().toISOString(),
      type: "blocker",
    };

    updatedHistoricalActivityLog.unshift(newActivityLogItem);
  }
  return {
    updatedHistoricalActivityLog,
    updatedCrmActivities,
  };
};

export default formatCompletedBlocker;
