import z from "zod";
import { AddressSchema, AvatarSchema } from "rdx/modules/users/schemas/users";

export const LeadSchema = z.object({
  id: z.number(),
  firstName: z.string(),
  lastName: z.string(),
  fullName: z.string(),
  email: z.string(),
  phone: z.string(),
  averageBill: z.number().nullable(),
  category: z.string(),
  notes: z.string().nullable(),
  createdAt: z.string(),
  updatedAt: z.string(),
  locale: z.string(),
  kind: z.union([z.literal("consultant"), z.literal("ambassador"), z.literal("lead_generator")]),
  leadSource: z.string().nullable(),
  allowDuplicateAddress: z.boolean(),
  copilot: z.boolean(),
  siteId: z.number().nullable(),
  apiUri: z.string(),
  utilityBillUrl: z.string().nullable(),
  isAddressConfirmed: z.boolean(),
  owner: z.object({
    id: z.number(),
    fullName: z.string(),
    email: z.string(),
    firstName: z.string(),
    lastName: z.string(),
    phone: z.string(),
    avatar: AvatarSchema,
  }),
  enterprise: z
    .object({
      id: z.number(),
      name: z.string(),
    })
    .optional(),
  address: AddressSchema,
});
