import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import z from "zod";
import { projectBlockersApi } from "./endpoints";
import { ProjectBlockerT, IndexProjectBlockerT } from "./types";
import { ProjectBlockerAssetSchema } from "./schemas";

type ProjectBlockerDrawerPayloadT = {
  visible: boolean;
  blockerId?: number;
  projectId?: number;
};

export type ProjectBlockerReduxStateT = {
  projectBlockers: {
    blockers: IndexProjectBlockerT[];
    completedBlockers: number;
    allBlockersComplete: boolean;
  };
  projectBlockerDetails: ProjectBlockerT | null;
  projectBlockerDrawer: {
    visible: boolean;
    projectId: number | null;
    blockerId: number | null;
  };
};

export type ProjectBlockerAsset = z.infer<typeof ProjectBlockerAssetSchema>;

const initialState: ProjectBlockerReduxStateT = {
  projectBlockers: {
    blockers: [],
    completedBlockers: 0,
    allBlockersComplete: false,
  },
  projectBlockerDetails: null,
  projectBlockerDrawer: {
    visible: false,
    blockerId: null,
    projectId: null,
  },
};

export const projectBlockersSlice = createSlice({
  name: "projectBlockers",
  initialState,
  reducers: {
    setProjectBlockerDrawerVisible: (state, { payload }: PayloadAction<ProjectBlockerDrawerPayloadT>) => {
      state.projectBlockerDrawer = {
        visible: payload.visible,
        blockerId: payload.blockerId || null,
        projectId: payload.projectId || null,
      };
    },
    removeBlockerAsset: (state, { payload }: PayloadAction<number>) => {
      if (state.projectBlockerDetails) {
        state.projectBlockerDetails.assets = state.projectBlockerDetails.assets?.filter(
          (asset) => asset.id !== payload,
        );
      }
    },
    addBlockerAsset: (state, { payload }: PayloadAction<ProjectBlockerAsset>) => {
      if (state.projectBlockerDetails) {
        state.projectBlockerDetails.assets = state.projectBlockerDetails.assets
          ? [...state.projectBlockerDetails.assets, payload]
          : [payload];
      }
    },
    setBlockerAssets: (state, { payload }: PayloadAction<ProjectBlockerAsset[]>) => {
      if (state.projectBlockerDetails) {
        state.projectBlockerDetails.assets = payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(projectBlockersApi.endpoints?.getProjectBlockers.matchFulfilled, (state, { payload }) => {
      state.projectBlockers = payload;
    });
    builder.addMatcher(projectBlockersApi.endpoints?.getProjectBlocker.matchFulfilled, (state, { payload }) => {
      state.projectBlockerDetails = payload;
    });
  },
  selectors: {
    selectProjectBlockers: (state) => state.projectBlockers,
    selectProjectBlockerDetails: (state) => state.projectBlockerDetails,
    selectProjectBlockerDrawerVisible: (state) => state.projectBlockerDrawer.visible,
    selectProjectBlockerDrawerData: (state) => state.projectBlockerDrawer,
    selectProjectBlockerAssets: (state) => state.projectBlockerDetails?.assets,
  },
});

export const { setProjectBlockerDrawerVisible, removeBlockerAsset, addBlockerAsset, setBlockerAssets } =
  projectBlockersSlice.actions;

export const {
  selectProjectBlockers,
  selectProjectBlockerDetails,
  selectProjectBlockerDrawerVisible,
  selectProjectBlockerDrawerData,
  selectProjectBlockerAssets,
} = projectBlockersSlice.selectors;

export default projectBlockersSlice;
